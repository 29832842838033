import React from 'react';
import ProjectSignupSection from './components/ProjectSignupSection';
import { mockData } from './mockData';

const PricingTier3 = ({ isHighlighted }) => {
  const highlightStyles = isHighlighted ? { transform: 'scale(0.9)', zIndex: '9999' } : {};

  return (
    <div style={{ ...highlightStyles, borderRadius:'24px', textAlign:'left', display: "inline-block", width: "351px" }} data-ignore="used only for top most container width">
      <ProjectSignupSection {...mockData} isHighlighted={isHighlighted} />
    </div>
  );
};

export default PricingTier3;
