import AWS from 'aws-sdk';
import { fetchAuthSession } from '@aws-amplify/auth';

const checkAndAddUserIdentity = async () => {
    try {
        const session = await fetchAuthSession();

        if (session && session.credentials) {
            AWS.config.update({
                region: 'us-east-1',
                credentials: {
                    accessKeyId: session.credentials.accessKeyId,
                    secretAccessKey: session.credentials.secretAccessKey,
                    sessionToken: session.credentials.sessionToken
                }
            });

            const dynamoDb = new AWS.DynamoDB.DocumentClient();
            const tableName = 'pdftomindmapUsers';
            const userId = session.userSub; 
            const identityId = session.identityId;
            //console.log(identityId);
            const getParams = {
                TableName: tableName,
                Key: { 'userID': userId }
            };

            const data = await dynamoDb.get(getParams).promise();
            if (data.Item && !data.Item.identityID) {
                const updateParams = {
                    TableName: tableName,
                    Key: { 'userID': userId },
                    UpdateExpression: 'set identityID = :i',
                    ExpressionAttributeValues: {
                        ':i': identityId,
                    },
                    ReturnValues: 'UPDATED_NEW'
                };

                try {
                    await dynamoDb.update(updateParams).promise();
                    //console.log("identityID added to DynamoDB for userID:", userId);
                } catch (updateErr) {
                    console.error("Error adding identityID to DynamoDB:", updateErr);
                }
            } else if (!data.Item) {
                //console.log("No existing user found for this userID:", userId);
            } else {
                //console.log("identityID already exists in DynamoDB for userID:", userId);
            }
        } else {
            //console.log("Session credentials are missing.");
        }
    } catch (error) {
        console.error('Error in checking and adding userIdentity:', error);
    }
};

export default checkAndAddUserIdentity;