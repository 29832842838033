import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { signOut, deleteUser } from 'aws-amplify/auth';
import { fetchAuthSession } from '@aws-amplify/auth';
import logo from '../assets/logo-no-name.png';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from '@aws-amplify/auth';
import "./MyAccount.css";
import SideNavbar from '../ui-components/SideNavBar';
import { handleUpgradeClick } from '../util/HandleUpgrade';
import { FaExclamationCircle } from 'react-icons/fa';
import { set } from 'react-ga';

const MyAccount = () => {
  const [selectedOption, setSelectedOption] = useState('Personal');
  // Placeholder for user's plan, replace with actual state/logic
  const [membershipPlan, setMembershipPlan] = useState('freemium');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteInput, setDeleteInput] = useState('');
    const [userDetails, setUserDetails] = useState({
        sub: 'Loading...',
        name: 'Loading...',
        email: 'Loading...',
        planPrice: 'Loading...',
        renewalDate: 'Loading...',
        monthlyLimit: 'Loading...',
        TrialStatus: 'Loading...',
        TrialEnd: 'Loading...',
        cancelAtPeriodEnd: 'Loading...',
        cancelAt: 'Loading...'
      });
    const navigate = useNavigate();
    const [isAccountLoading, setIsAccountLoading] = useState(false);
    const starterPlan = "$4";
    const proPlan = "$6";

    const handleDeleteAccount = () => {
      setShowDeleteModal(true); // Show the modal for account deletion confirmation
  };

  const onMyMapsClick = () => {
    navigate('/app'); // Adjust the route as necessary
  };
  
    
    const confirmDeleteAccount = async () => {
      if (deleteInput.toLowerCase() === 'delete permanently') {
        try {
          const requestBody = { email: userDetails.email, userID: userDetails.sub };
          //console.log('Request Body:', requestBody); // Add console log to show the request body
          const response = await fetch('https://o2086k2ssh.execute-api.us-east-1.amazonaws.com/dev', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          });
          if (response.ok) {
            await deleteUser(); // Delete the user from the Cognito user pool
            // Handle successful account deletion
            //console.log('Account successfully deleted');
          } else {
            // Handle error
            //console.error('Failed to delete account');
          }
        } catch (error) {
          //console.error("Error is this", error);
        }
      }
    };    

  const handleUpgradeClickLocal = (priceid) => {
      setIsAccountLoading(true);
      handleUpgradeClick(priceid);
    };

    const handleUpgradeNavigation = (pricingState = 'monthly') => {
      window.open(`/pricing?state=${pricingState}`, '_blank');
    }

  const manageSubscription = async () => {
    try {
      setIsAccountLoading(true);

      const user = await getCurrentUser();
      if (!user) {
        //console.error('User not found');
        throw new Error("User not found");
      }

      const userId = user.userId;

      const response = await fetch('https://daffzuzdjd.execute-api.us-east-1.amazonaws.com/dev/', { // for testing use this "https://hi40r02nx1.execute-api.us-east-1.amazonaws.com/dev" production url is -> "https://daffzuzdjd.execute-api.us-east-1.amazonaws.com/dev/" in production
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });

      const responseBody = await response.text(); // Get the response body as text

      const data = JSON.parse(responseBody); // Parse the text as JSON

      if (response.ok) {
        const innerData = JSON.parse(data.body); // Parse the inner JSON string

        if (innerData.url) {
          window.location.href = innerData.url;
        } else {
          throw new Error('URL not found in response data');
        }
      } else {
        throw new Error(data.error || 'Failed to retrieve billing portal URL');
      }
    } catch (error) {
      //console.error('Error in handleMyAccount:', error);
      // Handle the error (e.g., show a notification to the user)
    } finally {
      setIsAccountLoading(false); // Reset loading state to false
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const session = await fetchAuthSession();
        if (session && session.credentials) {
          AWS.config.update({
            region: 'us-east-1',
            credentials: {
              accessKeyId: session.credentials.accessKeyId,
              secretAccessKey: session.credentials.secretAccessKey,
              sessionToken: session.credentials.sessionToken,
            },
          });

          const dynamoDb = new AWS.DynamoDB.DocumentClient();
          const tableName = 'pdftomindmapUsers';
          const userId = session.userSub;


          const getParams = {
            TableName: tableName,
            Key: { 'userID': userId },
          };

          const data = await dynamoDb.get(getParams).promise();
          if (data && data.Item) {
            const membershipPlan = data.Item.MembershipPlan;
            setMembershipPlan(membershipPlan);
            const planPrice = membershipPlan === 'Freemium'? '$0' : `$${data.Item.InvoiceDetails.Amount / 100}`;
            const renewalDate = new Date(data.Item.userUsage.ResetDate).toLocaleDateString();
            const TrialStatus = data.Item.TrialStatus;
            const TrialEnd = data.Item.TrialEnd;
            const cancelAtPeriodEnd = data.Item.cancelAtPeriodEnd;
            const cancelAt = data.Item.cancelAt;

            setUserDetails({
              sub: data.Item.userID,
              name: data.Item.Name,
              email: data.Item.Email,
              planPrice: planPrice + '/month',
              renewalDate: 'Credits renew on: ' + renewalDate,
              monthlyLimit: data.Item.userUsage.MonthlyLimit,
              TrialStatus: TrialStatus,
              TrialEnd: TrialEnd,
              cancelAtPeriodEnd: cancelAtPeriodEnd,
              cancelAt: cancelAt
            });
          }
        }
      } catch (error) {
        //console.error('Error fetching user details:', error);
        setUserDetails({
          name: 'Error',
          email: 'Error',
          planPrice: 'Error',
          renewalDate: 'Error',
          TrialStatus: 'Error',
          TrialEnd: 'Error',
          cancelAtPeriodEnd: 'Error'
        });
      }
    };

    fetchUserDetails();
  }, []);

  const today = new Date();
  const timeDiff = new Date(userDetails.TrialEnd) - today;
  const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convert from milliseconds to days

  return (
    
    <div className="account-layout">
      <SideNavbar onMyMapsClick={onMyMapsClick}/>
      <div className="my-account-options-menu">
      {['Personal', 'Subscription', 'Danger Zone'].map((section) => (
          <React.Fragment key={section}>
            {section === 'Subscription' ? (
              (membershipPlan !== 'Pay Per Use' && membershipPlan !== "Freemium") ? (
                <button
                  className={`menu-option ${selectedOption === section ? 'active' : ''}`}
                  onClick={() => setSelectedOption(section)}
                >
                  {section}
                </button>
              ) : null
            ) : (
              <button
                className={`menu-option ${selectedOption === section ? 'active' : ''}`}
                onClick={() => setSelectedOption(section)}
              >
                {section}
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
      
      {selectedOption === 'Personal' && (
  <div className="section-content">
    <div className="inner-content">
      <div className='personal-details'>
      <h1>My Information</h1>
    <p>Name: {userDetails.name}</p>
    <p>Email: {userDetails.email}</p>
    </div>

    </div>
  </div>
)}
{selectedOption === 'Subscription' && (
  <div className="section-content">
    
    <div className="inner-content">
      
        {(membershipPlan === 'Freemium' || membershipPlan === 'Pro Plan' || membershipPlan === 'Paid' ||membershipPlan === 'Annual Pro Plan' || membershipPlan === 'Starter Plan') && userDetails.TrialStatus == false &&(
            <div className='plan-details'>
             <h2>Subscription Details</h2>
              <p>Plan: {userDetails.planPrice}</p>
              <p><strong>{userDetails.renewalDate}</strong></p>
              </div>
        )}

      
      {membershipPlan === 'Freemium' &&(
        <div className="pricing-section-my-account">
          {/** 
          <div className="pricing-tier starter-plan">
          <div className='plan-name-card'>
            <h2>Starter</h2>
            <h6>$4/month</h6>
            </div>
            <p>Best for students, researchers, educators, and anyone getting started</p>
            <ul>
            <li><strong>100 credits per month 🚀</strong></li>
              <li>No limit on file size 🗃️</li>
              <li>Edit mind maps ✍🏼</li>
              <li>Standard customer support ☎️</li>
            </ul>
            <button className="upgrade-button" onClick={() => handleUpgradeClickLocal(starterPlan)}>
              {isAccountLoading ? 'Loading...' : 'Get Started 🚀'}
            </button>
          </div>
          */}
          <div className="pricing-tier pro-plan">
            <div className='plan-name-card'>
            <h2>Pro</h2>
            <h6>$6/month</h6>
            </div>
            <p>Best for power users, visual learners, and business owners</p>
            <ul>
              <li><strong>250 credits/month (~ 25 mindmaps) 🚀</strong></li>
              <li>Early access to new features ✅</li>
              <li>Export without watermark ⬇️</li>
              <li>No limit on file size 🗃️</li>
              <li>Priority Customer Support Via Email ☎️</li>
            </ul>
            <button className="upgrade-button" onClick={(e) => handleUpgradeNavigation(e, 'yearly')}>
              {isAccountLoading ? 'Loading...' : ' Go Pro 💪🏽'}
            </button>
          </div>
        </div>
      )}

    {
      ((membershipPlan === 'Pro Plan' ||  membershipPlan === 'Paid' ||membershipPlan === 'Annual Pro Plan'  || membershipPlan === 'Starter Plan') && membershipPlan !== 'Freemium') && userDetails.TrialStatus === true && (
        <div className='trial-information'>
          <h2>Included in Your Subscription</h2>
          <ul>
            <li>{userDetails.monthlyLimit * 10} credits per month</li>
            <li>No limit on file size</li>
            <li>Prioritized customer support</li>
          </ul>
          {/* Adding specific trial duration information */}
      {(userDetails.cancelAtPeriodEnd &&  
      <div>
      <p>Your trial is set to cancel at <strong>{userDetails.cancelAt}</strong> and you will lose all the benefits of your current plan.</p>
      <button className='manage-sub-button'
          onClick={manageSubscription}>
          {isAccountLoading ? 'Loading...' : 'Renew Subscription'}</button>
      </div>
      )}
      {(!userDetails.cancelAtPeriodEnd &&
      <div>
      <p>Your trial will end on <strong>{userDetails.TrialEnd}</strong>, which is in <strong> {daysRemaining} days</strong>. Your subscription will begin unless cancelled.</p>
      <button className='manage-sub-button'
          onClick={manageSubscription}>
          {isAccountLoading ? 'Loading...' : 'Manage Subscription'}</button>
      </div>
      )}
        </div>
      )
    }
    

{/*
{membershipPlan === 'Starter Plan' && membershipPlan !== 'Freemium' && userDetails.TrialStatus == false && (
  
        <div className="pricing-section-my-account with-upgrade">
            <div className='subscription-information with-upgrade'>
          <h2>Included in Your Subscription</h2>
          <ul>
            <li>{userDetails.monthlyLimit * 10} credits per month</li>
            <li>No limit on file size</li>
            <li>Prioritized customer support</li>
          </ul>
          <button className='manage-sub-button'
          onClick={manageSubscription}>
          {isAccountLoading ? 'Loading...' : 'Manage Subscription'}</button>
        </div>
          <div className="pricing-tier pro-plan">
            <div className='plan-name-card'>
            <h2>Pro</h2>
            <h6>$6/month</h6>
            </div>
            <p>Best for power users, visual learners, and business owners</p>
            <ul>
              <li><strong>250 credits per month 🚀</strong></li>
              <li>No limit on file size 🗃️</li>
              <li>Edit mind maps ✍🏼</li>
              ||membershipPlan === 'Annual' <li>Prioritized customer support ☎️</li>
            </ul>
            <button className="upgrade-button" onClick={manageSubscription}>
              {isAccountLoading ? 'Loading...' : ' Go Pro 💪🏽'}
            </button>
          </div>
        </div>
        
)}

*/}


{((membershipPlan === 'Pro Plan' || membershipPlan === 'Paid'|| membershipPlan === 'Starter Plan' ||membershipPlan === 'Annual Pro Plan' ) && membershipPlan !== 'Freemium') && (userDetails.TrialStatus === false || userDetails.TrialStatus === undefined) && (
        <div className='subscription-information'>
          <h2>Included in Your Subscription</h2>
          <ul>
            <li>{userDetails.monthlyLimit * 10} credits per month</li>
            <li>No limit on file size</li>
            <li>Prioritized customer support</li>
          </ul>
          <button className='manage-sub-button'
          onClick={manageSubscription}>
          {isAccountLoading ? 'Loading...' : 'Manage Subscription'}</button>
        </div>
      )}
    </div>
  </div>

)}



{selectedOption === 'Danger Zone' && (
  <div className="section-content">
    <div className="inner-content">
      <div className='delete-content'>
        <h1>Delete Account</h1>
    <p>Deleting your account will remove all your data permanently, including documents uploaded and mind maps created.</p>
    <p>If you have a pro plan, your subscription will be immediately terminated and no refunds can be issued.</p>
    <button className="danger-button" onClick={handleDeleteAccount}>Delete my account</button>
    </div>
    </div>
  </div>
)}
        {showDeleteModal && (
          <>
          <div className="account-delete-modal-overlay"></div>
                <div className="account-delete-modal">
                <FaExclamationCircle className="warning-icon" />
                  <h3>Type "delete permanently" to confirm:</h3>
                  <p>This action will delete any information associated with your account and this action is irreversible</p>
                  <input
                    type="text"
                    value={deleteInput}
                    placeholder='delete permanently'
                    onChange={(e) => setDeleteInput(e.target.value)}
                  />
                  <div className='button-container'>
                  <button
                    onClick={confirmDeleteAccount} // Update this line
                    disabled={deleteInput.toLowerCase() !== 'delete permanently'}
                  >
                    Delete My Account
                  </button>
                  <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
                  </div>
                </div>
                </>
              )}
    </div>
  );
};

export default MyAccount;