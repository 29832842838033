import { downloadData } from 'aws-amplify/storage';

export const retrieveProcessedFile = async (originalFileName, onSuccess, onError, retryInterval = 2000, maxRetries = 50) => {
    let retries = 0;

    const summaryFileName = originalFileName.replace('.txt', '_summary.txt');
    
    const checkFile = async () => {
        
        //console.log(`Checking for file: ${summaryFileName}, attempt: ${retries + 1}`);

        try {
            const downloadResult = await downloadData({ key: summaryFileName, options: { accessLevel: 'private' } }).result;
            const textContent = await downloadResult.body.text();
            const filtertextContent = textContent.replace("```mermaid", "").replace("```", "");
            onSuccess(filtertextContent);
        } catch (error) {
            if (retries < maxRetries) {
                retries++;
                //console.log(`Retrying... (${retries}/${maxRetries})`);
                setTimeout(checkFile, retryInterval);
            } else {
                //console.log(`Max retries reached or file does not exist: ${summaryFileName}`);
                onError(new Error("Max retries reached or file does not exist"));
            }
        }
    };

    checkFile();
};