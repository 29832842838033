import { loadStripe } from '@stripe/stripe-js';
import { getCurrentUser } from '@aws-amplify/auth';
import { useEffect, useState } from 'react';
import getUserData from '../userManagement/getUserData';
const stripePromise = loadStripe('pk_live_51OJn7eGPqEG8TQso0zfoGWpGdFpsJ5IOw1f7FAEHIQwdfK5uFnxmdMvtaxcmbmVbZ5J6KYetwJwCnm8JkxTsPfd600d9j7ZsNO');
const testStripePromise = loadStripe('pk_test_51OJn7eGPqEG8TQsofkujN7Mz1bbsNGLHBqWUDO1iayILJeEWMKXr3OPDgVpZbJUL3mXKQZ4nKfx2RRh8nK9zUN5L00ezkF6YDc');

// Pass the priceId so that lambda can handle which price to use
export const handleUpgradeClick = async (priceId) => {
    try {
        const info = await getUserData();
        if (!info) {
            throw new Error('User not found');
        }

        const userData = {
            Email: info.Email, // make sure email attribute exists
            userID: info.userID // sub is typically the user ID
        };

        const stripe = await stripePromise;
        const testStripe = await testStripePromise;
        const requestBody = { 
            email: userData.Email,
            cognitoUserId: userData.userID,
            priceId // Include the priceId parameter to specify the price
        };

        const apiEndpoint = priceId === 'buyCredits' 
            ? 'https://cq37aolpde.execute-api.us-east-1.amazonaws.com/dev' 
            : 'https://r1lasn6ud1.execute-api.us-east-1.amazonaws.com/dev';

        const response = await fetch(apiEndpoint, { 
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ body: JSON.stringify(requestBody) }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        console.log(responseData);
        const { sessionId } = JSON.parse(responseData.body);
        await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
        console.error('Error redirecting to Stripe checkout:', error);
    }
};