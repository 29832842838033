import React from 'react';
import NavBar from '../ui-components/LandingPage/Navbar';
import Footer from '../ui-components/LandingPage/Footer';

const HowCreditsWork = () => {
  return (
    <>
    <NavBar />
    <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <h1>How Credits Work 🧮</h1>
      <p>
        Map This uses a credit system to manage the use of resources 🛠️. Here’s how we calculate the number of credits needed for each mind map you create.
      </p>
      <h2>Understanding Credits 💡</h2>
      <p>
        Credits are calculated based on the amount of text processed by our system. We use the "token" concept where:
      </p>
      <ul>
        <li>1 token ≈ 4 characters of text</li>
        <li>100 tokens ≈ 75 words 📖.</li>
      </ul>
      <h2>Credit Calculation 📊</h2>
      <p>
        The cost in credits for creating a mind map is calculated as follows:
      </p>
      <ul>
        <li>Each 5000 tokens cost 5 credits.</li>
        <li>If it's your first ever mind map, we only deduct 5 credits, no matter the size (with reasonable limits).</li>
        <li>After your first map, the minimum credits deducted are 5 and the maximum are 50 per mind map.</li>
      </ul>
      <h2>Example Calculations 📝</h2>
      <p>
        Consider this sample document which contains approximately 18,750 words. Here is how the credit calculation would work:
      </p>
      <ol>
        <li>
          <strong>For 18,750 words (about 25,000 tokens), it would typically cost 25 credits.</strong>
        </li>
        <li>
          If it's your first mind map, you would only pay 5 credits, regardless of the document size.
        </li>
      </ol>
      <p>
        Download the sample document with about 18,750 words to see the content that equates to 25 credits: &nbsp; 
        <a href="/samplePDF.pdf" download="SampleDocument.pdf">Download Sample Document</a>.
      </p>
      <p>
        We hope this guide helps you understand how credits are managed and deducted. Use your credits wisely to maximize the value from our tool.
      </p>
    </div>
    <Footer />
    </>
  );
};

export default HowCreditsWork;
