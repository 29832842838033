import React from 'react';
import './OptionsMenuModal.css';
import { FaPencilAlt, FaTrashAlt, FaMap } from 'react-icons/fa';

const OptionsMenuModal = ({ isOpen, onClose, style, onRename, onDelete, onEdit }) => {
    if (!isOpen) return null;

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
    };
  
    return (
      <div className="options-menu-overlay" onClick={handleOverlayClick}>
          <div className="options-menu" style={style}> {/* Apply the style prop here */}
              <ul className="options-menu-list">
                  <li className="options-menu-item" onClick={onRename}>
                      <FaPencilAlt className="options-icon" /> Rename
                  </li>
                  <li className="options-menu-item" onClick={onDelete}>
                      <FaTrashAlt className="options-icon" /> Delete
                  </li>
              </ul>
          </div>
      </div>
  );
};
  
  export default OptionsMenuModal;
