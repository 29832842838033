import React, { useState, useEffect } from 'react';
import './Onboarding.css'; 
import Confetti from 'react-confetti-boom';
import getUserData from '../userManagement/getUserData';
import { FaSpinner } from 'react-icons/fa';
import updateUserOnboardingData from '../userManagement/userOnboardingInDynamoDB';
import { useNavigate } from 'react-router-dom';
import updateUserGroupMailterlite from '../userManagement/updateUserGroupMailterlite';

const OnboardingPage = () => {
  const [roleType, setRoleType] = useState('');
  const [findUs, setFindUs] = useState('');
  const [discoveryMethod, setDiscoveryMethod] = useState('');
  const [emailSignup, setEmailSignup] = useState(false);
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(true);
  const [userEmail, setUserEmail] = useState('');
  const [isUserOnboarded, setIsUserOnboarded] = useState(false);
  const navigate = useNavigate();
  const goToApp = () => {
    navigate('/app');
  };


  const apiKey = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiN2NlYjNmYmNjYWQyODJiY2VlY2JjNGJhOTAxMmViYWFlMDI4ZTNiZjcwYzVkNGY3MTQzMWFmZTNlZmNiNzk5MmQzNzFjY2NkMzBmMjAwNWYiLCJpYXQiOjE3MTQxNjA0OTEuMTI5MDk2LCJuYmYiOjE3MTQxNjA0OTEuMTI5MDk4LCJleHAiOjQ4Njk4MzQwOTEuMTI1MTg0LCJzdWIiOiI5MjE3MTciLCJzY29wZXMiOltdfQ.XmJtblrj7tP45bOw-XOIU-Grk7oP2JRGF_JyRQ69vr82yiyBdDMFLHc2xQcDX5QEP7ntSkExiQ_1c-k3fWfwG6tYgkMOzUq6vsT1j_mKBETM9MELV-hQ-uyRy-hEcl1CFpw3-2HKnnbYg_TO88041y7zD0WC61HZBIYJJM5mdGCBNnKY324sbnHRM15xLKPWynp1zvus-NfJyhiDL3GCqQWVC6GNlhOy80z2o7pvrIXMcUOwBpmfTDoH43p_CbcRL9GKBEfNDpscN_LefxcxOxWGS2sORY-qtqjXMciZqkCk72ATIDF1xH08eNrriRhJb5Z73SfCxbyjVMhAXYybYP5ug3_vbTmVg8KpQ55j7l3WL5wm6c6619AWkC64XPeqKaBY4AEoYg3B7hQYzh2z4A_0c-qpEsEbJTpF7ktQju7rflD4Qx1ufw57-9W5ZK-X1oRqJ6H8-7t3lpGE8q9Der89X2LwbUC6nYsrcOZERO2CVUFijqJUbfqtu_9SKXJdfP36Z80bhPEl8k57pNa8xRssucS8OjHE0DnVQAbe6bQfjP-oScjqEK32863p0IzrSO2UVKG1BqFALKX8SpWUL9pUsBI4x2qLeDHyBCMF3McxqpL_GmQOYmuil9lAncXRnprbRo8Ptiau16GOz_EXXHfO0KnVtRIKhDHC9H1EYqs';  
  const onboadingPilotGroup = '119513939294815756';
  const studentGroup = '121354719717230535';
  const subscriberData = 
      { 
        email: userEmail,
        "fields": {
            "name": userName.split(' ')[0],
            "last_name": userName.split(' ')[1]
          },
          "groups":[
            onboadingPilotGroup
          ]
    };

//how did you hear about us?
// from a friend, ig, tt, fb, youtube, twitter, linkedin, google, blogposts, other

const handleSubmit = (event) => {
  event.preventDefault();
  // Process form submission here
  const userOnboardingData = {
      roleType: roleType,
      findUs: findUs,
      emailSignup: emailSignup
  };
  const bool = true;
  updateUserOnboardingData(userOnboardingData, bool, emailSignup);

  if(emailSignup){
      fetch(`https://connect.mailerlite.com/api/subscribers`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${apiKey}`
          },
          body: JSON.stringify(subscriberData)
      })
      .then(response => response.json())
      .catch(error => console.error('Error'));

      // Add user to the student group if roleType is Student/Researcher
      if (roleType === 'Student') {
          const studentSubscriberData = {
              ...subscriberData,
              "groups": [studentGroup]
          };
          updateUserGroupMailterlite(apiKey, studentSubscriberData);
      }
  }
  goToApp();
};

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUserData();
      setUserName(data.Name);
      setUserEmail(data.Email);
      setIsUserOnboarded(data.isUserOnboarded);
      setLoading(false); // Set loading to false after the data is fetched
    };
    fetchData();
  }, []); 

if(isUserOnboarded){
    goToApp();
}


if (loading) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{}}> 
            <FaSpinner
            size={50}
            /></div>
        </div>
    );
  }
  return (
    <div className="onboarding-container">
      <Confetti
        mode={'boom'}
        particleCount={419}
        shapeSize={40}
        deg={211}
        effectCount={1}
        effectInterval={1000}
        spreadDeg={52}
        x={0.90}
        y={0.8}
        launchSpeed={5}
      />
      <Confetti
        mode={'boom'}
        particleCount={419}
        shapeSize={40}
        deg={314}
        effectCount={1}
        effectInterval={1000}
        spreadDeg={34}
        x={0.10}
        y={0.8}
        launchSpeed={5}
      />

      <form onSubmit={handleSubmit} className="onboarding-form">
        <header className="onboarding-header">
          <img src="/logo-with-name-black.png" alt="Logo" className="onboarding-logo" />
          <h2>{userName.split(' ')[0]}, Welcome to Map This!</h2>
        </header>
        <label htmlFor="role-type">What is your role?</label>
        <select
          id="role-type"
          value={roleType}
          onChange={(e) => setRoleType(e.target.value)}
          required
        >
          <option value="" disabled>What is your role?</option>
          <option value="Student">Student</option>
          <option value="Grad Student/Researcher">Graduate Student / Researcher</option>
          <option value="Educator">Educator</option>
          <option value="Project Management">Project Management</option>
          <option value="Marketing / Sales / Operations">Marketing / Sales / Operations</option>
          <option value="Business Owner / Management">Business Owner / Management</option>
          <option value="Finance">Finance</option>
          <option value="Legal">Legal</option>
          <option value="Healthcare">Healthcare</option>
          <option value="Engineer / Software Developer">Engineer / Software Developer</option>
          <option value="Other">Other</option>
        </select>

        <label htmlFor="find-us">How did you find us?</label>
        <select
          id="find-us"
          value={findUs}
          onChange={(e) => setFindUs(e.target.value)}
          required
        >
          <option value="" disabled>How did you find us?</option>
          <option value="from a friend">From a friend</option>
          <option value="instagram">Instagram</option>
          <option value="tiktok">Twitter</option>
          <option value="facebook">Facebook</option>
          <option value="youtube">YouTube</option>
          <option value="twitter">Twitter</option>
          <option value="linkedin">LinkedIn</option>
          <option value="google">Google</option>
          <option value="blogposts">Blogposts</option>
          <option value="other">Other</option>
        </select>

        <label htmlFor="email-signup">
          <input
            className='email-signup-checkbox'
            type="checkbox"
            id="email-signup"
            checked={emailSignup}
            onChange={(e) => setEmailSignup(e.target.checked)}
          />
        </label>

        <button type="submit" className="continue-button" onSubmit={handleSubmit}>Continue</button>
      </form>
    </div>
  );
};

export default OnboardingPage;
