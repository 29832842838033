import React, { useState, useEffect, useRef } from 'react';
import './ShareLinkComponent.css'; 
import { FaFacebook, FaInstagram, FaLinkedin, FaLinkedinIn, FaReddit, FaTelegram, FaTwitter, FaWhatsapp, FaWhatsappSquare } from 'react-icons/fa';

const ShareLinkComponent = ({ link, style, onClose }) => {
    const [isCopied, setIsCopied] = useState(false);
    const containerRef = useRef(); // Ref for the container to detect clicks outside

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                onClose(); // Call the provided onClose function
            }
        };
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                onClose(); // Call the provided onClose function
            }
        };

        document.addEventListener('keydown', handleEscape);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Cleanup event listeners
            document.removeEventListener('keydown', handleEscape);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const handleCopy = () => {
        navigator.clipboard.writeText(link).then(() => {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        });
    };

    // Function to handle Twitter share with an engaging, formatted message
    const shareToTwitter = () => {
        // Structuring the message for readability (actual tweet won't have line breaks)
        const messageParts = [
            "Look at this amazing mindmap I created!!🌟",
            "Check it out! ➡️"
        ];
        const message = messageParts.join(' '); // Join parts into a single line

        const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}&url=${encodeURIComponent(link)}`;
        window.open(url, '_blank');
    };
    
        // Function to handle Facebook share
        const shareToFacebook = () => {
            const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`;
            window.open(url, '_blank');
        };

        const handleLinkedInShare = () => {
            const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(link)}`;
            window.open(linkedInUrl, '_blank');
        };
        

        const handleWhatsAppShare = () => {
            const message = "Check out this awesome mindmap I created!! 😍";
            const whatsAppWebUrl = `https://web.whatsapp.com/send?text=${encodeURIComponent(message + " " + link)}`;
            window.open(whatsAppWebUrl, '_blank');
        };
        
        const handleTelegramShare = () => {
            const message = "Check out this awesome mindmap I created!";
            const telegramUrl = `https://telegram.me/share/url?url=${encodeURIComponent(link)}&text=${encodeURIComponent(message)}`;
            window.open(telegramUrl, '_blank');
        };
        
        const handleRedditShare = () => {
            const title = "Check out this awesome mindmap I created!";
            const redditUrl = `https://reddit.com/submit?url=${encodeURIComponent(link)}&title=${encodeURIComponent(title)}`;
            window.open(redditUrl, '_blank');
        };

return (
    <div className="share-container" style={style} ref={containerRef}>
        <h2>Share this MindMap 😍</h2>
        <div className="link-container">
            <input type="text" value={link} readOnly />
            <button onClick={handleCopy}>{isCopied ? 'Copied' : 'Copy'}</button>
        </div>
        <div className="social-icons">
            <span>Share to</span>
            <i className="twitter-icon" onClick={shareToTwitter}> <FaTwitter /> </i>
            <i className="facebook-icon" onClick={shareToFacebook}> <FaFacebook /> </i>
            <i className='linkedin-icon' onClick={handleLinkedInShare}> <FaLinkedinIn /> </i>
            <i className="whatsapp-icon" onClick={handleWhatsAppShare}> <FaWhatsapp /> </i>
            <i className="reddit-icon" onClick={handleRedditShare}> <FaReddit /></i>
            <i className="telegram-icon" onClick={handleTelegramShare}> <FaTelegram /></i>
        </div>
    </div>
);
};

export default ShareLinkComponent;
