export function calculateCredits(numTokens, isFirstMindMap = false) {
    // Calculate credits needed based on token count
    const creditsNeeded = Math.ceil(numTokens / 5000) * 0.5;

    // If it's the first mindmap, cap the credits needed at 5 if within the range
    if (isFirstMindMap && creditsNeeded >= 1 && creditsNeeded <= 5) {
        return 0.5;
    }

    return creditsNeeded;
}
