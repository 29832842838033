import AWS from 'aws-sdk';
import { fetchAuthSession } from '@aws-amplify/auth';


const updateUserOnboardingData = async (userOnboardingData, isUserOnboarded, emailSignup ) => {
    try {
        
        const session = await fetchAuthSession();

        if (session && session.credentials) {
            AWS.config.update({
                region: 'us-east-1',
                credentials: {
                    accessKeyId: session.credentials.accessKeyId,
                    secretAccessKey: session.credentials.secretAccessKey,
                    sessionToken: session.credentials.sessionToken
                }
            });

            const dynamoDb = new AWS.DynamoDB.DocumentClient();
            const tableName = 'pdftomindmapUsers';
            const userId = session.userSub;

            const updateParams = {
                TableName: tableName,
                Key: { 'userID': userId },
                UpdateExpression: 'set userOnboardingData = :onboardingData, isUserOnboarded = :isUserOnboarded, emailSignup = :emailSignup',
                ExpressionAttributeValues: {
                    ':onboardingData': userOnboardingData,
                    ':isUserOnboarded': isUserOnboarded,
                    ':emailSignup': emailSignup
                },
                ReturnValues: 'UPDATED_NEW'
            };

            // Update the user onboarding data
            const updateResult = await dynamoDb.update(updateParams).promise();
        } else {
            console.error("Session credentials are missing.");
        }
    } catch (error) {
        console.error('Error updating user onboarding data in DynamoDB:', error);
    }
};

export default updateUserOnboardingData;
