import { Button } from "@mui/base";
import DisplayProjectMembers from "../DisplayProjectMembers";
import SvgIcon1 from "./icons/SvgIcon1";
import SvgIcon2 from "./icons/SvgIcon2";
import SvgIcon3 from "./icons/SvgIcon3";
import "./style.css";
import messages from "./messages.json";
import { useState } from "react";
import { handleUpgradeClick } from "../../../../../util/HandleUpgrade";

function ProjectSignupSection({ projectMemberLimitOptions, isHighlighted }) {
  // Determine button text based on isHighlighted
  const buttonText = isHighlighted ? messages["current_plan"] : messages["sign_up_now"];

  // Adjusted onClick to pass projectLimitOptions to handleUpgradeClick
  const onUpgradeClick = () => {
    setIsLoading(true);
    handleUpgradeClick(messages["price"]);
  };
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="pricing-card-container">
      <div className="project-pricing-section">
        <div className="price-container">
          <p className="free-label">{messages["plan_name"]}</p>
          <p className="price-display-heading">{messages["price"]}</p>
        </div>

        <p className="monthly-price-label">{messages["monthly"]}</p>
        <p className="benefits">Save 20 hours of reading</p>
      </div>
      {/* Button Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
      <Button onClick={onUpgradeClick} className="primary-button" disabled={isHighlighted || isLoading}>
        {isLoading ? "Loading..." : buttonText}
      </Button>
      <div className="team-members-section">
        <div className="vertical-centered-container">
          <SvgIcon3 className="svg-container" />
          <SvgIcon3 className="svg-container" />
          <SvgIcon3 className="svg-container" />
          <SvgIcon3 className="svg-container" />
          <SvgIcon3 className="svg-container" />
        </div>
        <DisplayProjectMembers projectMemberLimitOptions={projectMemberLimitOptions} />
      </div>
    </div>
  );
}

export default ProjectSignupSection;
