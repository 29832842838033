import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useParams } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';
import '@aws-amplify/ui-react/styles.css';
import Landing from './Pages/Landing';
import PDFToMindMap from './Pages/pdftomindmap';
import PrivateRoute from './auth/privateroute';
import LoginPage from './Pages/LoginPage';
import Home from './Pages/Home';
import studioTheme from './ui-components/studioTheme';
import NavBar from './ui-components/LandingPage/Navbar';
import { ThemeProvider } from '@aws-amplify/ui-react';
import SupportPage from './Pages/Support';
import MyAccount from './Pages/MyAccount';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import MindmapViewer from './Pages/MindmapViewer';
import FAQ from './ui-components/FAQ';
import OnboardingPage from './Pages/Onboarding';
import Pricing from './Pages/Pricing';
import Congratulations from './Pages/Congratulations';
import MaybeNextTime from './Pages/MaybeNextTime';
import HowCreditsWork from './Pages/HowCreditsWork';
Amplify.configure(config);
function NotFound() {
  return <Navigate to="/" />;
}
const ConditionalNavBar = () => {
  const location = useLocation();
  if (location.pathname === '/login' || location.pathname === '/signup') {
    const isLoginPage = location.pathname === '/login';
    return <NavBar isLoginPage={isLoginPage} width="100%"/>;
  }
  return null;
};
const ValidateMindmapId = ({ children }) => {
  const { mindmapId } = useParams();
  // Regular expression to validate UUID (version 4)
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const isValid = uuidRegex.test(mindmapId);
  if (!isValid) {
    return <Navigate to="/" />;
  }
  return children;
};
function App() {
  return (
    <ThemeProvider theme={studioTheme}>
      <Router>
        <ConditionalNavBar />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/onboarding" element={<OnboardingPage />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/congratulations" element={<Congratulations />} />
          <Route path="/see-you-again" element={<MaybeNextTime />} />
          <Route path="/how-credits-work" element={<HowCreditsWork />} />
          <Route
            path="/app"
            element={
              <PrivateRoute>
                <PDFToMindMap />
              </PrivateRoute>
            }
          />
          <Route
            path="/:mindmapId"
            element={
              <ValidateMindmapId>
                  <MindmapViewer />
              </ValidateMindmapId>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
