import { downloadData } from 'aws-amplify/storage';

export const checkForSummaryFile = async (originalFileName, onSuccess, onError, retryInterval = 2000, maxRetries = 50) => {
    let retries = 0;

    // Construct the correct summary file name without including the identityId
    const summaryFileName = `${originalFileName.replace('.pdf', '')}_summary.txt`;
    const checkFile = async () => {
        //console.log(`Checking for file: ${summaryFileName}`);
        //console.log(`Attempt number: ${retries + 1}/${maxRetries}`);  // Log current attempt

        try {
            const downloadResult = await downloadData({ key: summaryFileName, options: { accessLevel: 'private' } }).result;
            const textContent = await downloadResult.body.text();
            const filtertextContent = textContent.replace("```mermaid", "").replace("```", "");
            //console.log(filtertextContent);
            onSuccess(filtertextContent);
        } catch (error) {
            //console.log(`Error on attempt ${retries + 1}: ${error}`);  // Log error on current attempt

            if (retries < maxRetries) {
                retries++;
                //console.log(`Retrying... (${retries}/${maxRetries})`);  // Log retry number
                setTimeout(checkFile, retryInterval);
            } else {
                //console.log(`Max retries reached or file does not exist for: ${summaryFileName}`);
                //onError(new Error("Max retries reached or file does not exist"));
            }
        }
    };

    checkFile();
};
