import React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import './NewHero.css';
import { useSpring, animated } from 'react-spring';
import MindmapSumComponent from '../../util/MindmapSumComponent';


const backgroundPdfImage = 'background.png'; 
const mindMapImage = 'background1.png'; 
const iconImage = 'Backicon.png';

// YouTube video ID
//https://www.youtube.com/watch?v=jiBgfDYFZGo
const youTubeVideoId = "jiBgfDYFZGo";
const youTubeEmbedUrl = `https://www.youtube.com/embed/${youTubeVideoId}?autoplay=1&loop=1&playlist=${youTubeVideoId}&mute=1`;





const StyledLink = styled.div`
  align-items: center;
  border: 2px solid #002b31;
  border-radius: 42px;
  display: flex;
  justify-content: center;
  padding: 14px 25px;
  font-family: "Satoshi Variable-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.64px;
  line-height: 28px;
  color: #002b31;
  text-decoration: none; // Remove underline if it's a link
  transition: background-color 0.3s ease, color 0.3s ease;
  @media (max-width: 768px) {
    font-size: 14px; // Even smaller on very small screens
  }

  &:hover {
    background-color: #e6f7ff; // Example hover background color
    color: #000; // Example hover text color
    cursor: pointer;
  }
`;

// IconContainer for horizontally aligned icons with spacing
const IconContainer = styled.div`
  display: flex;
  justify-content: space-evenly; // This will distribute space evenly between icons
  align-items: center;
  width: 100%; // Set to the desired width or full width of the container
  margin-top: 30px; // Spacing from the top, adjust as necessary

  // If you want to add a specific size or other properties to all icons inside
  & > img { 
    // Add a fixed width and height for all icons if necessary
    width: 60px; // Example width, adjust as needed
    height: 60px; // Example height, adjust as needed
    margin: 0 10px; // Add some space between the icons
  }
`;
// StyledImage reused for multiple images
const StyledImage = styled.img`
  object-fit: cover;
  position: absolute;
`;

const StyledImageLarge = styled(StyledImage)`
  height: auto;
  max-width: 50%; // Adjust as necessary
  left: 100px;
  top: 40%;
  transform: translateY(-50%);
  position: absolute;
  @media (max-width: 768px) {
    display:none
  }
`;

const StyledImageSmall = styled(StyledImage)`
  height: auto;
  max-width: 50%; // Adjust as necessary
  right: 100px;
  top: 40%;
  transform: translateY(-50%);
  position: absolute;
  @media (max-width: 768px) {
    display:none
  }
`;

// Styled components for the text box
const StyledBox = styled.div`
  position: absolute;
  width: 100%;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; // Center the text
  z-index: 2;
  margin-bottom: 100px;

  & .turn-your-pdfs-into {
    color: #002b31;
    font-family: 'New Spirit-Regular', Helvetica;
    font-size: 62px;
    font-weight: 400;
    letter-spacing: 0.64px;
    line-height: 93px;
    position: relative;
    display: inline-block; // Use inline-block for better control

    & .highlight-pdf {
      background: linear-gradient(
        180deg,
        rgba(11, 121, 175, 0.31) 0%,
        rgba(11, 121, 175, 0) 99.52%
      );
      border-left: 3px solid #184a63;
      padding: 10px 20px; // Adjust padding as needed
      box-decoration-break: clone; // Apply background to each line separately
      -webkit-box-decoration-break: clone;
    }

    & .highlight-mindmap {
      background: linear-gradient(
        180deg,
        rgba(255, 202.94, 205.96, 0.31) 0%,
        rgba(244, 175, 179, 0) 99.52%
      );
      border-left: 3px solid #f4afb3;
      padding: 10px 20px; // Adjust padding as needed
      box-decoration-break: clone; // Apply background to each line separately
      -webkit-box-decoration-break: clone;
    }
  }

  & .description {
    color: #002b31;
    font-family: 'New Spirit-Regular', Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.64px;
    line-height: 36px;
    margin-top: 20px;
    width:400px;
    align-self: center;
  }
}`;



// Styled component for the frame
const StyledFrame = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute; 
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 50px;
  z-index: 2; 

  & .link {
    align-items: center;
    border: 2px solid #002b31;
    border-radius: 42px;
    display: flex;
    justify-content: center;
    padding: 14px 25px;
    font-family: "Satoshi Variable-Bold", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.64px;
    line-height: 28px;
    color: #002b31;
    @media (max-width: 768px) {
      margin-top:40px;
    }
  }

  & .div {
    color: #000000;
    font-family: "Satoshi Variable-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.2px;
    line-height: 27px;
    text-align: center;
    width: fit-content;
  }
`;

const InsideContainer = styled.div`
  background-color: white; // White background for the container
  border-radius: 15px; // Rounded borders
  padding: 20px; // Padding inside the container
  margin: 20px; // Margin from the edges of MainContainer
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Optional: adding a subtle shadow for depth
  max-width: 85%; // Maximum width
  width: 100%; // Take full width of the parent
  min-height:95%;
  align-self: center; // Center the container within MainContainer
  overflow: hidden; // In case any child element overflows
  `;

// Main container to hold all elements
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; // Center children vertically and horizontally
  position: relative;
  height: 100vh;
  background-color: #f5f5f4;
  background-image: url(${process.env.PUBLIC_URL}/BackgroundHero.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; // Take up 100% of the parent width
  margin-top: 20px;
  margin-bottom: 60px; // Add some vertical margin

  video {
    width: 100%; // Adjust the width as needed
    max-width: 800px; // Set a maximum width
    border-radius:20px;
    height: auto; // Maintain aspect ratio
  }
`;


// The CompletePage component
const NewHero = () => {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate('/login');
};
const [text, setText] = useState('');
const [isDeleting, setIsDeleting] = useState(false);
const [loopNum, setLoopNum] = useState(0);
const [sumOfMindmaps, setSumOfMindmaps] = useState(0);
const typingSpeed = isDeleting ? 100 : 200; 
const phrases = ["PDFs", "Notes", "Docs","Reports"];

const { number } = useSpring({
  from: { number: 0 },
  to: { number: sumOfMindmaps },
  config: { duration: 10000, easing: (t) => --t * t * t + 1 },
});

useEffect(() => {
  const currentIndex = loopNum % phrases.length;
  const fullText = phrases[currentIndex];

  const handleTyping = () => {
    setText(current => {
      const baseText = isDeleting ? current.slice(0, current.length - 1) : (current.length < fullText.length ? current + fullText[current.length] : current);
      if (!isDeleting && baseText === fullText) {
        setTimeout(() => setIsDeleting(true), 1000);
      } else if (isDeleting && baseText === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
      }
      return baseText || '';
    });
  };

  const timer = setTimeout(handleTyping, typingSpeed);

  return () => clearTimeout(timer);
}, [text, isDeleting, loopNum, phrases]);

/* return (
    <MainContainer>
      <InsideContainer>
        <StyledBox>
          <div className="turn-your-pdfs-into">
            Turn your <span className="highlight-pdf">PDFs</span> into <br />
            <span className="highlight-mindmap">Mind Maps</span>
          </div>

        </StyledBox>

        <StyledImageLarge
          src={`${process.env.PUBLIC_URL}/${backgroundPdfImage}`} 
          alt="Background PDF" 
          
        />

        <StyledImageSmall
          src={`${process.env.PUBLIC_URL}/${mindMapImage}`} 
          alt="Mind Map" 
          
        />

        <StyledFrame>
          <StyledLink className="link" onClick={handleLogin}>Start for Free</StyledLink>
          <div className="div">*No credit card required</div>
          <img 
            src={`${process.env.PUBLIC_URL}/${iconImage}`} 
            alt="Icon" 
            style={{ width: '250px', height: 'auto', margin: '30px 0' }} // Adjust size and margin as necessary
          />

          <div className='videoContainerHero'>
            <iframe
              width="760"
              height="450"
              paddingBottom="50px"
              src={youTubeEmbedUrl}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </StyledFrame>
      </InsideContainer>
    </MainContainer>
  );
};

*/

return (
  <>
  <div className="hero-container">

    <div style={{ display: 'none' }}>
      <MindmapSumComponent onSumUpdate={setSumOfMindmaps} />
    </div>
    <div className="hero-text">
      <h1>
        <span>Turn your </span>
        <span className="highlight-pdf">{text}</span> into <br />
        <span className="highlight-mindmap">Mind Maps</span>
      </h1>
      <p className="hero-description">Say goodbye to information overload and hello to streamlined knowledge retention and sharing</p>
      <StyledLink className="link" onClick={handleLogin}>Start for Free</StyledLink>
      <div className="hero-note">*No credit card required</div>
    </div>
    <div className="video-container">
      <iframe
        width="760"
        height="450"
        src={youTubeEmbedUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  </div>
  <div className="trust-building">
    <div className="trust-building-container hero-review">
      <img src={`${process.env.PUBLIC_URL}/quotation-image.png`} alt="quote" />
      <p className="review">Impressed with the efficiency and simplicity. It streamlines research tasks and improves productivity. A must-have for academics.</p>
      <p className="reviewer-designation"> - Jamie Lee</p>
    </div>
    <div className="trust-building-container hero-join">
      <p>Join 100,000+ happy mind mappers and counting </p>
      <div className="user-images">
        <img src={`${process.env.PUBLIC_URL}/users/andrew.jpg`} alt="User 1" />
        <img src={`${process.env.PUBLIC_URL}/users/katrina.jpg`} alt="User 2" />
        <img src={`${process.env.PUBLIC_URL}/users/moe.jpg`} alt="User 3" />
        <img src={`${process.env.PUBLIC_URL}/users/giselle.jpg`} alt="User 4" />
        <img src={`${process.env.PUBLIC_URL}/users/christina.jpg`} alt="User 5" />
      </div>
    </div>
    <div className="trust-building-container hero-generated-today">
      <animated.span className="highlight-mindmap counter">
        {number.interpolate((value) => Math.floor(value))}
      </animated.span>
      <div style={{ padding: '16px' }}>Maps generated in last 24 hours</div>
      <img src={`${process.env.PUBLIC_URL}/Backicon.png`} alt="PDF to Mind Map Icon" />
    </div>
  </div>
</>
);
};

export default NewHero;