import fetchMetadata from './getMetaData';

const isSummaryLoading = async (fileKey, identityId) => {
  //console.log('Checking if summary is loading...', fileKey, identityId);
  if (!identityId || !fileKey) {
    console.error("Missing required parameters: identityId or fileKey.");
    return false;
  }

  try {
    // Fetch metadata and handle the promise
    const response = await fetchMetadata(fileKey);
    const metadata = JSON.parse(response.body);

    // Check if metadata "is_summary_loading" exists and evaluate its value
    if (metadata && metadata.is_summary_loading) {
      //console.log('Metadata from loading:', metadata);
      return metadata.is_summary_loading === 'true';
    } else {
      return false;
    }
  } catch (err) {
    //console.error('Error fetching metadata:', err);
    return false;
  }
};

export default isSummaryLoading;
