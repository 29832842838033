// updateUserGroupMailterlite.js

const updateUserGroupMailterlite = (apiKey, subscriberData) => {
        fetch(`https://connect.mailerlite.com/api/subscribers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${apiKey}`
            },
            body: JSON.stringify(subscriberData)
        })
        .then(response => response.json())
        .catch(error => console.error('Error:', error));
};

export default updateUserGroupMailterlite;