import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { downloadData, uploadData } from 'aws-amplify/storage';
import './EditModal.css';
import FontAwesomeIconDropdown from './FontAwesomeIconDropdown';
import { FaTimesCircle } from 'react-icons/fa';
import PricingTiersDisplay from '../ui-components/ResponsivePricingComponent/ResponsivePricing';
import { MantineProvider } from '@mantine/core';

const EditModal = ({ isOpen, onClose, fileKey, onMindMapSaved, membershipPlan, monthlyLimit }) => {
    const [originalContent, setOriginalContent] = useState('');
    const [editableQuotes, setEditableQuotes] = useState([]);
    const [normalizedQuotes, setNormalizedQuotes] = useState([]);
    const [content, setContent] = useState('');
    const modalRef = useRef();
    const pricingModalRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [showSavedMessage, setShowSavedMessage] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(''); // Stores the error message text
    const [isPricingPopupOpen, setPricingPopupOpen] = useState(false); // New state for pricing popup visibility
    const [saveTimeoutId, setSaveTimeoutId] = useState(null);
    const saveTimeoutRef = useRef(null);
    const [autosaveStatus, setAutosaveStatus] = useState('All changes saved');
    const [showEditModal, setShowEditModal] = useState(isOpen);

    const handleKeyDown = (event) => {
        if (event.key === 'Escape' && isPricingPopupOpen === false) {
            onClose();
        }
    };

    // New function to toggle the pricing popup
    const togglePricingPopup = () => {
        setPricingPopupOpen(!isPricingPopupOpen);
        if(isPricingPopupOpen === true){
            setShowEditModal(false);
        }
    };

    const handleUpgradeNavigation = (pricingState = 'monthly') => {
        window.open(`/pricing?state=yearly`, '_blank');
      }


    useEffect(() => {
        if (showError) {
            // Apply the slide-up animation when the message is set to show
            const overlay = document.querySelector('.error-message-overlay');
            if (overlay) {
                overlay.style.animation = 'slideUpFromBottom 0.5s ease-out forwards';
            }
    
            // Set a timer to initiate the hide animation and then hide the message
            const timer = setTimeout(() => {
                if (overlay) {
                    overlay.style.animation = 'slideDownToBottom 0.5s ease-out forwards';
                    // Wait for the animation to finish before hiding the message
                    setTimeout(() => setShowError(false), 500);
                }
            }, 5000); // Adjust timing as needed
    
            return () => clearTimeout(timer);
        }
    }, [showError]);

    useEffect(() => {     
        if (showEditModal) {
            loadFileContent();
            document.addEventListener('keydown', handleKeyDown);
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.addEventListener('mousedown', handleClickOutside);
        };
    }, [showEditModal, isPricingPopupOpen]);


    const loadFileContent = async () => {
        setLoading(true);
        try {
            const downloadResult = await downloadData({
                key: `${fileKey.replace(/(\.pdf|\.txt)$/, '_summary.txt')}`,
                options: { accessLevel: 'private' }
            }).result;
            const textContent = await downloadResult.body.text();
            setOriginalContent(textContent);
            extractQuotedTextWithIndentation(textContent);
        } catch (error) {
            //console.error('Error loading file content:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            loadFileContent();
            document.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen]);


    const extractQuotedTextWithIndentation = (textContent) => {
        const lines = textContent.split('\n');
        const quotes = [];
        let currentComment = ''; // Variable to hold the current comment
    
        lines.forEach((line, index) => {
            const indentation = countLeadingWhitespace(line);
            const textMatch = line.match(/"([^"]*)"/);
            const iconMatch = line.match(/::icon\((fa fa-[^\)]+)\)/);
            const commentMatch = line.match(/^\s*%%\s*(.*)/); // Regex to capture comments with any number of whitespaces before and after
    
            if (commentMatch) {
                currentComment = commentMatch[1]; // Update the current comment
                return; // Skip further processing for this line since it's a comment
            }
    
            if (textMatch) {
                quotes.push({
                    text: textMatch[1],
                    editedText: textMatch[1],
                    icon: null,
                    editedIcon: null,
                    indentationLevel: indentation,
                    comment: currentComment, // Assign the current comment to this quote
                    editedComment: currentComment, // Also keep an editable version of the comment
                });
                currentComment = ''; // Reset the comment for the next iteration
            }
    
            if (iconMatch && quotes.length > 0 && quotes[quotes.length - 1].text) {
                quotes[quotes.length - 1].icon = iconMatch[1];
                quotes[quotes.length - 1].editedIcon = iconMatch[1];
                // If there's a comment associated with an icon, it applies to the previous quote
                if (currentComment) {
                    quotes[quotes.length - 1].comment = currentComment;
                    quotes[quotes.length - 1].editedComment = currentComment;
                    currentComment = ''; // Reset the comment after assigning it
                }
            }
        });
    
        setEditableQuotes(quotes);
    };
    

    const countLeadingWhitespace = (str) => {
        const match = str.match(/^\s*/);
        return match ? match[0].length : 0;
    };    

    //When editableQuotes is updated, the useEffect hook that depends on editableQuotes is triggered. 
    //This hook calls normalizeIndentationRelativeToMinimum with editableQuotes and sets normalizedQuotes to the 
    //returned value.

    React.useEffect(() => {
        if (editableQuotes.length > 0) {
            const normalized = normalizeIndentationRelativeToMinimum(editableQuotes);
            setNormalizedQuotes(normalized);
        }
    }, [editableQuotes]);


    //The component renders a modal with an input for each quote in normalizedQuotes. 
    //Each input's value is bound to the editedText property of the corresponding quote, and 
    //its onChange handler updates editableQuotes with the new text.

    const normalizeIndentationRelativeToMinimum = (quotes) => {
        // Find the minimum non-zero indentation level among all quotes
        const minIndentation = quotes.reduce((min, quote) => {
            if (quote.indentationLevel > 0 && (min === 0 || quote.indentationLevel < min)) {
                return quote.indentationLevel;
            }
            return min;
        }, 0);

        // Normalize indentation levels relative to the minimum found
        return quotes.map(quote => {
            if (quote.indentationLevel === 0) {
                // Keep level 0 as is
                return { ...quote, normalizedIndentationLevel: 0 };
            } else {
                // Adjust non-zero levels relative to the minimum indentation
                const normalizedLevel = Math.max(1, Math.round(quote.indentationLevel / minIndentation));
                return {
                    ...quote,
                    normalizedIndentationLevel: normalizedLevel
                };
            }
        });
    };

    const handleClickOutside = (event) => {
        // Check if the click is outside the main modal when the pricing popup is not open
        if (!isPricingPopupOpen && modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isPricingPopupOpen]); // Ensuring the listener is added or removed based on the popup state
    


    const handleSave = async () => {
        setAutosaveStatus('Saving...');

        setLoading(true);
        setShowError(false);
        setAutosaveStatus('Saving...');
    
        try {
            // Regenerate the document content from editableQuotes
            setAutosaveStatus('Saving...');
            let updatedContent = "mindmap\n"; // Starting with a mindmap root
            const rootQuote = editableQuotes[0]; // Assuming the first quote is always the root
            if (rootQuote) {
                if (rootQuote.editedComment) {
                    // Include the comment for the root if it exists
                    updatedContent += `%% ${rootQuote.editedComment}\n`;
                }
                updatedContent += `\troot("${rootQuote.editedText}")\n`; // Format the root quote with edited text
                if (rootQuote.editedIcon) {
                    updatedContent += `::icon(${rootQuote.editedIcon})\n`; // Add icon for root if exists
                }
            }
    
            editableQuotes.forEach((quote, index) => {
                if (index === 0) return; // Skip the root quote as it's already processed
    
                if (quote.editedComment) {
                    // Add the edited comment before the quote if it exists
                    const commentTabs = "\t".repeat(Math.max(0, quote.indentationLevel - 1)); // Adjusted for correct indentation of the comment
                    updatedContent += `${commentTabs}%% ${quote.editedComment}\n`;
                }
    
                // Add tabs based on indentation level to respect hierarchy
                const tabs = "\t".repeat(quote.indentationLevel); // Adjusted for correct indentation
                updatedContent += `${tabs}("${quote.editedText}")\n`; // Use editedText for updated quote text
                
                if (quote.editedIcon) {
                    // Assuming the icon syntax needs to be directly after the quote it's associated with
                    updatedContent += `${tabs}::icon(${quote.editedIcon})\n`;
                }
            });
    
            // Create a new file object with the updated content
            const file = new File([updatedContent], fileKey, { type: 'text/plain' });
    
            // Upload the file
            await uploadData({
                key: `${fileKey.replace(/(\.pdf|\.txt)$/, '_summary.txt')}`,
                data: file,
                options: { accessLevel: 'private' }
            });
            
            onMindMapSaved();
            setTimeout(() => {
                // After saving successfully and waiting for 2 seconds, update the autosave status
                setAutosaveStatus('All changes saved');
                // Optionally, use another timeout to revert the status back to "idle" after a few seconds
                setTimeout(() => setAutosaveStatus('All changes saved'), 1000);
            }, 1000); // 2000 milliseconds = 2 seconds delay

    
        } catch (error) {
            //console.error('Error saving file:', error);
        } finally {
            setLoading(false);
        }
    };   
    
    useEffect(() => {
        if (saveTimeoutRef.current) clearTimeout(saveTimeoutRef.current);

        saveTimeoutRef.current = setTimeout(() => {
            handleSave();
        }, 1000); // Autosave delay

        return () => clearTimeout(saveTimeoutRef.current);
    }, [editableQuotes]);
    
    const handleEditChange = (index, newText) => {
        const updatedQuotes = editableQuotes.map((quote, i) => {
            if (i === index) {
                return { ...quote, editedText: newText };
            }
            return quote;
        });
        setEditableQuotes(updatedQuotes);
            // Clear any existing timeout to reset the timer for autosave
            if (saveTimeoutId) clearTimeout(saveTimeoutId);

            // Set a new timeout to call handleSave after 1 second of inactivity
            const newTimeoutId = setTimeout(() => {
                handleSave();
            }, 100); // Adjust the delay as needed

    };



    const handleIconChange = (index, newIconClass) => {
        const updatedQuotes = editableQuotes.map((quote, i) => {
            if (i === index) {
                return { ...quote, editedIcon: newIconClass };
            }
            return quote;
        });
        setEditableQuotes(updatedQuotes);
            // Clear any existing timeout to reset the timer for autosave
            if (saveTimeoutId) clearTimeout(saveTimeoutId);

            // Set a new timeout to call handleSave after 1 second of inactivity
            const newTimeoutId = setTimeout(() => {
                handleSave();
            }, 1000); // Adjust the delay as needed

            setSaveTimeoutId(newTimeoutId);
    };

        // Make sure to clean up the timeout when the component unmounts
        useEffect(() => {
            return () => {
                if (saveTimeoutId) clearTimeout(saveTimeoutId);
            };
        }, [saveTimeoutId]);

    const handleIconClick = (index) => {
        setActiveDropdown(activeDropdown === index ? null : index);
    };


    const handleAddNode = (index, normalizedIndentationLevel) => {
        const clickedNode = normalizedQuotes[index];
        let newIndentationLevel = clickedNode.normalizedIndentationLevel + 1;
        let newClassName;

        // If the clicked node has children, set the className of the new node to be the same as the className of the clicked node
        if (clickedNode.children && clickedNode.children.length > 0) {
            newClassName = clickedNode.className;
            //console.log('New Class Name if clicked node has children:', newClassName);
        } 
        // If the clicked node does not have children, set the className of the new node to be 'indent-level-x', where x is the indentationLevel of the new node plus 1
        else {
            newClassName = `indent-level-${newIndentationLevel}`;
            //console.log('New Class Name if clicked node does not have children:', newClassName);
        }

        //('Clicked Node:', clickedNode);
        //console.log('New Indentation Level:', newIndentationLevel);
        //console.log('New Class Name:', newClassName);

        const newNode = {
            text: '',
            editedText: '',
            icon: null,
            editedIcon: null,
            indentationLevel: newIndentationLevel,
            className: newClassName, // Set the className of the new node
            isNew: true // Mark the node as new
        };

        const updatedQuotes = [...normalizedQuotes];
        updatedQuotes.splice(index + 1, 0, newNode);
        setNormalizedQuotes(updatedQuotes);

        // Update editableQuotes
        const updatedEditableQuotes = [...editableQuotes];
        updatedEditableQuotes.splice(index + 1, 0, newNode);
        setEditableQuotes(updatedEditableQuotes);
            // Clear any existing timeout to reset the timer for autosave
            if (saveTimeoutId) clearTimeout(saveTimeoutId);

            // Set a new timeout to call handleSave after 1 second of inactivity
            const newTimeoutId = setTimeout(() => {
                handleSave();
            }, 1000); // Adjust the delay as needed

            setSaveTimeoutId(newTimeoutId);
    };

        // Make sure to clean up the timeout when the component unmounts
        useEffect(() => {
            return () => {
                if (saveTimeoutId) clearTimeout(saveTimeoutId);
            };
        }, [saveTimeoutId]);

    const handleDelete = (deleteIndex, deleteText) => {
        // 1. Find the node to be deleted by index and text, to confirm it's the correct node.
        const targetNode = normalizedQuotes[deleteIndex];
        const deleteIndentLevel = targetNode.normalizedIndentationLevel;
    
        if (targetNode.text !== deleteText) {
            //console.error("Node text does not match. Deletion aborted.");
            return;
        }
    
        // 2. Use a flag to mark when deletion should start and stop.
        let shouldDelete = false;
        const filteredQuotes = normalizedQuotes.filter((quote, index) => {
            if (index === deleteIndex) {
                shouldDelete = true; // Start deletion with the matched node.
                return false; // Remove the initial node.
            }
            if (shouldDelete) {
                // Continue deletion until a node with the same or lesser indent level is found.
                if (quote.normalizedIndentationLevel <= deleteIndentLevel) {
                    shouldDelete = false; // Stop deleting.
                    return true; // Keep the node that stops the deletion.
                }
                return false; // Delete nodes under the target node.
            }
            return true; // Keep all nodes before the target node.
        });
    
        // 3. Update the state with the filtered list.
        setNormalizedQuotes(filteredQuotes);
        // 4. Recompute editableQuotes based on the changes in normalizedQuotes
        const updatedEditableQuotes = normalizeIndentationRelativeToMinimum(filteredQuotes);
        //console.log('Updated editable quotes:', updatedEditableQuotes);
        setEditableQuotes(updatedEditableQuotes);
        handleSave();
    };

    if (!isOpen) return null;

    return (
        <>
        <div className={`edit-modal-overlay ${(membershipPlan === "Freemium" || membershipPlan === "Pay Per Use") ? "blur" : ""}`} ref={modalRef} style={isPricingPopupOpen ? { display: 'none' } : { display: 'block' }}>
        {(membershipPlan === "Freemium" || membershipPlan === "Pay Per Use") && (
                    <div className="freemium-overlay">
                        <p>Upgrade to unlock editing features </p>
                        <button onClick={(e) => handleUpgradeNavigation(e, 'yearly')}>Upgrade to Edit✨</button>
                    </div>
        )}
            <div className="editing-modal"  style={{
                 pointerEvents: membershipPlan === "Freemium" ? 'none' : 'auto'
             }}>
            {showSavedMessage && (
                                    <div className="saved-message-overlay">
                                        <div className="saved-message">Saved!</div>
                                    </div>
            )}
                <div className='title-of-map'><h3>Editing Mode ✍🏼</h3>
                <span className={`autosave-status ${autosaveStatus}`}>{autosaveStatus}</span>
                </div>
                {showError && errorMessage && (
                    <div className="error-message-overlay">
                        <div className="error-message-modal">
                            {errorMessage}
                            <button className="error-message-close" onClick={() => setShowError(false)}><FaTimesCircle/></button>
                        </div>
                    </div>
                    
            )}
                    {editableQuotes.map((quote, index) => {
                        const indentClass = `indent-level-${quote.indentationLevel}`;
                        const isError = quote.editedText && quote.editedText.trim() === '';
                        const quoteClass = `editable-quote-bubble ${indentClass} ${isError ? 'error' : ''}`;
                        const marginLeft = quote.indentationLevel > 2 ? `${20 * quote.indentationLevel}px` : '0';

                        return (
                            <React.Fragment key={index}>
                                <div className={quoteClass} style={{ marginLeft }}>
                                    {quote.icon && (
                                        <i 
                                            className={quote.editedIcon}
                                            onClick={() => handleIconClick(index)}
                                            style={{ cursor: 'pointer', marginLeft: '5px' , marginRight: '5px' ,marginBottom: '10px', position: 'relative', right: '4px'  }}
                                        ></i>
                                    )}
                                    {activeDropdown === index && (
                                        <FontAwesomeIconDropdown 
                                            selectedIcon={quote.editedIcon}
                                            onIconChange={(option) => handleIconChange(index, option.value)}
                                            isVisible={activeDropdown === index}
                                        />
                                    )}
                                    <input
                                        className="editable-quote-input"
                                        type="text"
                                        value={quote.editedText}
                                        onChange={(e) => handleEditChange(index, e.target.value)}
                                        disabled={loading}
                                    />
                                    <div className="add-delete-buttons-container">
                                        <button
                                            className="add-node-button"
                                            onClick={() => handleAddNode(index, quote.normalizedIndentationLevel)}
                                            disabled={loading}
                                        >
                                            <img src={`${process.env.PUBLIC_URL}/plus-icon.png`} alt="Add" />
                                        </button>

                                        {index !== 0 && (
                                            <button
                                                className="delete-node-button"
                                                onClick={() => handleDelete(index, quote.text)}
                                            >
                                                <img src={`${process.env.PUBLIC_URL}/minus-icon.png`} alt="Delete" />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
            </div>

        </div>
        <Modal 
            show={isPricingPopupOpen} 
            onHide={() => setPricingPopupOpen(false)} 
            centered 
            className='pricing-modal'>
            <Modal.Body style={{ background: 'transparent', width: '80vw' }}>
                <MantineProvider>
                    <PricingTiersDisplay currentPlanProps={{monthlyLimit: monthlyLimit, currentPlan: membershipPlan}} />
                </MantineProvider>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default EditModal;