import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import TextField from '@mui/material/TextField';
import { Container } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function CustomInstructionsForm({ onSubmit }) {
    const [activeStep, setActiveStep] = useState(0);
    const [answers, setAnswers] = useState({
        mainTopic: '',
        exclusions: '',
    });

    // Create a theme instance.
    const theme = createTheme({
        palette: {
            primary: {
                main: '#f471ff',
            },
        },
    });

    const handleNext = () => {
        // Move to next step or submit if it's the last step
        if (activeStep === steps.length - 1) {
            onSubmit(answers);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleAnswerChange = (event) => {
        const { name, value } = event.target;
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [name]: value,
        }));
    };

    const steps = ['Main Topic', 'Exclusions'];

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <TextField name="mainTopic" label="What topic do you want the mindmap for?" variant="outlined" fullWidth value={answers.mainTopic} onChange={handleAnswerChange} />;
            case 1:
                return <TextField name="exclusions" label="What do you want to exclude from the mindmap?" variant="outlined" fullWidth value={answers.exclusions} onChange={handleAnswerChange} />;
            default:
                return 'Unknown step';
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="sm">
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box sx={{ mt: 2 }}>
                    {getStepContent(activeStep)}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default CustomInstructionsForm;