import React from 'react';
import './deleteMenu.css'; 
import { FaTimes, FaExclamationCircle, FaItalic } from 'react-icons/fa';

const Modal = ({ isOpen, onClose, onConfirm, fileName }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="delete-modal">
        <FaTimes className="modal-close" onClick={onClose} />
        <div className="delete-modal-content">
          <FaExclamationCircle className="warning-icon" />
          <h2 className="modal-title">Delete "{fileName}"?</h2>
          <p className="modal-body">
            Deleting "{fileName}" is permanent and cannot be undone.
          </p>
          <div className="modal-action-buttons">
            <button className="modal-cancel" onClick={onClose}>No, Keep it</button>
            <button className="modal-delete" onClick={onConfirm}>Yes, Delete it</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
