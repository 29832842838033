import React from 'react';
import PricingTier1 from './Comp1/main';
import PricingTier3 from './Comp3/main';

const PricingTiersDisplay = ({ currentPlanProps }) => {
  const { monthlyLimit, currentPlan } = currentPlanProps;

  const highlightTier = (limit) => {
    if (limit === 1 || limit === 3) return 'tier1';
    if (limit === 10) return 'tier2';
    if (limit === 25) return 'tier3';
    return null; // Default or unhighlighted state
  };

  const tierToHighlight = highlightTier(monthlyLimit);

  // Grid container styles
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    // Align items to the center vertically
    alignItems: 'center',
    // Add padding if necessary
    padding: '20px',
  };

  return (
    <div className="pricing-tiers-grid" style={containerStyle}>
      <PricingTier3 isHighlighted={tierToHighlight === 'tier3'} />
    </div>
  );
};

export default PricingTiersDisplay;
