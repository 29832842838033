const mockData = {
  projectMemberLimitOptions: [
    { projectMemberLimit: "250 credits/month (~ 25 mindmaps)" },
    { projectMemberLimit: "Early access to new features" },
    { projectMemberLimit: "Export without watermark" },
    { projectMemberLimit: "No limit on file size" },
    { projectMemberLimit: "Priority Customer Support Via Email" },
  ],
};

export { mockData };
