import { useEffect, useState } from 'react';
import getUserData from '../userManagement/getUserData';

const useFetchUserData = () => {
  const [userData, setUserData] = useState({
    userCurrentUsage: 0,
    userMembershipPlan: '',
    userMonthlyLimit: 0,
    userID: '',
    reviewExists: false,
    userExceededLimit: false,
    dataForNav: {},
    isFirstMindMap: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUserData();
      setUserData({
        userCurrentUsage: data.userUsage.CurrentMonthUsage,
        userMembershipPlan: data.MembershipPlan,
        userMonthlyLimit: data.userUsage.MonthlyLimit,
        userID: data.userID,
        reviewExists: 'reviews' in data,
        userExceededLimit: data.userUsage.CurrentMonthUsage === data.userUsage.MonthlyLimit,
        dataForNav: {
          userName: data.Name,
          userEmail: data.Email,
          currentPlan: data.MembershipPlan,
          currentUsage: data.userUsage.CurrentMonthUsage,
          monthlyLimit: data.userUsage.MonthlyLimit,
        },
        isFirstMindMap: data.isFirstMindMap,
      });
    };
    fetchData();
  }, []);

  return userData;
};

export default useFetchUserData;
