import React from "react";
import mermaid from "mermaid";
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Mermaid extends React.Component {
  componentDidMount() {
    mermaid.initialize({
      startOnLoad: true,
      theme: "default",
      securityLevel: "loose",
      fontFamily: "Helvetica Neue",
      fontColor: "white",
      interaction: {
        enabled: true,
      },
      themeCSS: `
      text, tspan {
        fill: #000000 !important;
      }
      .fa {
        color: black;
      }
      .mindmap-node.section--1.section-root .node-bkg {
        fill: #A7D8F7 !important; /* Replace #color1 with the actual color for section-0 */
      }
      .mindmap-node.section-0 .node-bkg {
        fill: #FF85FF !important; /* Replace #color1 with the actual color for section-0 */
      }
      .mindmap-node.section-1 .node-bkg {
        fill: #87FFFF !important; /* Replace #color2 with the actual color for section-1 */
      }
      .mindmap-node.section-2 .node-bkg {
        fill: #FF838D !important; /* Replace #color3 with the actual color for section-2 */
      }
      .mindmap-node.section-3 .node-bkg {
        fill: #D7FF86 !important; /* Replace #color3 with the actual color for section-2 */
      }
      .mindmap-node.section-4 .node-bkg {
        fill: #FEFE7B !important; /* Replace #color3 with the actual color for section-2 */
      }
      .mindmap-node.section-5 .node-bkg {
        fill: #C084F4 !important; /* Replace #color3 with the actual color for section-2 */
      }
      .mindmap-node.section-6 .node-bkg {
        fill: #87FFC2 !important; /* Replace #color3 with the actual color for section-2 */
      }
      .node-circle {
        stroke-width: 2px !important;
      }
      .node-no-border {
        stroke-width: 0 !important;
      }
      path.edge.section-edge-0 {
        stroke: #FF85FF !important; /* Color for section-edge-0 */
      }
      path.edge.section-edge-1 {
        stroke: #87FFFF !important; /* Color for section-edge-0 */
      }
      path.edge.section-edge-2 {
        stroke: #FF838D !important; /* Color for section-edge-0 */
      }
      path.edge.section-edge-3 {
        stroke: #D7FF86 !important; /* Color for section-edge-0 */
      }
      path.edge.section-edge-4 {
        stroke: #FEFE7B !important; /* Color for section-edge-0 */
      }
      path.edge.section-edge-5 {
        stroke: #C084F4 !important; /* Color for section-edge-0 */
      }
      path.edge.section-edge-6 {
        stroke: #87FFC2 !important; /* Color for section-edge-0 */
      }
      [class^="node-line-"] {
        stroke: #2C3E50 !important;
      }
      .fa.icon-container {
        color: inherit !important;
        font-size: inherit !important;
      }
    `,
    nodeSpacing: 200, // Adjust this value to increase horizontal spacing
    rankSpacing: 200, // Adjust this value to increase vertical spacing
    });
    mermaid.contentLoaded();
  }
  

  render() {
    return (
      <>
        <style>
          {`
          .transform-component-module_wrapper__SPB86{
            width:100%;
          }
                      .mermaid {
                        width: 100%; /* Ensure mermaid div takes full container width */
                        overflow-x: auto; /* Allows scrolling on smaller screens */
                      }
            .mermaid .mindmap-node rect, 
            .mermaid .mindmap-node circle, 
            .mermaid .mindmap-node polygon, 
            .mermaid .mindmap-node path {
            fill: #ffff; /* Neutral fill color */
            stroke: #bbbbbb; /* Neutral stroke color for better definition */
            stroke-width: 1px; /* Defines the thickness of the node outlines */
                      }
          
            .mermaid .mindmap-node:hover rect, 
            .mermaid .mindmap-node:hover circle, 
            .mermaid .mindmap-node:hover polygon, 
            .mermaid .mindmap-node:hover path {
              stroke: #666; /* Color of the border */
              stroke-width: 2px; /* Thickness of the border */
              fill-opacity: 1; /* Keep the fill color unaffected */
              
            }
            /* Targeting the text within the root node */
            .mindmap-node.section-root text,
            .mindmap-node.section-root text tspan {
                font-weight: bolder; /* Make the text bolder */
                font-size: 1.05em; /* Increase the font size */
                /* Additional styling here if needed */
            }
            
            /* Optionally, if you want the background rectangle of the root node to stand out */
            .mindmap-node.section-root .node-bkg {
              font-weight: bolder; 
              fill: #f0f0f0; /* Change background color */
              stroke: #333; /* Change border color */
              stroke-width: 2px; /* Make the border thicker */
            }
            .mermaid .mindmap-node:hover{
              cursor: pointer;
              scale: 1.01;
            }
          `}
        </style>
        <pre id="mermaid-chart" className="mermaid" >
          {this.props.chart}
        </pre>
      </>
    );
  }
}
