import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, Grid, Card, CardContent, Typography, Button, ToggleButtonGroup, ToggleButton } from '@mui/material';
import NavBar from '../ui-components/LandingPage/Navbar';
import WallOfLove from '../ui-components/LandingPage/WallOfLove';
import { handleUpgradeClick } from '../util/HandleUpgrade';
import AWS from 'aws-sdk';
import { fetchAuthSession } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { useLocation } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: { main: '#000000' },
    background: { default: '#f4f4f4' },
  },
  typography: {
    h5: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1rem',
    }
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          boxShadow: 'none',
          padding: '20px',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          padding: '10px 25px',
          fontSize: '1rem',
          textTransform: 'none',
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected, &.Mui-selected:hover': {
            color: '#edd1d3',
            backgroundColor: 'black',
            fontWeight: 600
          },
          '&:hover': {
            backgroundColor: '#eeeeee',
          },
        }
      }
    }
  }
});

const ProPlanCardStyle = {
  background: 'linear-gradient(45deg, #edd1d3, #f4a4a6)',
  backgroundSize: '200% 200%',
  animation: 'gradientMove 5s ease infinite',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transform: 'scale(1.05)',
  transition: 'transform 0.3s ease',
};

function Pricing() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialState = searchParams.get('state');
  const validStates = ['monthly', 'yearly', 'buyCredits'];
  const defaultState = validStates.includes(initialState) ? initialState : 'yearly';
  const [billingCycle, setBillingCycle] = useState(defaultState);
  const [loading, setLoading] = useState(false); // State to manage loading state of the button
  const [membershipPlan, setMembershipPlan] = useState('Loading...');
  const [userDetails, setUserDetails] = useState({
    sub: 'Loading...',
    name: 'Loading...',
    email: 'Loading...',
    planPrice: 'Loading...',
    renewalDate: 'Loading...',
    monthlyLimit: 'Loading...',
    TrialStatus: 'Loading...',
    TrialEnd: 'Loading...',
    cancelAtPeriodEnd: 'Loading...',
    cancelAt: 'Loading...'
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);  // New state to track login status
  const navigate = useNavigate();  // Use navigate hook for navigation

  const SALE_END_DATE = new Date('2024-07-15'); // Define the sale end date

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const session = await fetchAuthSession();
        if (session && session.credentials) {
          setIsLoggedIn(true);  // Set login status to true
          AWS.config.update({
            region: 'us-east-1',
            credentials: {
              accessKeyId: session.credentials.accessKeyId,
              secretAccessKey: session.credentials.secretAccessKey,
              sessionToken: session.credentials.sessionToken,
            },
          });

          const dynamoDb = new AWS.DynamoDB.DocumentClient();
          const tableName = 'pdftomindmapUsers';
          const userId = session.userSub;

          const getParams = {
            TableName: tableName,
            Key: { 'userID': userId },
          };

          const data = await dynamoDb.get(getParams).promise();
          if (data && data.Item) {
            const membershipPlan = data.Item.MembershipPlan;
            setMembershipPlan(membershipPlan);
            const planPrice = membershipPlan === 'Freemium' ? '$0' : `$${data.Item.InvoiceDetails.Amount / 100}`;
            const renewalDate = new Date(data.Item.userUsage.ResetDate).toLocaleDateString();
            const TrialStatus = data.Item.TrialStatus;
            const TrialEnd = data.Item.TrialEnd;
            const cancelAtPeriodEnd = data.Item.cancelAtPeriodEnd;
            const cancelAt = data.Item.cancelAt;

            setUserDetails({
              sub: data.Item.userID,
              name: data.Item.Name,
              email: data.Item.Email,
              planPrice: planPrice + '/month',
              renewalDate: 'Credits renew on: ' + renewalDate,
              monthlyLimit: data.Item.userUsage.MonthlyLimit,
              TrialStatus: TrialStatus,
              TrialEnd: TrialEnd,
              cancelAtPeriodEnd: cancelAtPeriodEnd,
              cancelAt: cancelAt
            });
          }
        }
      } catch (error) {
        setUserDetails({
          name: 'Error',
          email: 'Error',
          planPrice: 'Error',
          renewalDate: 'Error',
          TrialStatus: 'Error',
          TrialEnd: 'Error',
          cancelAtPeriodEnd: 'Error'
        });
      }
    };

    fetchUserDetails();
  }, []);

  const onUpgradeClick = async ({ planName }) => {
    if (!isLoggedIn) {
      navigate('/login'); // Redirect to login if not logged in
    } else {
      setLoading(true); // Set loading to true before the operation
      try {
        if (planName === 'Free') {
          navigate('/'); // Navigate to home if Free plan is selected
        } else if (planName === 'Pro' && billingCycle === 'monthly' && isSaleActive()) {
          await handleUpgradeClick("$6-discount");
        } else if (planName === 'Pro' && billingCycle === 'monthly' && !isSaleActive()) {
          await handleUpgradeClick("$6");
        } else if (planName === 'Pro' && billingCycle === 'yearly') {
          await handleUpgradeClick("AnnualPro");
        }
      } finally {
        setLoading(false); // Reset loading state after operation
      }
    }
  };

  const onBuyCredits = async () => {
    if (!isLoggedIn) {
      navigate('/login');
    } else {
      setLoading(true);
      try {
         await handleUpgradeClick("buyCredits");
      } finally {
        setLoading(false); // Reset loading state after operation
      }
    }
  };

  const plans = [
    {
      name: 'Free',
      priceMonthly: 0,
      priceYearly: 0,
      discountMonthly: 0,
      features: ['10 credits/month (~2 mindmaps)', "Text or AI Prompt", 'Export with watermark'],
      limitations: ['No PDF uploads', 'Export without watermark'],
      cardColor: '#ffffff',
      borderRadiusMonthly: '0 0 10px 10px'
    },
    {
      name: 'Pro',
      priceMonthly: 6,
      priceYearly: 4.16,
      discountMonthly: 1.0,
      features: ['250 credits/month (~50 mindmaps)', "PDF upload is included", 'Export without watermark', 'No limit on file size', 'Priority support by email'],
      limitations: [],
      cardColor: '#edd1d3',
      borderRadiusMonthly: '0 0 10px 10px'
    }
  ];

  const handleBillingChange = (event, newBillingCycle) => {
    if (newBillingCycle !== null) {
      setBillingCycle(newBillingCycle);
    }
  };

  function formatPrice(price) {
    if (price % 1 === 0) {
      return <span>{price}</span>;
    } else {
      const parts = price.toFixed(2).split('.');
      return (
        <>
          <span>{parts[0]}</span>
          <small style={{ fontSize: '14px' }}>.{parts[1]}</small>
        </>
      );
    }
  }

  function isSaleActive() {
    const currentDate = new Date();
    return currentDate < SALE_END_DATE;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavBar />
      <div style={{ padding: 20, maxWidth: 800, margin: '0 auto' }}>

        <Typography variant="h4" gutterBottom style={{ textAlign: 'center', padding: '20px' }}>
          Simple pricing for all needs
        </Typography>
        <div style={{ textAlign: 'center', paddingBottom: '40px' }}>
          <ToggleButtonGroup
            color="primary"
            value={billingCycle}
            exclusive
            onChange={handleBillingChange}
            aria-label="Billing cycle"
          >
            <ToggleButton sx={{ textTransform: 'none' }} value="buyCredits">Get Credits</ToggleButton>
            <ToggleButton sx={{ textTransform: 'none' }} value="monthly">Monthly</ToggleButton>
            <ToggleButton sx={{ textTransform: 'none' }} value="yearly">Yearly</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Grid container spacing={2} justifyContent="center" style={{ paddingBottom: '40px'}}>
          {billingCycle === 'buyCredits' && (
            <>
            <Grid item xs={12} sm={6}>
            <Typography variant="caption" component="div" style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', color: 'black', borderRadius: '10px 10px 0 0', background: 'linear-gradient(45deg, #bfd5e3 50%, #80c0e8)', }}>
                  <img src="shine.png" style={{ width: '16px', textAlign:'center' }} alt="Check mark" /> NEW! Top up credits for free plan!
                </Typography>
              <Card raised style={{   backgroundColor: 'linear-gradient(to right, #3e5151, #decba4)', borderRadius: '0px 0px 10px 10px', textAlign: 'center' }}>
                
                <CardContent>
                <div style={{ textAlign: 'center'}}>
                <Link href="/how-credits-work" variant="body2">
                  {'How credits work 👈🏼'}
                </Link>
                </div>
                  <Typography variant="p" component="div" style={{ textAlign: 'center', fontSize: '40px' }} gutterBottom>
                    100 credits 
                  </Typography>
                  <>
                    <div style={{ textAlign: 'center', position: 'relative', display: 'inline-block', fontSize: '20px', fontWeight: '200' }}>
                      <span style={{ position: 'relative', zIndex: 1, fontSize: '12px', textAlign: 'center' }}>
                        <span style={{ fontSize: '20px' }}>$</span> <span style={{ fontSize: '2.5rem', fontWeight: '700' }}>{formatPrice("6")}</span><span style={{ fontSize: '12px' }}>/100 credits</span>
                      </span>
                      <span style={{
                        position: 'absolute',
                        top: '60%',
                        left: 0,
                        width: '100%',
                        borderTop: '5px solid red',
                        zIndex: 10,
                        transform: 'translateY(-50%)'
                      }}></span>
                    </div>
                    &nbsp;
                    <div style={{ display: 'inline-block', fontSize: '20px', fontWeight: '200' }}>
                      $<span style={{ fontSize: '2.5rem', fontWeight: '700' }}>{formatPrice("4")}</span><span style={{ fontSize: '12px' }}>/100 credits</span>
                    </div>
                  </>
                  <Button
                    onClick={onBuyCredits}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, mx: 'auto', display: 'block', borderRadius: '20px', padding: '10px 25px' }}
                    disabled = {loading}
                  >
                    {loading ? 'Processing...' : 'Get Now!'}
                  </Button>
                  <Typography variant="subtitle2" style={{ paddingBottom: '10px', paddingTop: '10px' }} gutterBottom>
                    {'SUPERCHARGE YOUR FREE PLAN:'}
                  </Typography>
                  <Typography style={{textAlign:'left'}} variant="body1" color="primary">
                    <span style={{ marginRight: '10px' }}><img src="check-circle.svg" style={{ marginRight: '10px' }} alt="Check mark" /></span>100 credits<br></br>
                    <span style={{ marginRight: '10px' }}><img src="check-circle.svg" style={{ marginRight: '10px' }} alt="Check mark" /></span>PDF uploads included<br></br>
                    <span style={{ marginRight: '10px' }}><img src="check-circle.svg" style={{ marginRight: '10px' }} alt="Check mark" /></span>Priority support by email<br></br>
                    <span style={{ marginRight: '10px' }}><img src="check-circle.svg" style={{ marginRight: '10px' }} alt="Check mark" /></span>No monthly commitment<br></br>
                    <span style={{ marginRight: '10px' }}><img src="check-circle.svg" style={{ marginRight: '10px' }} alt="Check mark" /></span>Credits never expire<br></br>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            </>
          )}
          {billingCycle !== 'buyCredits' && plans.map((plan) => (
            <Grid item xs={12} sm={6} key={plan.name}>
              {plan.name === 'Pro' && (
                <Typography variant="caption" component="div" style={{ padding: '10px', textAlign: 'center', color: (membershipPlan === 'Pro Plan' || membershipPlan === 'Paid') ? 'green' : '#f5f5f5', borderRadius: '10px 10px 0 0', backgroundColor: (membershipPlan === 'Pro Plan' || membershipPlan === 'Paid') ? '#dff0d8' : '#f5f5f5', }}>
                  {(membershipPlan === 'Pro Plan' || membershipPlan === 'Paid') ? 'Current Plan' : ''}
                </Typography>
              )}
              {plan.name === 'Pro' && isSaleActive() && billingCycle === 'monthly' && !(membershipPlan === 'Pro Plan' || membershipPlan === 'Paid') && (
                <Typography variant="caption" component="div" style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', color: 'black', borderRadius: '10px 10px 0 0', background: 'linear-gradient(45deg, #bfd5e3 50%, #80c0e8)', }}>
                  <img src="shine.png" style={{ width: '16px' }} alt="Check mark" /> Save 85% for 3 months. Offer ends on 15th July 2024
                </Typography>
              )}
              {plan.name === 'Free' && (
                <Typography variant="caption" component="div" style={{ padding: '10px', textAlign: 'center', color: membershipPlan === 'Freemium' ? 'green' : '#f5f5f5', borderRadius: '10px 10px 0 0', backgroundColor: membershipPlan === 'Freemium' ? '#dff0d8' : '#f5f5f5', }}>
                  {membershipPlan === 'Freemium' ? 'Current Plan' : ''}
                </Typography>
              )}
              <Card raised style={{
                  ...((plan.name === 'Pro') ? ProPlanCardStyle : {}),
                  backgroundColor: plan.cardColor,
                  borderRadius: plan.borderRadiusMonthly,
                }}>
                <CardContent>
                  <Typography variant="p" component="div" style={{ textAlign: 'center', fontSize: '40px' }} gutterBottom>
                    {plan.name}
                  </Typography>
                  <Typography variant="h6" component="div" style={{ textAlign: 'center' }} gutterBottom>
                    <div>
                      {plan.name === 'Pro' && isSaleActive() && billingCycle === "monthly" && plan.discountMonthly !== undefined ? (
                        <>
                          <div style={{ position: 'relative', display: 'inline-block', fontSize: '20px', fontWeight: '200' }}>
                            <span style={{ position: 'relative', zIndex: 1, fontSize: '12px' }}>
                              <span style={{ fontSize: '20px' }}>$</span> <span style={{ fontSize: '2.5rem', fontWeight: '700' }}>{formatPrice(plan.priceMonthly)}</span><span style={{ fontSize: '12px' }}>/month</span>
                            </span>
                            <span style={{
                              position: 'absolute',
                              top: '60%',
                              left: 0,
                              width: '100%',
                              borderTop: '5px solid red',
                              zIndex: 10,
                              transform: 'translateY(-50%)'
                            }}></span>
                          </div>
                          &nbsp;
                          <div style={{ display: 'inline-block', fontSize: '20px', fontWeight: '200' }}>
                            $<span style={{ fontSize: '2.5rem', fontWeight: '700' }}>{formatPrice(plan.discountMonthly)}</span><span style={{ fontSize: '12px' }}>/month</span>
                          </div>
                        </>
                      ) : (
                        <>
                            <>
                            {plan.name === 'Pro' && billingCycle === "monthly" && plan.discountMonthly !== undefined && (
                              <>
                              <div style={{ textAlign: 'center', position: 'relative', display: 'inline-block', fontSize: '20px', fontWeight: '200' }}>
                                <span style={{ position: 'relative', zIndex: 1, fontSize: '12px', textAlign: 'center' }}>
                                  <span style={{ fontSize: '20px' }}>$</span> <span style={{ fontSize: '2.5rem', fontWeight: '700' }}>{formatPrice("10")}</span><span style={{ fontSize: '12px' }}>/month</span>
                                </span>
                                <span style={{
                                  position: 'absolute',
                                  top: '60%',
                                  left: 0,
                                  width: '100%',
                                  borderTop: '5px solid red',
                                  zIndex: 10,
                                  transform: 'translateY(-50%)'
                                }}></span>
                              </div>
                              &nbsp;
                              </>
                            )}
                            </>
                            <>
                            {plan.name === 'Pro' && billingCycle === "yearly" && plan.discountMonthly !== undefined && (
                              <>
                              <div style={{ textAlign: 'center', position: 'relative', display: 'inline-block', fontSize: '20px', fontWeight: '200' }}>
                                <span style={{ position: 'relative', zIndex: 1, fontSize: '12px', textAlign: 'center' }}>
                                  <span style={{ fontSize: '20px' }}>$</span> <span style={{ fontSize: '2.5rem', fontWeight: '700' }}>{formatPrice("8")}</span><span style={{ fontSize: '12px' }}>/month</span>
                                </span>
                                <span style={{
                                  position: 'absolute',
                                  top: '60%',
                                  left: 0,
                                  width: '100%',
                                  borderTop: '5px solid red',
                                  zIndex: 10,
                                  transform: 'translateY(-50%)'
                                }}></span>
                              </div>
                              &nbsp;
                              </>
                            )}
                            </>
                          <span style={{ fontSize: '20px', fontWeight: '200' }}>$</span>
                          {billingCycle === 'monthly' ? formatPrice(plan.priceMonthly) : formatPrice(plan.priceYearly)}
                          <small style={{ fontSize: '0.7rem', fontWeight: '100', textAlign: 'center' }}>
                            {plan.priceYearly > 0 ? (billingCycle === 'monthly' ? ' /month' : ' /month') : ''}
                          </small>
                        </>
                      )}
                    </div>
                    {(billingCycle === 'yearly' && plan.priceMonthly > 0) ? (<p style={{ fontSize: '16px', fontWeight: '500', textAlign: 'center' }}> Pay $50/year and save $46</p>) : ''}
                  </Typography>
                  {isLoggedIn && plan.name !== 'Free' ? (
                    <Button
                      onClick={() => onUpgradeClick({ planName: plan.name })}
                      variant="contained"
                      color="primary"
                      sx={{ mb: 2, mx: 'auto', display: 'block', borderRadius: '24px', paddingLeft: '60px', paddingRight: '60px' }}
                      disabled={(plan.name === 'Free' && membershipPlan === 'Freemium') || loading ||
                        (plan.name === 'Pro' && billingCycle === 'yearly' && membershipPlan === 'Annual Pro Plan') ||
                        ((plan.name === 'Pro' && billingCycle === 'monthly' && membershipPlan === 'Pro Plan') || (plan.name === 'Pro' && billingCycle === 'monthly' && membershipPlan === 'Paid'))}
                    >
                      {loading ? 'Processing...' : (plan.name === 'Free' ? 'Sign up' : 'Upgrade')}
                    </Button>
                  ) : null}
                  <Typography variant="subtitle2" style={{ paddingBottom: '10px', paddingTop: '10px' }} gutterBottom>
                    {plan.name === 'Free' ? 'ENJOY, FOR FREE' : 'EVERYTHING IN FREE, PLUS:'}
                  </Typography>
                  {plan.features.map((feature, idx) => (
                    <Typography key={idx} variant="body1" color="primary">
                      <span style={{ marginRight: '10px' }}><img src="check-circle.svg" style={{ marginRight: '10px' }} alt="Check mark" /></span>{feature}
                    </Typography>
                  ))}
                  {plan.limitations.map((limitation, idx) => (
                    <Typography key={idx} style={{background:'#f55f5f', borderRadius:'10px', marginBottom:'4px', color:'white' }} variant="body1" color="primary">
                      <span style={{ marginRight: '10px'}}><img src="cross.png" style={{ marginRight: '10px', width:'24px' }} alt="Cross mark" /></span><s>{limitation}</s>
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {!isLoggedIn && (
          <Button
            className='Try-Free-Button'
            onClick={() => window.open('/login')}
            variant="contained"
            color="primary"
            sx={{ mb: 2, mx: 'auto', marginTop: '0px', display: 'block', borderRadius: '24px', paddingLeft: '60px', paddingRight: '60px' }}
          >
            Try for Free
          </Button>
        )}
      </div>
      <WallOfLove />
    </ThemeProvider>
  );
}

function App() {
  return <Pricing />;
}

export default App;
