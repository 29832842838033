import React, { useEffect, useState } from 'react';
import Airtable from 'airtable';

const AIRTABLE_BASE_ID = 'appRgfGSKMliHxHSS';
const AIRTABLE_API_KEY = 'patoZsqAYhzghx1mM.fe360b7f678a595631f2ff3c15cd06c546b10351bde3efefd415508119c1c328';
const AIRTABLE_TABLE_NAME = 'CreditsUsageStretch';

const base = new Airtable({ apiKey: AIRTABLE_API_KEY }).base(AIRTABLE_BASE_ID);

const MindmapSumComponent = ({ onSumUpdate }) => {
    const [sumOfMindmaps, setSumOfMindmaps] = useState(0);
  
    const fetchRecords = async () => {
      const records = await base(AIRTABLE_TABLE_NAME)
        .select({ sort: [{ field: 'DateTime', direction: 'asc' }] })
        .all();
  
      return records.map((record) => ({
        id: record.id,
        DateTime: record.fields.DateTime,
        MindMapsGenerated: record.fields.MindMapsGenerated,
        WindowEndTime: record.fields.WindowEndTime
      }));
    };
  
    const checkAndDeleteOldestRecord = async (records) => {
      if (records.length > 1) {
        const latestRecord = records[records.length - 1];
        const oldestRecord = records[0];
        const difference = new Date(latestRecord.WindowEndTime) - new Date(oldestRecord.DateTime);
  
        if (difference > 24 * 60 * 60 * 1000) {
          await base(AIRTABLE_TABLE_NAME).destroy(oldestRecord.id);
        }
      }
    };
  
    const updateSumOfMindmaps = (records) => {
      const total = records.reduce((acc, curr) => acc + curr.MindMapsGenerated, 0);
      setSumOfMindmaps(total);
      if (onSumUpdate) {
        onSumUpdate(total); // Call the callback with the new total
      }
    };
  
    useEffect(() => {
      const interval = setInterval(async () => {
        const records = await fetchRecords();
        await checkAndDeleteOldestRecord(records);
        updateSumOfMindmaps(records);
      }, 15 * 60 * 1000);

      fetchRecords().then(records => {
        updateSumOfMindmaps(records);
        checkAndDeleteOldestRecord(records);
      });
  
      return () => clearInterval(interval);
    }, []);
  
    return <span>{sumOfMindmaps}</span>;
  };
  
  export default MindmapSumComponent;
  