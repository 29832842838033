const fetchMetadata = async (fileKey) => {
    const url = `https://lqnf76n611.execute-api.us-east-1.amazonaws.com/test`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Add other headers like Authorization if your API requires it
            },
            body: JSON.stringify({ fileKey: fileKey }) // Sending fileKey in the body as JSON
        });

        if (!response.ok) {
            // Handle non-200 responses differently
            return null;
        }

        const data = await response.json();
        return data; // Assuming the API returns JSON with metadata
    } catch (error) {
        // Handle errors differently or throw them
        return null;
    }
};

export default fetchMetadata;