import ProjectMemberDisplay from "../ProjectMemberDisplay";
import "./style.css";

function DisplayProjectMembers({ projectMemberLimitOptions }) {
  return (
    <div className="project-member-limit-container">
      {projectMemberLimitOptions.map((data, index) => {
        return <ProjectMemberDisplay {...data} key={index} />;
      })}
    </div>
  );
}

export default DisplayProjectMembers;
