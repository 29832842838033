import AWS from 'aws-sdk';
import { fetchAuthSession } from '@aws-amplify/auth';

const getUserData = async () => {
    try {
        // Fetch the current session
        const session = await fetchAuthSession();

        if (session && session.credentials) {
            AWS.config.update({
                region: 'us-east-1',
                credentials: {
                    accessKeyId: session.credentials.accessKeyId,
                    secretAccessKey: session.credentials.secretAccessKey,
                    sessionToken: session.credentials.sessionToken
                }
            });

            const dynamoDb = new AWS.DynamoDB.DocumentClient();
            const tableName = 'pdftomindmapUsers'; // DynamoDB table name
            const userId = session.userSub; // User ID from the token payload

            // DynamoDB get item parameters
            const params = {
                TableName: tableName,
                Key: { 'userID': userId }
            };

            // Fetch data from DynamoDB
            const data = await dynamoDb.get(params).promise();
            if (data && data.Item) {
                // Check if the MembershipPlan is 'Freemium' and conditionally update the record if needed
                if (data.Item.MembershipPlan === 'Freemium') {
                    const updateParams = {
                        TableName: tableName,
                        Key: { 'userID': userId },
                        UpdateExpression: 'set usedTrialForPro = if_not_exists(usedTrialForPro, :proTrial), usedTrialForStarter = if_not_exists(usedTrialForStarter, :starterTrial)',
                        ExpressionAttributeValues: {
                            ':proTrial': false,
                            ':starterTrial': false
                        },
                        ReturnValues: 'UPDATED_NEW'
                    };

                    // Perform the update operation
                    try {
                        const updateResult = await dynamoDb.update(updateParams).promise();
                        //console.log('Updated DynamoDB record:', updateResult);
                    } catch (updateError) {
                        //console.error('Error updating record:', updateError);
                    }
                }
                return data.Item; // Return the user data
            } else {
                //console.log("No data found for this userID.");
                return null; // Return null if no data found
            }
        } else {
            //console.log("Session credentials are missing.");
            return null; // Return null if session credentials are missing
        }
    } catch (error) {
        //console.error('Error fetching user session:', error);
        return null; // Return null on error
    }
};

export default getUserData;
