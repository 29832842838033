import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from '@aws-amplify/auth';
import NavBar from '../ui-components/LandingPage/Navbar';
import NewHero from '../ui-components/LandingPage/NewHero';
import VisualDemo from '../ui-components/LandingPage/VisualDemo';
import Footer from '../ui-components/LandingPage/Footer'
import WallOfLove from '../ui-components/LandingPage/WallOfLove';
import InfluencerVideosWall from '../ui-components/LandingPage/InfluencerVideosWall';



export default function Landing() {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is already logged in
    const checkUserLoggedIn = async () => {
      try {
        const user = await getCurrentUser();
        if (user) {
          navigate('/app'); // Redirect to /app if the user is logged in
        }
      } catch (error) {
        //console.log('Not logged in');
      }
    };

    checkUserLoggedIn();
  }, [navigate]); // Add navigate to the dependency array

  return (
    <div >
    <NavBar />
      <NewHero />
      <VisualDemo /> 
      <WallOfLove />
    <Footer />
    </div>
  );
}
