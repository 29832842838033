import React, { useState, useEffect } from 'react';
import './HamsterLoader.css';

const HamsterLoader = () => {
    const sentences = [
        "Hello 👋🏼 my name is AI",
        "Just a sec, running through the maze 🧩 of code!",
        "Gathering the digital cheese 🧀... ",
        "I mean, your data",
        "Spinning the wheel of knowledge to connect the dots!",
        "Let me put on my thinking cap 🧢... Ah, there it is!",
        "Hang tight, I'm tunneling through information!",
        "One moment, I'm scurrying ⛏️ across the server garden!",
        "Imagine me wearing a lab coat 🥼",
        "because I'm processing some serious science here!",
        "Translating your input into hamster-speak... I mean, code!",
        "Turning the cogs and wheels to craft your mind map!",
        "Like a hamster to a sunflower seed, I'm on it!",
        "Whiskers twitching, brain ticking, I'm on the case!",
        "Beep boop! I might be fluffy, but I'm also a tech-savvy rodent!",
        "Creating digital magic... with a dash of hamster charm!",
        "I'm not just a cute face; I'm an AI with a mission to assist you!",
        "On hamster paws, I deliver digital wonders. Let's roll!"
    ];

    const [currentSentence, setCurrentSentence] = useState('');
    const [index, setIndex] = useState(0); // Sentence index
    const [subIndex, setSubIndex] = useState(0); // Character index

    useEffect(() => {
        if (index >= sentences.length) {
            // Restart from the first sentence
            setIndex(0);
            setSubIndex(0);
            return;
        }
    
        // When a sentence is fully displayed, move to the next one after some delay
        if (subIndex === sentences[index].length + 1) {
            const timeout = setTimeout(() => {
                setIndex((prev) => prev + 1);
                setSubIndex(0);
            }, 3000); // Pause for 2 seconds at the end of a sentence
            return () => clearTimeout(timeout);
        }
    
        const timeout = setTimeout(() => {
            setCurrentSentence(sentences[index].substring(0, subIndex));
            setSubIndex((prev) => prev + 1);
        }, 120); // Typing speed
    
        return () => clearTimeout(timeout);
    }, [index, subIndex, sentences]);
    

    return (
        <>
        
        <div aria-label="Orange and tan hamster running in a metal wheel" role="img" className="wheel-and-hamster">
            
            <div className="wheel"></div>
            <div className="hamster">
                <div className="hamster__body">
                    <div className="hamster__head">
                        <div className="hamster__ear"></div>
                        <div className="hamster__eye"></div>
                        <div className="hamster__nose"></div>
                        <div className="speech-bubble" style={{width:'150px'}}><span className="typing">{currentSentence}</span></div>
                    </div>
                    <div className="hamster__limb hamster__limb--fr"></div>
                    <div className="hamster__limb hamster__limb--fl"></div>
                    <div className="hamster__limb hamster__limb--br"></div>
                    <div className="hamster__limb hamster__limb--bl"></div>
                    <div className="hamster__tail"></div>
                </div>
            </div>
            <div className="spoke"></div>
        </div>
        </>
    );
}

export default HamsterLoader;
