import AWS from 'aws-sdk';
import { fetchAuthSession } from '@aws-amplify/auth';

const resetUserUsage = async () => {
    try {
        const session = await fetchAuthSession();

        if (session && session.credentials) {
            AWS.config.update({
                region: 'us-east-1',
                credentials: {
                    accessKeyId: session.credentials.accessKeyId,
                    secretAccessKey: session.credentials.secretAccessKey,
                    sessionToken: session.credentials.sessionToken
                }
            });

            const dynamoDb = new AWS.DynamoDB.DocumentClient();
            const tableName = 'pdftomindmapUsers';
            const userId = session.userSub;

            const getParams = {
                TableName: tableName,
                Key: { 'userID': userId }
            };

            try {
                const data = await dynamoDb.get(getParams).promise();
                if (data && data.Item) {
                    const userUsage = data.Item.userUsage;
                    const resetDate = new Date(userUsage.ResetDate);
                    //console.log(resetDate);
                    const today = new Date();

                    if (today >= resetDate) {
                        // Calculate the new reset date, one month from today
                        const newResetDate = new Date();
                        newResetDate.setMonth(newResetDate.getMonth() + 1);
                        newResetDate.setHours(0, 0, 0, 0); // Reset time to 00:00:00

                        const updateParams = {
                            TableName: tableName,
                            Key: { 'userID': userId },
                            UpdateExpression: 'set userUsage.CurrentMonthUsage = :newUsage, userUsage.ResetDate = :newResetDate',
                            ExpressionAttributeValues: {
                                ':newUsage': 0,
                                ':newResetDate': newResetDate.toISOString().split('T')[0] // Store only the date part
                            },
                            ReturnValues: 'UPDATED_NEW'
                        };

                        try {
                            const updateData = await dynamoDb.update(updateParams).promise();
                            //console.log("User CurrentMonthUsage reset and ResetDate updated:", updateData);
                        } catch (updateErr) {
                            //console.error("Error resetting user CurrentMonthUsage and updating ResetDate:", updateErr);
                        }
                    } else {
                        //console.log("Current date is before the reset date. No reset required.");
                    }
                } else {
                    //console.log("No data found for this userID.");
                }
            } catch (getErr) {
                //console.error("Error fetching data from DynamoDB:", getErr);
            }
        } else {
            //console.log("Session credentials are missing.");
        }
    } catch (error) {
        //console.error('Error in resetting user CurrentMonthUsage and updating ResetDate:', error);
    }
};

export default resetUserUsage;
