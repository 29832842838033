import React, { useState } from 'react';
import { Modal, Box, Stepper, Step, StepLabel, Button, TextField } from '@mui/material';
import './ReMindMap.css';

const steps = ['Main Topic of the mind map', 'Specify Exclusions'];

const ReMindMap = ({ fileKey, metadata, onClick }) => {
    const [open, setOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [mainTopic, setMainTopic] = useState('');
    const [exclusions, setExclusions] = useState('');

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setMainTopic('');
        setExclusions('');
    };

    const handleOpen = () => {
        setOpen(true)

    };
    const handleClose = () => setOpen(false);

    const handleSubmit = async () => {
        const newMetadata = {
            'question_maintopic': mainTopic,
            'question_exclusions': exclusions,
            'redo': "true",
            'redoloading': 'true'
        };

        try {
            const response = await fetch('https://pswb6uf787.execute-api.us-east-1.amazonaws.com/dev', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ file_key: fileKey, new_metadata: newMetadata })
            });

            if (response.ok) {

                handleClose(); // Close modal after submission
            } else {
                alert('Failed to update metadata.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error updating metadata.');
        }
        onClick();
    };

    const stepContent = (step) => {
        switch (step) {
            case 0:
                return <TextField label="How supply effects demand in economics" value={mainTopic} onChange={(e) => setMainTopic(e.target.value)} fullWidth required />;
            case 1:
                return <TextField label="I do not want to see references" value={exclusions} onChange={(e) => setExclusions(e.target.value)} fullWidth required />;
            default:
                throw new Error('Unknown step');
        }
    };

    return (
        <div className="center-container">
            <div className="remindmap-container">
                <span>Do not like the mind map?</span>
                <button className="remap-button" onClick={handleOpen}><strong>Try again ↪️</strong></button>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '10px'
                }}>
                    <h2 style={{ textAlign: 'center', marginBottom :'10px' }}>Update Mind Map</h2>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label) => (
                            <Step style={{ marginBottom: '10px'}} key={label}>
                                <StepLabel >{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    <div>
                        {stepContent(activeStep)}
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1}}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto'}} />
                            {activeStep === steps.length - 1 ? (
                                <Button onClick={handleSubmit}>Submit</Button>
                            ) : (
                                <Button onClick={handleNext}>Next</Button>
                            )}
                        </Box>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default ReMindMap;
