const FormatFileName = (name) => {
    // Ensure the input is a string
    if (typeof name !== 'string') {
        throw new TypeError('Expected a string as input');
    }

    if (name.endsWith('.pdf')) {
        // For .pdf files, remove the UUID and the hyphen, then return the remaining part of the name
        let cleanName = name.substring(37); // Remove the UUID and hyphen
        cleanName = cleanName.replace(/\.pdf$/, ''); // Remove the .pdf extension
        return cleanName;
    } else {
        // For .txt files, first remove the directory prefix
        let cleanName = name.replace(/^(AI_Magic\/|TextInput\/)/, '');

        // Then remove the UUID and the hyphen
        cleanName = cleanName.substring(37);

        // Finally, remove the specific suffix and replace underscores with spaces
        cleanName = cleanName.replace(/(_ai_magic|_text_input)\.txt$/, '').replace(/_/g, ' ');

        return cleanName;
    }
};

export default FormatFileName;