import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import toast, { Toaster } from 'react-hot-toast';
import { generateShareableLink } from './shareMindMap';
import './MindmapRatings.css';
import { FaRegThumbsDown, FaRegThumbsUp } from 'react-icons/fa';

const MindmapRatings = ({ userId, fileKey, identityId }) => {
  const [hasRated, setHasRated] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  // DynamoDB client setup
  const docClient = new AWS.DynamoDB.DocumentClient();

  const fetchUserEmail = async () => {
    try {
      const response = await docClient.get({
        TableName: 'pdftomindmapUsers',
        Key: { userID: userId }
      }).promise();
      if (response.Item) {
        setUserEmail(response.Item.Email);
      } else {
        console.error("User not found");
      }
    } catch (error) {
      console.error("Error fetching user email:", error);
    }
  };

  const checkRating = async () => {
    try {
      const data = await docClient.get({
        TableName: 'MindmapRatings',
        Key: { UserID: userId }
      }).promise();
      const fileRated = data.Item?.pdf_ratings?.some(r => r.pdf_location === `private/${userId}/${fileKey}`);
      setHasRated(fileRated);
    } catch (error) {
      console.error("Error accessing DynamoDB", error);
    }
  };

  const triggerThumbsUpToast = () => {
    toast.success('Good to hear!');
  };

  const triggerThumbsDownToast = () => {
    toast.error('Sad to hear that!');
  };

  const submitRating = async (ratingType) => {

    if (ratingType === 'thumbs_up') {
      triggerThumbsUpToast();
    } else {
      triggerThumbsDownToast();
    }


    const pdf_location = `private/${userId}/${fileKey}`;
    const shareableUrl = await generateShareableLink(identityId, fileKey); // Generate the shareable URL

    if (!shareableUrl) {
      console.error('Failed to generate shareable URL');
      return; // Optionally handle this case in the UI
    }

    try {
      await docClient.update({
        TableName: 'MindmapRatings',
        Key: { UserID: userId },
        UpdateExpression: `SET pdf_ratings = list_append(if_not_exists(pdf_ratings, :empty_list), :rating_info),
                            Email = if_not_exists(Email, :email)`,
        ExpressionAttributeValues: {
          ':rating_info': [{
            pdf_location: pdf_location,
            rating: ratingType,
            shareable_url: shareableUrl
          }],
          ':empty_list': [],
          ':email': userEmail
        },
        ReturnValues: "UPDATED_NEW"
      }).promise();

    } catch (error) {
      console.error("Failed to update rating", error);
      toast.error('Failed to submit your rating. Please try again.');
    }
    // Delay updating the state by 3 seconds
        setTimeout(() => {
          setHasRated(true); // Update local state to reflect the new rating
        }, 3000);
  };


  useEffect(() => {
    fetchUserEmail();
    checkRating();
  }, [userId, fileKey]);  // Ensure dependencies are correctly listed

  if (hasRated) {
    return null;
  }

  return (
    <>
      <div style={{
        display: "flex",
        marginRight: "40px",
        gap: "10px",
        flexDirection: "row",
        borderRadius: "5px",
        border: "1px solid #e5e5e5",
        padding: "5px",
      }} className='rating-block'>

        <div className='rating-block like-block' style={{ position: 'relative' }}>
          <button
            style={{ border: "none" }}
            className={`like-button ${hasRated && 'liked'}`}
            onClick={() => submitRating('thumbs_up')}
            disabled={hasRated}
          >
            <img src="/thumbsup.png" alt="Thumbs Down" width="34" height="34" />
          </button>
          <span className="tooltip-text-rating">Found it helpful!</span>
        </div>
        <div className='rating-block dislike-block' style={{ position: 'relative' }}>
          <button
            style={{ border: "none" }}
            className={`dislike-button ${hasRated && 'disliked'}`}
            onClick={() => submitRating('thumbs_down')}
            disabled={hasRated}
          >
             <img src="/thumbsdown.png" alt="Thumbs Down" width="34" height="34" />
          </button>
          <span className="tooltip-text-rating">Not what I wanted</span>
        </div>

      </div>
      <Toaster position="bottom-center" />
    </>
  );
};

export default MindmapRatings;
