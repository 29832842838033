import { useEffect, useState, useRef } from 'react';
import debounce from 'lodash.debounce'; // Import debounce function from lodash

const useMindMapNodeInteractions = (isMindMapReady, mindMapCode) => {
  const [activeSummary, setActiveSummary] = useState(null);
  const clickHandlers = useRef(new Map());
  const mutationObserver = useRef(null);

  const closeAllNodes = () => {
    document.body.click();
    openCentralNode();
  };

  const openCentralNode = () => {
    const centralNode = document.querySelector('.mindmap-node.section--1');
    if (centralNode) {
      centralNode.click();
    }
  }

  useEffect(() => {

    if (!isMindMapReady) return;

    const lines = mindMapCode.split('\n');
    const nodeSummaries = [];
    let currentSummaryLines = [];
    lines.forEach(line => {
      const trimmedLine = line.trim();
      if (/^%%\s*/.test(trimmedLine)) {
        const summaryText = trimmedLine.replace(/^%%\s*/, '');
        currentSummaryLines.push(summaryText);
      } else if (trimmedLine === '') {
        // If the line is empty, it's a continuation of the summary
        currentSummaryLines.push(trimmedLine);
      } else {
        const nodeTextMatch = trimmedLine.match(/\("(.+?)"\)|\{(.+?)\}|\[(.+?)\]/);
        if (nodeTextMatch) {
          const nodeText = (nodeTextMatch[1] || nodeTextMatch[2] || nodeTextMatch[3]).trim();
          const summary = currentSummaryLines.join(' '); // Concatenate multi-line summaries
          nodeSummaries.push({ nodeText, summary });
          currentSummaryLines = []; // Reset for the next node
        }
      }
    });



    // Function to remove and reattach event listeners to prevent memory leaks
    const reattachEventListeners = debounce(() => {
      const nodes = document.querySelectorAll('.mindmap-node');
      if (!nodes.length) return; // Check for node existence

      nodes.forEach(node => {
        const clickHandler = () => {
  

          const rect = node.getBoundingClientRect();
          const nodeText = node.textContent.trim().replace(/\s/g, ''); // Remove white spaces
          const associatedData = nodeSummaries.find(ns => ns.nodeText.replace(/\s/g, '') === nodeText);

          if (associatedData) {
            setActiveSummary({ 
              ...associatedData, 
              x: rect.left + window.scrollX, 
              y: rect.top + window.scrollY 
            });
          } else {
            // To clear the active summary, you might also consider using a unique value
            setActiveSummary(prevState => ({
                ...prevState,
                summary: '',
                uniqueId: new Date().getTime() // Ensure React detects this as a change
            }));
        }
    };
        // Store clickHandler in Map
        clickHandlers.current.set(node, clickHandler);

        // Remove any existing click listeners to avoid duplicates
        node.removeEventListener('click', clickHandler);
        node.addEventListener('click', clickHandler);
      });

      // Use MutationObserver to watch for changes in the DOM
      if (mutationObserver.current) {
        mutationObserver.current.disconnect();
      }
      mutationObserver.current = new MutationObserver(reattachEventListeners);
      mutationObserver.current.observe(document.body, { childList: true, subtree: true });

    }, 300); // Debounce time in milliseconds

    reattachEventListeners();

    // Cleanup function to remove event listeners
   // Cleanup function to remove event listeners and disconnect MutationObserver
   return () => {
    document.querySelectorAll('.mindmap-node').forEach(node => {
      // Get clickHandler from Map and remove event listener
      const clickHandler = clickHandlers.current.get(node);
      if (clickHandler) {
        node.removeEventListener('click', clickHandler);
      }
    });

    if (mutationObserver.current) {
      mutationObserver.current.disconnect();
    }
  };

}, [isMindMapReady, mindMapCode]); // Re-run effect when these dependencies change

return { activeSummary, closeAllNodes };
};

export default useMindMapNodeInteractions;