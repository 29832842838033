import React, { useState, useEffect } from 'react';
import './renameModal.css'

const RenameMindmapModal = ({ isOpen, onClose, currentFileKey, onRename, fileName }) => {
  const [newMindmapName, setNewMindmapName] = useState(fileName);
  const [isInputInvalid, setIsInputInvalid] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const trimmedName = newMindmapName.trim();
    if (!trimmedName) {
      setIsInputInvalid(true); // Highlight input for empty input
      setTimeout(() => setIsInputInvalid(false), 2000); // Remove highlight after a delay
      return; // Do not proceed further
    } else if (trimmedName == fileName) {
      onClose(); // Close the modal if no changes were made
      return; // Do not proceed further
    }
    onRename(currentFileKey, trimmedName);
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="rename-modal-overlay" onClick={handleOverlayClick}>
      <div className="rename-modal">
        <div className="rename-modal-header">
          <h2 className="rename-modal-title">Rename Mindmap</h2>
          <button className="rename-modal-close-button" onClick={onClose}>×</button>
        </div>
        <form onSubmit={handleSubmit} className="rename-modal-form">
          <label className="rename-modal-label">
            Enter a new Mindmap name:
            <input
                className={`rename-modal-input ${isInputInvalid ? 'input-invalid' : ''}`}
                type="text"
                value={newMindmapName}
                onChange={(e) => setNewMindmapName(e.target.value)}
                style={{ maxHeight: isInputInvalid ? 'none' : '40px', minHeight: isInputInvalid ? 'none' : '40px', paddingTop: '0px', paddingBottom: '0px' }}
                autoFocus
                onFocus={(e) => e.target.select()} // Automatically select text when the input is focused
  
            />
          </label>
          <div className="rename-modal-actions">
            <button type="button" onClick={onClose} className="rename-modal-button rename-modal-button-cancel">Cancel</button>
            <button type="submit" className="rename-modal-button rename-modal-button-ok">Ok</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RenameMindmapModal;
