import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { FaCaretDown, FaExpand } from 'react-icons/fa';
import NavBar from './LandingPage/Navbar';
import Footer from './LandingPage/Footer';
import './FAQ.css';
import WallOfLove from './LandingPage/WallOfLove';
import { useState } from 'react';
import { styled } from '@mui/system';
import CTA from './CTA';
import HamsterLoader from './HamsterLoader';


const faqs = [
  {
    question: 'How do I convert a PDF into a mind map?',
    answer: 'Upload your PDF. Tell our AI what part of the PDF you want to turn into a Mind Map. Wait a few seconds, and AI will turn the PDF into a mind map!',
  },
  {
    question: 'How do I generate the best Mind Maps?',
    answer: 'When you upload your PDF, just mention the topic for your Mind Map. This will create mind maps on that specific topic from the PDF.',
  },
  {
    question: 'Can I edit or customize my mind map after creation?',
    answer: 'Absolutely! You can add new nodes or edit or delete existing mind map nodes using the Edit feature. Note: The edit feature is available on both Starter and Pro plans.',
  },
  {
    question: 'What should I do if I encounter an error during conversion?',
    answer: 'Getting an error during conversion is rare. If you get an error, make sure the PDF is machine readable and just try it again. The credits are not used if there is an error. If the error persists, please reach out to our support team, and they will help.',
  },
  {
    question: 'How can I share my mind map with others?',
    answer: 'Once you generate your mind map, you can download the mind map as an image or share it with anyone using a shareable link.',
  },
  {
    question: 'Are there limits to the size of the PDF I can upload?',
    answer: 'There’s a 5MB file size limit on the free plan and no size limit on the paid plan.',
  },
];

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    '&.Mui-expanded': {
      '&::before': {
        opacity: 1,
      },
    },
    '&::before': {
      height: '1px',
      content: '""',
      opacity: 1,
      backgroundColor: theme.palette.divider,
    },
  }));

function FAQ() {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div >
                <NavBar />
                <div className='faq-container'>
                    <h1 className='faq-heading'>Frequently Asked Questions</h1>
                    <div className='accordian-faq' style={{ display: 'flex', flexDirection:'column', justifyContent: 'center' }}> 
                    {faqs.map((faq, index) => (
                            <StyledAccordion 
                                expanded={expanded === `panel${index}`} 
                                onChange={handleChange(`panel${index}`)}
                                style={{boxShadow:'none'}}  
                                slotProps={{ transition: { unmountOnExit: true } }} 
                                key={index}>
                            <AccordionSummary
                                    expandIcon={<FaCaretDown/>}
                                    aria-controls={`panel${index}a-content`}
                                    id={`panel${index}a-header`}
                            >
                                    <Typography style={{fontWeight:'600'}}>{faq.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                    <Typography style={{fontWeight:'400', fontSize:'16px'}}>
                                    {faq.answer}
                                    </Typography>
                            </AccordionDetails>
                            </StyledAccordion>
                    ))}
                    </div>
            </div>
            <CTA />
            <Footer />
        </div>
    );
}

export default FAQ;
