import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import './FontAwesomeIconDropdown.css'; 


const iconsList = [
  { value: 'fa fa-500px', label: 'fa fa-500px' },
  { value: 'fa fa-address-book', label: 'fa fa-address-book' },
  { value: 'fa fa-address-book-o', label: 'fa fa-address-book-o' },
  { value: 'fa fa-address-card', label: 'fa fa-address-card' },
  { value: 'fa fa-address-card-o', label: 'fa fa-address-card-o' },
  { value: 'fa fa-adjust', label: 'fa fa-adjust' },
  { value: 'fa fa-adn', label: 'fa fa-adn' },
  { value: 'fa fa-align-center', label: 'fa fa-align-center' },
  { value: 'fa fa-align-justify', label: 'fa fa-align-justify' },
  { value: 'fa fa-align-left', label: 'fa fa-align-left' },
  { value: 'fa fa-align-right', label: 'fa fa-align-right' },
  { value: 'fa fa-amazon', label: 'fa fa-amazon' },
  { value: 'fa fa-ambulance', label: 'fa fa-ambulance' },
  { value: 'fa fa-american-sign-language-interpreting', label: 'fa fa-american-sign-language-interpreting' },
  { value: 'fa fa-anchor', label: 'fa fa-anchor' },
  { value: 'fa fa-android', label: 'fa fa-android' },
  { value: 'fa fa-angellist', label: 'fa fa-angellist' },
  { value: 'fa fa-angle-double-down', label: 'fa fa-angle-double-down' },
  { value: 'fa fa-angle-double-left', label: 'fa fa-angle-double-left' },
  { value: 'fa fa-angle-double-right', label: 'fa fa-angle-double-right' },
  { value: 'fa fa-angle-double-up', label: 'fa fa-angle-double-up' },
  { value: 'fa fa-angle-down', label: 'fa fa-angle-down' },
  { value: 'fa fa-angle-left', label: 'fa fa-angle-left' },
  { value: 'fa fa-angle-right', label: 'fa fa-angle-right' },
  { value: 'fa fa-angle-up', label: 'fa fa-angle-up' },
  { value: 'fa fa-apple', label: 'fa fa-apple' },
  { value: 'fa fa-archive', label: 'fa fa-archive' },
  { value: 'fa fa-area-chart', label: 'fa fa-area-chart' },
  { value: 'fa fa-arrow-circle-down', label: 'fa fa-arrow-circle-down' },
  { value: 'fa fa-arrow-circle-left', label: 'fa fa-arrow-circle-left' },
  { value: 'fa fa-arrow-circle-o-down', label: 'fa fa-arrow-circle-o-down' },
  { value: 'fa fa-arrow-circle-o-left', label: 'fa fa-arrow-circle-o-left' },
  { value: 'fa fa-arrow-circle-o-right', label: 'fa fa-arrow-circle-o-right' },
  { value: 'fa fa-arrow-circle-o-up', label: 'fa fa-arrow-circle-o-up' },
  { value: 'fa fa-arrow-circle-right', label: 'fa fa-arrow-circle-right' },
  { value: 'fa fa-arrow-circle-up', label: 'fa fa-arrow-circle-up' },
  { value: 'fa fa-arrow-down', label: 'fa fa-arrow-down' },
  { value: 'fa fa-arrow-left', label: 'fa fa-arrow-left' },
  { value: 'fa fa-arrow-right', label: 'fa fa-arrow-right' },
  { value: 'fa fa-arrow-up', label: 'fa fa-arrow-up' },
  { value: 'fa fa-arrows', label: 'fa fa-arrows' },
  { value: 'fa fa-arrows-alt', label: 'fa fa-arrows-alt' },
  { value: 'fa fa-arrows-h', label: 'fa fa-arrows-h' },
  { value: 'fa fa-arrows-v', label: 'fa fa-arrows-v' },
  { value: 'fa fa-asl-interpreting', label: 'fa fa-asl-interpreting' },
  { value: 'fa fa-assistive-listening-systems', label: 'fa fa-assistive-listening-systems' },
  { value: 'fa fa-asterisk', label: 'fa fa-asterisk' },
  { value: 'fa fa-at', label: 'fa fa-at' },
  { value: 'fa fa-audio-description', label: 'fa fa-audio-description' },
  { value: 'fa fa-automobile', label: 'fa fa-automobile' },
  { value: 'fa fa-backward', label: 'fa fa-backward' },
  { value: 'fa fa-balance-scale', label: 'fa fa-balance-scale' },
  { value: 'fa fa-ban', label: 'fa fa-ban' },
  { value: 'fa fa-bandcamp', label: 'fa fa-bandcamp' },
  { value: 'fa fa-bank', label: 'fa fa-bank' },
  { value: 'fa fa-bar-chart', label: 'fa fa-bar-chart' },
  { value: 'fa fa-bar-chart-o', label: 'fa fa-bar-chart-o' },
  { value: 'fa fa-barcode', label: 'fa fa-barcode' },
  { value: 'fa fa-bars', label: 'fa fa-bars' },
  { value: 'fa fa-bath', label: 'fa fa-bath' },
  { value: 'fa fa-bathtub', label: 'fa fa-bathtub' },
  { value: 'fa fa-battery', label: 'fa fa-battery' },
  { value: 'fa fa-battery-0', label: 'fa fa-battery-0' },
  { value: 'fa fa-battery-1', label: 'fa fa-battery-1' },
  { value: 'fa fa-battery-2', label: 'fa fa-battery-2' },
  { value: 'fa fa-battery-3', label: 'fa fa-battery-3' },
  { value: 'fa fa-battery-4', label: 'fa fa-battery-4' },
  { value: 'fa fa-battery-empty', label: 'fa fa-battery-empty' },
  { value: 'fa fa-battery-full', label: 'fa fa-battery-full' },
  { value: 'fa fa-battery-half', label: 'fa fa-battery-half' },
  { value: 'fa fa-battery-quarter', label: 'fa fa-battery-quarter' },
  { value: 'fa fa-battery-three-quarters', label: 'fa fa-battery-three-quarters' },
  { value: 'fa fa-bed', label: 'fa fa-bed' },
  { value: 'fa fa-beer', label: 'fa fa-beer' },
  { value: 'fa fa-behance', label: 'fa fa-behance' },
  { value: 'fa fa-behance-square', label: 'fa fa-behance-square' },
  { value: 'fa fa-bell', label: 'fa fa-bell' },
  { value: 'fa fa-bell-o', label: 'fa fa-bell-o' },
  { value: 'fa fa-bell-slash', label: 'fa fa-bell-slash' },
  { value: 'fa fa-bell-slash-o', label: 'fa fa-bell-slash-o' },
  { value: 'fa fa-bicycle', label: 'fa fa-bicycle' },
  { value: 'fa fa-binoculars', label: 'fa fa-binoculars' },
  { value: 'fa fa-birthday-cake', label: 'fa fa-birthday-cake' },
  { value: 'fa fa-bitbucket', label: 'fa fa-bitbucket' },
  { value: 'fa fa-bitbucket-square', label: 'fa fa-bitbucket-square' },
  { value: 'fa fa-bitcoin', label: 'fa fa-bitcoin' },
  { value: 'fa fa-black-tie', label: 'fa fa-black-tie' },
  { value: 'fa fa-blind', label: 'fa fa-blind' },
  { value: 'fa fa-bluetooth', label: 'fa fa-bluetooth' },
  { value: 'fa fa-bluetooth-b', label: 'fa fa-bluetooth-b' },
  { value: 'fa fa-bold', label: 'fa fa-bold' },
  { value: 'fa fa-bolt', label: 'fa fa-bolt' },
  { value: 'fa fa-bomb', label: 'fa fa-bomb' },
  { value: 'fa fa-book', label: 'fa fa-book' },
  { value: 'fa fa-bookmark', label: 'fa fa-bookmark' },
  { value: 'fa fa-bookmark-o', label: 'fa fa-bookmark-o' },
  { value: 'fa fa-braille', label: 'fa fa-braille' },
  { value: 'fa fa-briefcase', label: 'fa fa-briefcase' },
  { value: 'fa fa-btc', label: 'fa fa-btc' },
  { value: 'fa fa-bug', label: 'fa fa-bug' },
  { value: 'fa fa-building', label: 'fa fa-building' },
  { value: 'fa fa-building-o', label: 'fa fa-building-o' },
  { value: 'fa fa-bullhorn', label: 'fa fa-bullhorn' },
  { value: 'fa fa-bullseye', label: 'fa fa-bullseye' },
  { value: 'fa fa-bus', label: 'fa fa-bus' },
  { value: 'fa fa-buysellads', label: 'fa fa-buysellads' },
  { value: 'fa fa-cab', label: 'fa fa-cab' },
  { value: 'fa fa-calculator', label: 'fa fa-calculator' },
  { value: 'fa fa-calendar', label: 'fa fa-calendar' },
  { value: 'fa fa-calendar-check-o', label: 'fa fa-calendar-check-o' },
  { value: 'fa fa-calendar-minus-o', label: 'fa fa-calendar-minus-o' },
  { value: 'fa fa-calendar-o', label: 'fa fa-calendar-o' },
  { value: 'fa fa-calendar-plus-o', label: 'fa fa-calendar-plus-o' },
  { value: 'fa fa-calendar-times-o', label: 'fa fa-calendar-times-o' },
  { value: 'fa fa-camera', label: 'fa fa-camera' },
  { value: 'fa fa-camera-retro', label: 'fa fa-camera-retro' },
  { value: 'fa fa-car', label: 'fa fa-car' },
  { value: 'fa fa-caret-down', label: 'fa fa-caret-down' },
  { value: 'fa fa-caret-left', label: 'fa fa-caret-left' },
  { value: 'fa fa-caret-right', label: 'fa fa-caret-right' },
  { value: 'fa fa-caret-square-o-down', label: 'fa fa-caret-square-o-down' },
  { value: 'fa fa-caret-square-o-left', label: 'fa fa-caret-square-o-left' },
  { value: 'fa fa-caret-square-o-right', label: 'fa fa-caret-square-o-right' },
  { value: 'fa fa-caret-square-o-up', label: 'fa fa-caret-square-o-up' },
  { value: 'fa fa-caret-up', label: 'fa fa-caret-up' },
  { value: 'fa fa-cart-arrow-down', label: 'fa fa-cart-arrow-down' },
  { value: 'fa fa-cart-plus', label: 'fa fa-cart-plus' },
  { value: 'fa fa-cc', label: 'fa fa-cc' },
  { value: 'fa fa-cc-amex', label: 'fa fa-cc-amex' },
  { value: 'fa fa-cc-diners-club', label: 'fa fa-cc-diners-club' },
  { value: 'fa fa-cc-discover', label: 'fa fa-cc-discover' },
  { value: 'fa fa-cc-jcb', label: 'fa fa-cc-jcb' },
  { value: 'fa fa-cc-mastercard', label: 'fa fa-cc-mastercard' },
  { value: 'fa fa-cc-paypal', label: 'fa fa-cc-paypal' },
  { value: 'fa fa-cc-stripe', label: 'fa fa-cc-stripe' },
  { value: 'fa fa-cc-visa', label: 'fa fa-cc-visa' },
  { value: 'fa fa-certificate', label: 'fa fa-certificate' },
  { value: 'fa fa-chain', label: 'fa fa-chain' },
  { value: 'fa fa-chain-broken', label: 'fa fa-chain-broken' },
  { value: 'fa fa-check', label: 'fa fa-check' },
  { value: 'fa fa-check-circle', label: 'fa fa-check-circle' },
  { value: 'fa fa-check-circle-o', label: 'fa fa-check-circle-o' },
  { value: 'fa fa-check-square', label: 'fa fa-check-square' },
  { value: 'fa fa-check-square-o', label: 'fa fa-check-square-o' },
  { value: 'fa fa-chevron-circle-down', label: 'fa fa-chevron-circle-down' },
  { value: 'fa fa-chevron-circle-left', label: 'fa fa-chevron-circle-left' },
  { value: 'fa fa-chevron-circle-right', label: 'fa fa-chevron-circle-right' },
  { value: 'fa fa-chevron-circle-up', label: 'fa fa-chevron-circle-up' },
  { value: 'fa fa-chevron-down', label: 'fa fa-chevron-down' },
  { value: 'fa fa-chevron-left', label: 'fa fa-chevron-left' },
  { value: 'fa fa-chevron-right', label: 'fa fa-chevron-right' },
  { value: 'fa fa-chevron-up', label: 'fa fa-chevron-up' },
  { value: 'fa fa-child', label: 'fa fa-child' },
  { value: 'fa fa-chrome', label: 'fa fa-chrome' },
  { value: 'fa fa-circle', label: 'fa fa-circle' },
  { value: 'fa fa-circle-o', label: 'fa fa-circle-o' },
  { value: 'fa fa-circle-o-notch', label: 'fa fa-circle-o-notch' },
  { value: 'fa fa-circle-thin', label: 'fa fa-circle-thin' },
  { value: 'fa fa-clipboard', label: 'fa fa-clipboard' },
  { value: 'fa fa-clock-o', label: 'fa fa-clock-o' },
  { value: 'fa fa-clone', label: 'fa fa-clone' },
  { value: 'fa fa-close', label: 'fa fa-close' },
  { value: 'fa fa-cloud', label: 'fa fa-cloud' },
  { value: 'fa fa-cloud-download', label: 'fa fa-cloud-download' },
  { value: 'fa fa-cloud-upload', label: 'fa fa-cloud-upload' },
  { value: 'fa fa-cny', label: 'fa fa-cny' },
  { value: 'fa fa-code', label: 'fa fa-code' },
  { value: 'fa fa-code-fork', label: 'fa fa-code-fork' },
  { value: 'fa fa-codepen', label: 'fa fa-codepen' },
  { value: 'fa fa-codiepie', label: 'fa fa-codiepie' },
  { value: 'fa fa-coffee', label: 'fa fa-coffee' },
  { value: 'fa fa-cog', label: 'fa fa-cog' },
  { value: 'fa fa-cogs', label: 'fa fa-cogs' },
  { value: 'fa fa-columns', label: 'fa fa-columns' },
  { value: 'fa fa-comment', label: 'fa fa-comment' },
  { value: 'fa fa-comment-o', label: 'fa fa-comment-o' },
  { value: 'fa fa-commenting', label: 'fa fa-commenting' },
  { value: 'fa fa-commenting-o', label: 'fa fa-commenting-o' },
  { value: 'fa fa-comments', label: 'fa fa-comments' },
  { value: 'fa fa-comments-o', label: 'fa fa-comments-o' },
  { value: 'fa fa-compass', label: 'fa fa-compass' },
  { value: 'fa fa-compress', label: 'fa fa-compress' },
  { value: 'fa fa-connectdevelop', label: 'fa fa-connectdevelop' },
  { value: 'fa fa-contao', label: 'fa fa-contao' },
  { value: 'fa fa-copy', label: 'fa fa-copy' },
  { value: 'fa fa-copyright', label: 'fa fa-copyright' },
  { value: 'fa fa-creative-commons', label: 'fa fa-creative-commons' },
  { value: 'fa fa-credit-card', label: 'fa fa-credit-card' },
  { value: 'fa fa-credit-card-alt', label: 'fa fa-credit-card-alt' },
  { value: 'fa fa-crop', label: 'fa fa-crop' },
  { value: 'fa fa-crosshairs', label: 'fa fa-crosshairs' },
  { value: 'fa fa-css3', label: 'fa fa-css3' },
  { value: 'fa fa-cube', label: 'fa fa-cube' },
  { value: 'fa fa-cubes', label: 'fa fa-cubes' },
  { value: 'fa fa-cut', label: 'fa fa-cut' },
  { value: 'fa fa-cutlery', label: 'fa fa-cutlery' },
  { value: 'fa fa-dashboard', label: 'fa fa-dashboard' },
  { value: 'fa fa-dashcube', label: 'fa fa-dashcube' },
  { value: 'fa fa-database', label: 'fa fa-database' },
  { value: 'fa fa-deaf', label: 'fa fa-deaf' },
  { value: 'fa fa-deafness', label: 'fa fa-deafness' },
  { value: 'fa fa-dedent', label: 'fa fa-dedent' },
  { value: 'fa fa-delicious', label: 'fa fa-delicious' },
  { value: 'fa fa-desktop', label: 'fa fa-desktop' },
  { value: 'fa fa-deviantart', label: 'fa fa-deviantart' },
  { value: 'fa fa-diamond', label: 'fa fa-diamond' },
  { value: 'fa fa-digg', label: 'fa fa-digg' },
  { value: 'fa fa-dollar', label: 'fa fa-dollar' },
  { value: 'fa fa-dot-circle-o', label: 'fa fa-dot-circle-o' },
  { value: 'fa fa-download', label: 'fa fa-download' },
  { value: 'fa fa-dribbble', label: 'fa fa-dribbble' },
  { value: 'fa fa-drivers-license', label: 'fa fa-drivers-license' },
  { value: 'fa fa-drivers-license-o', label: 'fa fa-drivers-license-o' },
  { value: 'fa fa-dropbox', label: 'fa fa-dropbox' },
  { value: 'fa fa-drupal', label: 'fa fa-drupal' },
  { value: 'fa fa-edge', label: 'fa fa-edge' },
  { value: 'fa fa-edit', label: 'fa fa-edit' },
  { value: 'fa fa-eercast', label: 'fa fa-eercast' },
  { value: 'fa fa-eject', label: 'fa fa-eject' },
  { value: 'fa fa-ellipsis-h', label: 'fa fa-ellipsis-h' },
  { value: 'fa fa-ellipsis-v', label: 'fa fa-ellipsis-v' },
  { value: 'fa fa-empire', label: 'fa fa-empire' },
  { value: 'fa fa-envelope', label: 'fa fa-envelope' },
  { value: 'fa fa-envelope-o', label: 'fa fa-envelope-o' },
  { value: 'fa fa-envelope-open', label: 'fa fa-envelope-open' },
  { value: 'fa fa-envelope-open-o', label: 'fa fa-envelope-open-o' },
  { value: 'fa fa-envelope-square', label: 'fa fa-envelope-square' },
  { value: 'fa fa-envira', label: 'fa fa-envira' },
  { value: 'fa fa-eraser', label: 'fa fa-eraser' },
  { value: 'fa fa-etsy', label: 'fa fa-etsy' },
  { value: 'fa fa-eur', label: 'fa fa-eur' },
  { value: 'fa fa-euro', label: 'fa fa-euro' },
  { value: 'fa fa-exchange', label: 'fa fa-exchange' },
  { value: 'fa fa-exclamation', label: 'fa fa-exclamation' },
  { value: 'fa fa-exclamation-circle', label: 'fa fa-exclamation-circle' },
  { value: 'fa fa-exclamation-triangle', label: 'fa fa-exclamation-triangle' },
  { value: 'fa fa-expand', label: 'fa fa-expand' },
  { value: 'fa fa-expeditedssl', label: 'fa fa-expeditedssl' },
  { value: 'fa fa-external-link', label: 'fa fa-external-link' },
  { value: 'fa fa-external-link-square', label: 'fa fa-external-link-square' },
  { value: 'fa fa-eye', label: 'fa fa-eye' },
  { value: 'fa fa-eye-slash', label: 'fa fa-eye-slash' },
  { value: 'fa fa-eyedropper', label: 'fa fa-eyedropper' },
  { value: 'fa fa-fa', label: 'fa fa-fa' },
  { value: 'fa fa-facebook', label: 'fa fa-facebook' },
  { value: 'fa fa-facebook-f', label: 'fa fa-facebook-f' },
  { value: 'fa fa-facebook-official', label: 'fa fa-facebook-official' },
  { value: 'fa fa-facebook-square', label: 'fa fa-facebook-square' },
  { value: 'fa fa-fast-backward', label: 'fa fa-fast-backward' },
  { value: 'fa fa-fast-forward', label: 'fa fa-fast-forward' },
  { value: 'fa fa-fax', label: 'fa fa-fax' },
  { value: 'fa fa-feed', label: 'fa fa-feed' },
  { value: 'fa fa-female', label: 'fa fa-female' },
  { value: 'fa fa-fighter-jet', label: 'fa fa-fighter-jet' },
  { value: 'fa fa-file', label: 'fa fa-file' },
  { value: 'fa fa-file-archive-o', label: 'fa fa-file-archive-o' },
  { value: 'fa fa-file-audio-o', label: 'fa fa-file-audio-o' },
  { value: 'fa fa-file-code-o', label: 'fa fa-file-code-o' },
  { value: 'fa fa-file-excel-o', label: 'fa fa-file-excel-o' },
  { value: 'fa fa-file-image-o', label: 'fa fa-file-image-o' },
  { value: 'fa fa-file-movie-o', label: 'fa fa-file-movie-o' },
  { value: 'fa fa-file-o', label: 'fa fa-file-o' },
  { value: 'fa fa-file-pdf-o', label: 'fa fa-file-pdf-o' },
  { value: 'fa fa-file-photo-o', label: 'fa fa-file-photo-o' },
  { value: 'fa fa-file-picture-o', label: 'fa fa-file-picture-o' },
  { value: 'fa fa-file-powerpoint-o', label: 'fa fa-file-powerpoint-o' },
  { value: 'fa fa-file-sound-o', label: 'fa fa-file-sound-o' },
  { value: 'fa fa-file-text', label: 'fa fa-file-text' },
  { value: 'fa fa-file-text-o', label: 'fa fa-file-text-o' },
  { value: 'fa fa-file-video-o', label: 'fa fa-file-video-o' },
  { value: 'fa fa-file-word-o', label: 'fa fa-file-word-o' },
  { value: 'fa fa-file-zip-o', label: 'fa fa-file-zip-o' },
  { value: 'fa fa-files-o', label: 'fa fa-files-o' },
  { value: 'fa fa-film', label: 'fa fa-film' },
  { value: 'fa fa-filter', label: 'fa fa-filter' },
  { value: 'fa fa-fire', label: 'fa fa-fire' },
  { value: 'fa fa-fire-extinguisher', label: 'fa fa-fire-extinguisher' },
  { value: 'fa fa-firefox', label: 'fa fa-firefox' },
  { value: 'fa fa-first-order', label: 'fa fa-first-order' },
  { value: 'fa fa-flag', label: 'fa fa-flag' },
  { value: 'fa fa-flag-checkered', label: 'fa fa-flag-checkered' },
  { value: 'fa fa-flag-o', label: 'fa fa-flag-o' },
  { value: 'fa fa-flash', label: 'fa fa-flash' },
  { value: 'fa fa-flask', label: 'fa fa-flask' },
  { value: 'fa fa-flickr', label: 'fa fa-flickr' },
  { value: 'fa fa-floppy-o', label: 'fa fa-floppy-o' },
  { value: 'fa fa-folder', label: 'fa fa-folder' },
  { value: 'fa fa-folder-o', label: 'fa fa-folder-o' },
  { value: 'fa fa-folder-open', label: 'fa fa-folder-open' },
  { value: 'fa fa-folder-open-o', label: 'fa fa-folder-open-o' },
  { value: 'fa fa-font', label: 'fa fa-font' },
  { value: 'fa fa-font-awesome', label: 'fa fa-font-awesome' },
  { value: 'fa fa-fonticons', label: 'fa fa-fonticons' },
  { value: 'fa fa-fort-awesome', label: 'fa fa-fort-awesome' },
  { value: 'fa fa-forumbee', label: 'fa fa-forumbee' },
  { value: 'fa fa-forward', label: 'fa fa-forward' },
  { value: 'fa fa-foursquare', label: 'fa fa-foursquare' },
  { value: 'fa fa-free-code-camp', label: 'fa fa-free-code-camp' },
  { value: 'fa fa-frown-o', label: 'fa fa-frown-o' },
  { value: 'fa fa-futbol-o', label: 'fa fa-futbol-o' },
  { value: 'fa fa-gamepad', label: 'fa fa-gamepad' },
  { value: 'fa fa-gavel', label: 'fa fa-gavel' },
  { value: 'fa fa-gbp', label: 'fa fa-gbp' },
  { value: 'fa fa-ge', label: 'fa fa-ge' },
  { value: 'fa fa-gear', label: 'fa fa-gear' },
  { value: 'fa fa-gears', label: 'fa fa-gears' },
  { value: 'fa fa-genderless', label: 'fa fa-genderless' },
  { value: 'fa fa-get-pocket', label: 'fa fa-get-pocket' },
  { value: 'fa fa-gg', label: 'fa fa-gg' },
  { value: 'fa fa-gg-circle', label: 'fa fa-gg-circle' },
  { value: 'fa fa-gift', label: 'fa fa-gift' },
  { value: 'fa fa-git', label: 'fa fa-git' },
  { value: 'fa fa-git-square', label: 'fa fa-git-square' },
  { value: 'fa fa-github', label: 'fa fa-github' },
  { value: 'fa fa-github-alt', label: 'fa fa-github-alt' },
  { value: 'fa fa-github-square', label: 'fa fa-github-square' },
  { value: 'fa fa-gitlab', label: 'fa fa-gitlab' },
  { value: 'fa fa-gittip', label: 'fa fa-gittip' },
  { value: 'fa fa-glass', label: 'fa fa-glass' },
  { value: 'fa fa-glide', label: 'fa fa-glide' },
  { value: 'fa fa-glide-g', label: 'fa fa-glide-g' },
  { value: 'fa fa-globe', label: 'fa fa-globe' },
  { value: 'fa fa-google', label: 'fa fa-google' },
  { value: 'fa fa-google-plus', label: 'fa fa-google-plus' },
  { value: 'fa fa-google-plus-circle', label: 'fa fa-google-plus-circle' },
  { value: 'fa fa-google-plus-official', label: 'fa fa-google-plus-official' },
  { value: 'fa fa-google-plus-square', label: 'fa fa-google-plus-square' },
  { value: 'fa fa-google-wallet', label: 'fa fa-google-wallet' },
  { value: 'fa fa-graduation-cap', label: 'fa fa-graduation-cap' },
  { value: 'fa fa-gratipay', label: 'fa fa-gratipay' },
  { value: 'fa fa-grav', label: 'fa fa-grav' },
  { value: 'fa fa-group', label: 'fa fa-group' },
  { value: 'fa fa-h-square', label: 'fa fa-h-square' },
  { value: 'fa fa-hacker-news', label: 'fa fa-hacker-news' },
  { value: 'fa fa-hand-grab-o', label: 'fa fa-hand-grab-o' },
  { value: 'fa fa-hand-lizard-o', label: 'fa fa-hand-lizard-o' },
  { value: 'fa fa-hand-o-down', label: 'fa fa-hand-o-down' },
  { value: 'fa fa-hand-o-left', label: 'fa fa-hand-o-left' },
  { value: 'fa fa-hand-o-right', label: 'fa fa-hand-o-right' },
  { value: 'fa fa-hand-o-up', label: 'fa fa-hand-o-up' },
  { value: 'fa fa-hand-paper-o', label: 'fa fa-hand-paper-o' },
  { value: 'fa fa-hand-peace-o', label: 'fa fa-hand-peace-o' },
  { value: 'fa fa-hand-pointer-o', label: 'fa fa-hand-pointer-o' },
  { value: 'fa fa-hand-rock-o', label: 'fa fa-hand-rock-o' },
  { value: 'fa fa-hand-scissors-o', label: 'fa fa-hand-scissors-o' },
  { value: 'fa fa-hand-spock-o', label: 'fa fa-hand-spock-o' },
  { value: 'fa fa-hand-stop-o', label: 'fa fa-hand-stop-o' },
  { value: 'fa fa-handshake-o', label: 'fa fa-handshake-o' },
  { value: 'fa fa-hard-of-hearing', label: 'fa fa-hard-of-hearing' },
  { value: 'fa fa-hashtag', label: 'fa fa-hashtag' },
  { value: 'fa fa-hdd-o', label: 'fa fa-hdd-o' },
  { value: 'fa fa-header', label: 'fa fa-header' },
  { value: 'fa fa-headphones', label: 'fa fa-headphones' },
  { value: 'fa fa-heart', label: 'fa fa-heart' },
  { value: 'fa fa-heart-o', label: 'fa fa-heart-o' },
  { value: 'fa fa-heartbeat', label: 'fa fa-heartbeat' },
  { value: 'fa fa-history', label: 'fa fa-history' },
  { value: 'fa fa-home', label: 'fa fa-home' },
  { value: 'fa fa-hospital-o', label: 'fa fa-hospital-o' },
  { value: 'fa fa-hotel', label: 'fa fa-hotel' },
  { value: 'fa fa-hourglass', label: 'fa fa-hourglass' },
  { value: 'fa fa-hourglass-1', label: 'fa fa-hourglass-1' },
  { value: 'fa fa-hourglass-2', label: 'fa fa-hourglass-2' },
  { value: 'fa fa-hourglass-3', label: 'fa fa-hourglass-3' },
  { value: 'fa fa-hourglass-end', label: 'fa fa-hourglass-end' },
  { value: 'fa fa-hourglass-half', label: 'fa fa-hourglass-half' },
  { value: 'fa fa-hourglass-o', label: 'fa fa-hourglass-o' },
  { value: 'fa fa-hourglass-start', label: 'fa fa-hourglass-start' },
  { value: 'fa fa-houzz', label: 'fa fa-houzz' },
  { value: 'fa fa-html5', label: 'fa fa-html5' },
  { value: 'fa fa-i-cursor', label: 'fa fa-i-cursor' },
  { value: 'fa fa-id-badge', label: 'fa fa-id-badge' },
  { value: 'fa fa-id-card', label: 'fa fa-id-card' },
  { value: 'fa fa-id-card-o', label: 'fa fa-id-card-o' },
  { value: 'fa fa-ils', label: 'fa fa-ils' },
  { value: 'fa fa-image', label: 'fa fa-image' },
  { value: 'fa fa-imdb', label: 'fa fa-imdb' },
  { value: 'fa fa-inbox', label: 'fa fa-inbox' },
  { value: 'fa fa-indent', label: 'fa fa-indent' },
  { value: 'fa fa-industry', label: 'fa fa-industry' },
  { value: 'fa fa-info', label: 'fa fa-info' },
  { value: 'fa fa-info-circle', label: 'fa fa-info-circle' },
  { value: 'fa fa-inr', label: 'fa fa-inr' },
  { value: 'fa fa-instagram', label: 'fa fa-instagram' },
  { value: 'fa fa-institution', label: 'fa fa-institution' },
  { value: 'fa fa-internet-explorer', label: 'fa fa-internet-explorer' },
  { value: 'fa fa-intersex', label: 'fa fa-intersex' },
  { value: 'fa fa-ioxhost', label: 'fa fa-ioxhost' },
  { value: 'fa fa-italic', label: 'fa fa-italic' },
  { value: 'fa fa-joomla', label: 'fa fa-joomla' },
  { value: 'fa fa-jpy', label: 'fa fa-jpy' },
  { value: 'fa fa-jsfiddle', label: 'fa fa-jsfiddle' },
  { value: 'fa fa-key', label: 'fa fa-key' },
  { value: 'fa fa-keyboard-o', label: 'fa fa-keyboard-o' },
  { value: 'fa fa-krw', label: 'fa fa-krw' },
  { value: 'fa fa-language', label: 'fa fa-language' },
  { value: 'fa fa-laptop', label: 'fa fa-laptop' },
  { value: 'fa fa-lastfm', label: 'fa fa-lastfm' },
  { value: 'fa fa-lastfm-square', label: 'fa fa-lastfm-square' },
  { value: 'fa fa-leaf', label: 'fa fa-leaf' },
  { value: 'fa fa-leanpub', label: 'fa fa-leanpub' },
  { value: 'fa fa-legal', label: 'fa fa-legal' },
  { value: 'fa fa-lemon-o', label: 'fa fa-lemon-o' },
  { value: 'fa fa-level-down', label: 'fa fa-level-down' },
  { value: 'fa fa-level-up', label: 'fa fa-level-up' },
  { value: 'fa fa-life-bouy', label: 'fa fa-life-bouy' },
  { value: 'fa fa-life-buoy', label: 'fa fa-life-buoy' },
  { value: 'fa fa-life-ring', label: 'fa fa-life-ring' },
  { value: 'fa fa-life-saver', label: 'fa fa-life-saver' },
  { value: 'fa fa-lightbulb-o', label: 'fa fa-lightbulb-o' },
  { value: 'fa fa-line-chart', label: 'fa fa-line-chart' },
  { value: 'fa fa-link', label: 'fa fa-link' },
  { value: 'fa fa-linkedin', label: 'fa fa-linkedin' },
  { value: 'fa fa-linkedin-square', label: 'fa fa-linkedin-square' },
  { value: 'fa fa-linode', label: 'fa fa-linode' },
  { value: 'fa fa-linux', label: 'fa fa-linux' },
  { value: 'fa fa-list', label: 'fa fa-list' },
  { value: 'fa fa-list-alt', label: 'fa fa-list-alt' },
  { value: 'fa fa-list-ol', label: 'fa fa-list-ol' },
  { value: 'fa fa-list-ul', label: 'fa fa-list-ul' },
  { value: 'fa fa-location-arrow', label: 'fa fa-location-arrow' },
  { value: 'fa fa-lock', label: 'fa fa-lock' },
  { value: 'fa fa-long-arrow-down', label: 'fa fa-long-arrow-down' },
  { value: 'fa fa-long-arrow-left', label: 'fa fa-long-arrow-left' },
  { value: 'fa fa-long-arrow-right', label: 'fa fa-long-arrow-right' },
  { value: 'fa fa-long-arrow-up', label: 'fa fa-long-arrow-up' },
  { value: 'fa fa-low-vision', label: 'fa fa-low-vision' },
  { value: 'fa fa-magic', label: 'fa fa-magic' },
  { value: 'fa fa-magnet', label: 'fa fa-magnet' },
  { value: 'fa fa-mail-forward', label: 'fa fa-mail-forward' },
  { value: 'fa fa-mail-reply', label: 'fa fa-mail-reply' },
  { value: 'fa fa-mail-reply-all', label: 'fa fa-mail-reply-all' },
  { value: 'fa fa-male', label: 'fa fa-male' },
  { value: 'fa fa-map', label: 'fa fa-map' },
  { value: 'fa fa-map-marker', label: 'fa fa-map-marker' },
  { value: 'fa fa-map-o', label: 'fa fa-map-o' },
  { value: 'fa fa-map-pin', label: 'fa fa-map-pin' },
  { value: 'fa fa-map-signs', label: 'fa fa-map-signs' },
  { value: 'fa fa-mars', label: 'fa fa-mars' },
  { value: 'fa fa-mars-double', label: 'fa fa-mars-double' },
  { value: 'fa fa-mars-stroke', label: 'fa fa-mars-stroke' },
  { value: 'fa fa-mars-stroke-h', label: 'fa fa-mars-stroke-h' },
  { value: 'fa fa-mars-stroke-v', label: 'fa fa-mars-stroke-v' },
  { value: 'fa fa-maxcdn', label: 'fa fa-maxcdn' },
  { value: 'fa fa-meanpath', label: 'fa fa-meanpath' },
  { value: 'fa fa-medium', label: 'fa fa-medium' },
  { value: 'fa fa-medkit', label: 'fa fa-medkit' },
  { value: 'fa fa-meetup', label: 'fa fa-meetup' },
  { value: 'fa fa-meh-o', label: 'fa fa-meh-o' },
  { value: 'fa fa-mercury', label: 'fa fa-mercury' },
  { value: 'fa fa-microchip', label: 'fa fa-microchip' },
  { value: 'fa fa-microphone', label: 'fa fa-microphone' },
  { value: 'fa fa-microphone-slash', label: 'fa fa-microphone-slash' },
  { value: 'fa fa-minus', label: 'fa fa-minus' },
  { value: 'fa fa-minus-circle', label: 'fa fa-minus-circle' },
  { value: 'fa fa-minus-square', label: 'fa fa-minus-square' },
  { value: 'fa fa-minus-square-o', label: 'fa fa-minus-square-o' },
  { value: 'fa fa-mixcloud', label: 'fa fa-mixcloud' },
  { value: 'fa fa-mobile', label: 'fa fa-mobile' },
  { value: 'fa fa-mobile-phone', label: 'fa fa-mobile-phone' },
  { value: 'fa fa-modx', label: 'fa fa-modx' },
  { value: 'fa fa-money', label: 'fa fa-money' },
  { value: 'fa fa-moon-o', label: 'fa fa-moon-o' },
  { value: 'fa fa-mortar-board', label: 'fa fa-mortar-board' },
  { value: 'fa fa-motorcycle', label: 'fa fa-motorcycle' },
  { value: 'fa fa-mouse-pointer', label: 'fa fa-mouse-pointer' },
  { value: 'fa fa-music', label: 'fa fa-music' },
  { value: 'fa fa-navicon', label: 'fa fa-navicon' },
  { value: 'fa fa-neuter', label: 'fa fa-neuter' },
  { value: 'fa fa-newspaper-o', label: 'fa fa-newspaper-o' },
  { value: 'fa fa-object-group', label: 'fa fa-object-group' },
  { value: 'fa fa-object-ungroup', label: 'fa fa-object-ungroup' },
  { value: 'fa fa-odnoklassniki', label: 'fa fa-odnoklassniki' },
  { value: 'fa fa-odnoklassniki-square', label: 'fa fa-odnoklassniki-square' },
  { value: 'fa fa-opencart', label: 'fa fa-opencart' },
  { value: 'fa fa-openid', label: 'fa fa-openid' },
  { value: 'fa fa-opera', label: 'fa fa-opera' },
  { value: 'fa fa-optin-monster', label: 'fa fa-optin-monster' },
  { value: 'fa fa-outdent', label: 'fa fa-outdent' },
  { value: 'fa fa-pagelines', label: 'fa fa-pagelines' },
  { value: 'fa fa-paint-brush', label: 'fa fa-paint-brush' },
  { value: 'fa fa-paper-plane', label: 'fa fa-paper-plane' },
  { value: 'fa fa-paper-plane-o', label: 'fa fa-paper-plane-o' },
  { value: 'fa fa-paperclip', label: 'fa fa-paperclip' },
  { value: 'fa fa-paragraph', label: 'fa fa-paragraph' },
  { value: 'fa fa-paste', label: 'fa fa-paste' },
  { value: 'fa fa-pause', label: 'fa fa-pause' },
  { value: 'fa fa-pause-circle', label: 'fa fa-pause-circle' },
  { value: 'fa fa-pause-circle-o', label: 'fa fa-pause-circle-o' },
  { value: 'fa fa-paw', label: 'fa fa-paw' },
  { value: 'fa fa-paypal', label: 'fa fa-paypal' },
  { value: 'fa fa-pencil', label: 'fa fa-pencil' },
  { value: 'fa fa-pencil-square', label: 'fa fa-pencil-square' },
  { value: 'fa fa-pencil-square-o', label: 'fa fa-pencil-square-o' },
  { value: 'fa fa-percent', label: 'fa fa-percent' },
  { value: 'fa fa-phone', label: 'fa fa-phone' },
  { value: 'fa fa-phone-square', label: 'fa fa-phone-square' },
  { value: 'fa fa-photo', label: 'fa fa-photo' },
  { value: 'fa fa-picture-o', label: 'fa fa-picture-o' },
  { value: 'fa fa-pie-chart', label: 'fa fa-pie-chart' },
  { value: 'fa fa-pied-piper', label: 'fa fa-pied-piper' },
  { value: 'fa fa-pied-piper-alt', label: 'fa fa-pied-piper-alt' },
  { value: 'fa fa-pied-piper-pp', label: 'fa fa-pied-piper-pp' },
  { value: 'fa fa-pinterest', label: 'fa fa-pinterest' },
  { value: 'fa fa-pinterest-p', label: 'fa fa-pinterest-p' },
  { value: 'fa fa-pinterest-square', label: 'fa fa-pinterest-square' },
  { value: 'fa fa-plane', label: 'fa fa-plane' },
  { value: 'fa fa-play', label: 'fa fa-play' },
  { value: 'fa fa-play-circle', label: 'fa fa-play-circle' },
  { value: 'fa fa-play-circle-o', label: 'fa fa-play-circle-o' },
  { value: 'fa fa-plug', label: 'fa fa-plug' },
  { value: 'fa fa-plus', label: 'fa fa-plus' },
  { value: 'fa fa-plus-circle', label: 'fa fa-plus-circle' },
  { value: 'fa fa-plus-square', label: 'fa fa-plus-square' },
  { value: 'fa fa-plus-square-o', label: 'fa fa-plus-square-o' },
  { value: 'fa fa-podcast', label: 'fa fa-podcast' },
  { value: 'fa fa-power-off', label: 'fa fa-power-off' },
  { value: 'fa fa-print', label: 'fa fa-print' },
  { value: 'fa fa-product-hunt', label: 'fa fa-product-hunt' },
  { value: 'fa fa-puzzle-piece', label: 'fa fa-puzzle-piece' },
  { value: 'fa fa-qq', label: 'fa fa-qq' },
  { value: 'fa fa-qrcode', label: 'fa fa-qrcode' },
  { value: 'fa fa-question', label: 'fa fa-question' },
  { value: 'fa fa-question-circle', label: 'fa fa-question-circle' },
  { value: 'fa fa-question-circle-o', label: 'fa fa-question-circle-o' },
  { value: 'fa fa-quora', label: 'fa fa-quora' },
  { value: 'fa fa-quote-left', label: 'fa fa-quote-left' },
  { value: 'fa fa-quote-right', label: 'fa fa-quote-right' },
  { value: 'fa fa-ra', label: 'fa fa-ra' },
  { value: 'fa fa-random', label: 'fa fa-random' },
  { value: 'fa fa-ravelry', label: 'fa fa-ravelry' },
  { value: 'fa fa-rebel', label: 'fa fa-rebel' },
  { value: 'fa fa-recycle', label: 'fa fa-recycle' },
  { value: 'fa fa-reddit', label: 'fa fa-reddit' },
  { value: 'fa fa-reddit-alien', label: 'fa fa-reddit-alien' },
  { value: 'fa fa-reddit-square', label: 'fa fa-reddit-square' },
  { value: 'fa fa-refresh', label: 'fa fa-refresh' },
  { value: 'fa fa-registered', label: 'fa fa-registered' },
  { value: 'fa fa-remove', label: 'fa fa-remove' },
  { value: 'fa fa-renren', label: 'fa fa-renren' },
  { value: 'fa fa-reorder', label: 'fa fa-reorder' },
  { value: 'fa fa-repeat', label: 'fa fa-repeat' },
  { value: 'fa fa-reply', label: 'fa fa-reply' },
  { value: 'fa fa-reply-all', label: 'fa fa-reply-all' },
  { value: 'fa fa-resistance', label: 'fa fa-resistance' },
  { value: 'fa fa-retweet', label: 'fa fa-retweet' },
  { value: 'fa fa-rmb', label: 'fa fa-rmb' },
  { value: 'fa fa-road', label: 'fa fa-road' },
  { value: 'fa fa-rocket', label: 'fa fa-rocket' },
  { value: 'fa fa-rotate-left', label: 'fa fa-rotate-left' },
  { value: 'fa fa-rotate-right', label: 'fa fa-rotate-right' },
  { value: 'fa fa-rouble', label: 'fa fa-rouble' },
  { value: 'fa fa-rss', label: 'fa fa-rss' },
  { value: 'fa fa-rss-square', label: 'fa fa-rss-square' },
  { value: 'fa fa-rub', label: 'fa fa-rub' },
  { value: 'fa fa-ruble', label: 'fa fa-ruble' },
  { value: 'fa fa-rupee', label: 'fa fa-rupee' },
  { value: 'fa fa-s15', label: 'fa fa-s15' },
  { value: 'fa fa-safari', label: 'fa fa-safari' },
  { value: 'fa fa-save', label: 'fa fa-save' },
  { value: 'fa fa-scissors', label: 'fa fa-scissors' },
  { value: 'fa fa-scribd', label: 'fa fa-scribd' },
  { value: 'fa fa-search', label: 'fa fa-search' },
  { value: 'fa fa-search-minus', label: 'fa fa-search-minus' },
  { value: 'fa fa-search-plus', label: 'fa fa-search-plus' },
  { value: 'fa fa-sellsy', label: 'fa fa-sellsy' },
  { value: 'fa fa-send', label: 'fa fa-send' },
  { value: 'fa fa-send-o', label: 'fa fa-send-o' },
  { value: 'fa fa-server', label: 'fa fa-server' },
  { value: 'fa fa-share', label: 'fa fa-share' },
  { value: 'fa fa-share-alt', label: 'fa fa-share-alt' },
  { value: 'fa fa-share-alt-square', label: 'fa fa-share-alt-square' },
  { value: 'fa fa-share-square', label: 'fa fa-share-square' },
  { value: 'fa fa-share-square-o', label: 'fa fa-share-square-o' },
  { value: 'fa fa-shekel', label: 'fa fa-shekel' },
  { value: 'fa fa-sheqel', label: 'fa fa-sheqel' },
  { value: 'fa fa-shield', label: 'fa fa-shield' },
  { value: 'fa fa-ship', label: 'fa fa-ship' },
  { value: 'fa fa-shirtsinbulk', label: 'fa fa-shirtsinbulk' },
  { value: 'fa fa-shopping-bag', label: 'fa fa-shopping-bag' },
  { value: 'fa fa-shopping-basket', label: 'fa fa-shopping-basket' },
  { value: 'fa fa-shopping-cart', label: 'fa fa-shopping-cart' },
  { value: 'fa fa-shower', label: 'fa fa-shower' },
  { value: 'fa fa-sign-in', label: 'fa fa-sign-in' },
  { value: 'fa fa-sign-language', label: 'fa fa-sign-language' },
  { value: 'fa fa-sign-out', label: 'fa fa-sign-out' },
  { value: 'fa fa-signal', label: 'fa fa-signal' },
  { value: 'fa fa-signing', label: 'fa fa-signing' },
  { value: 'fa fa-simplybuilt', label: 'fa fa-simplybuilt' },
  { value: 'fa fa-sitemap', label: 'fa fa-sitemap' },
  { value: 'fa fa-skyatlas', label: 'fa fa-skyatlas' },
  { value: 'fa fa-skype', label: 'fa fa-skype' },
  { value: 'fa fa-slack', label: 'fa fa-slack' },
  { value: 'fa fa-sliders', label: 'fa fa-sliders' },
  { value: 'fa fa-slideshare', label: 'fa fa-slideshare' },
  { value: 'fa fa-smile-o', label: 'fa fa-smile-o' },
  { value: 'fa fa-snapchat', label: 'fa fa-snapchat' },
  { value: 'fa fa-snapchat-ghost', label: 'fa fa-snapchat-ghost' },
  { value: 'fa fa-snapchat-square', label: 'fa fa-snapchat-square' },
  { value: 'fa fa-snowflake-o', label: 'fa fa-snowflake-o' },
  { value: 'fa fa-soccer-ball-o', label: 'fa fa-soccer-ball-o' },
  { value: 'fa fa-sort', label: 'fa fa-sort' },
  { value: 'fa fa-sort-alpha-asc', label: 'fa fa-sort-alpha-asc' },
  { value: 'fa fa-sort-alpha-desc', label: 'fa fa-sort-alpha-desc' },
  { value: 'fa fa-sort-amount-asc', label: 'fa fa-sort-amount-asc' },
  { value: 'fa fa-sort-amount-desc', label: 'fa fa-sort-amount-desc' },
  { value: 'fa fa-sort-asc', label: 'fa fa-sort-asc' },
  { value: 'fa fa-sort-desc', label: 'fa fa-sort-desc' },
  { value: 'fa fa-sort-down', label: 'fa fa-sort-down' },
  { value: 'fa fa-sort-numeric-asc', label: 'fa fa-sort-numeric-asc' },
  { value: 'fa fa-sort-numeric-desc', label: 'fa fa-sort-numeric-desc' },
  { value: 'fa fa-sort-up', label: 'fa fa-sort-up' },
  { value: 'fa fa-soundcloud', label: 'fa fa-soundcloud' },
  { value: 'fa fa-space-shuttle', label: 'fa fa-space-shuttle' },
  { value: 'fa fa-spinner', label: 'fa fa-spinner' },
  { value: 'fa fa-spoon', label: 'fa fa-spoon' },
  { value: 'fa fa-spotify', label: 'fa fa-spotify' },
  { value: 'fa fa-square', label: 'fa fa-square' },
  { value: 'fa fa-square-o', label: 'fa fa-square-o' },
  { value: 'fa fa-stack-exchange', label: 'fa fa-stack-exchange' },
  { value: 'fa fa-stack-overflow', label: 'fa fa-stack-overflow' },
  { value: 'fa fa-star', label: 'fa fa-star' },
  { value: 'fa fa-star-half', label: 'fa fa-star-half' },
  { value: 'fa fa-star-half-empty', label: 'fa fa-star-half-empty' },
  { value: 'fa fa-star-half-full', label: 'fa fa-star-half-full' },
  { value: 'fa fa-star-half-o', label: 'fa fa-star-half-o' },
  { value: 'fa fa-star-o', label: 'fa fa-star-o' },
  { value: 'fa fa-steam', label: 'fa fa-steam' },
  { value: 'fa fa-steam-square', label: 'fa fa-steam-square' },
  { value: 'fa fa-step-backward', label: 'fa fa-step-backward' },
  { value: 'fa fa-step-forward', label: 'fa fa-step-forward' },
  { value: 'fa fa-stethoscope', label: 'fa fa-stethoscope' },
  { value: 'fa fa-sticky-note', label: 'fa fa-sticky-note' },
  { value: 'fa fa-sticky-note-o', label: 'fa fa-sticky-note-o' },
  { value: 'fa fa-stop', label: 'fa fa-stop' },
  { value: 'fa fa-stop-circle', label: 'fa fa-stop-circle' },
  { value: 'fa fa-stop-circle-o', label: 'fa fa-stop-circle-o' },
  { value: 'fa fa-street-view', label: 'fa fa-street-view' },
  { value: 'fa fa-strikethrough', label: 'fa fa-strikethrough' },
  { value: 'fa fa-stumbleupon', label: 'fa fa-stumbleupon' },
  { value: 'fa fa-stumbleupon-circle', label: 'fa fa-stumbleupon-circle' },
  { value: 'fa fa-subscript', label: 'fa fa-subscript' },
  { value: 'fa fa-subway', label: 'fa fa-subway' },
  { value: 'fa fa-suitcase', label: 'fa fa-suitcase' },
  { value: 'fa fa-sun-o', label: 'fa fa-sun-o' },
  { value: 'fa fa-superpowers', label: 'fa fa-superpowers' },
  { value: 'fa fa-superscript', label: 'fa fa-superscript' },
  { value: 'fa fa-support', label: 'fa fa-support' },
  { value: 'fa fa-table', label: 'fa fa-table' },
  { value: 'fa fa-tablet', label: 'fa fa-tablet' },
  { value: 'fa fa-tachometer', label: 'fa fa-tachometer' },
  { value: 'fa fa-tag', label: 'fa fa-tag' },
  { value: 'fa fa-tags', label: 'fa fa-tags' },
  { value: 'fa fa-tasks', label: 'fa fa-tasks' },
  { value: 'fa fa-taxi', label: 'fa fa-taxi' },
  { value: 'fa fa-telegram', label: 'fa fa-telegram' },
  { value: 'fa fa-television', label: 'fa fa-television' },
  { value: 'fa fa-tencent-weibo', label: 'fa fa-tencent-weibo' },
  { value: 'fa fa-terminal', label: 'fa fa-terminal' },
  { value: 'fa fa-text-height', label: 'fa fa-text-height' },
  { value: 'fa fa-text-width', label: 'fa fa-text-width' },
  { value: 'fa fa-th', label: 'fa fa-th' },
  { value: 'fa fa-th-large', label: 'fa fa-th-large' },
  { value: 'fa fa-th-list', label: 'fa fa-th-list' },
  { value: 'fa fa-themeisle', label: 'fa fa-themeisle' },
  { value: 'fa fa-thermometer', label: 'fa fa-thermometer' },
  { value: 'fa fa-thermometer-0', label: 'fa fa-thermometer-0' },
  { value: 'fa fa-thermometer-1', label: 'fa fa-thermometer-1' },
  { value: 'fa fa-thermometer-2', label: 'fa fa-thermometer-2' },
  { value: 'fa fa-thermometer-3', label: 'fa fa-thermometer-3' },
  { value: 'fa fa-thermometer-4', label: 'fa fa-thermometer-4' },
  { value: 'fa fa-thermometer-empty', label: 'fa fa-thermometer-empty' },
  { value: 'fa fa-thermometer-full', label: 'fa fa-thermometer-full' },
  { value: 'fa fa-thermometer-half', label: 'fa fa-thermometer-half' },
  { value: 'fa fa-thermometer-quarter', label: 'fa fa-thermometer-quarter' },
  { value: 'fa fa-thermometer-three-quarters', label: 'fa fa-thermometer-three-quarters' },
  { value: 'fa fa-thumb-tack', label: 'fa fa-thumb-tack' },
  { value: 'fa fa-thumbs-down', label: 'fa fa-thumbs-down' },
  { value: 'fa fa-thumbs-o-down', label: 'fa fa-thumbs-o-down' },
  { value: 'fa fa-thumbs-o-up', label: 'fa fa-thumbs-o-up' },
  { value: 'fa fa-thumbs-up', label: 'fa fa-thumbs-up' },
  { value: 'fa fa-ticket', label: 'fa fa-ticket' },
  { value: 'fa fa-times', label: 'fa fa-times' },
  { value: 'fa fa-times-circle', label: 'fa fa-times-circle' },
  { value: 'fa fa-times-circle-o', label: 'fa fa-times-circle-o' },
  { value: 'fa fa-times-rectangle', label: 'fa fa-times-rectangle' },
  { value: 'fa fa-times-rectangle-o', label: 'fa fa-times-rectangle-o' },
  { value: 'fa fa-tint', label: 'fa fa-tint' },
  { value: 'fa fa-toggle-down', label: 'fa fa-toggle-down' },
  { value: 'fa fa-toggle-left', label: 'fa fa-toggle-left' },
  { value: 'fa fa-toggle-off', label: 'fa fa-toggle-off' },
  { value: 'fa fa-toggle-on', label: 'fa fa-toggle-on' },
  { value: 'fa fa-toggle-right', label: 'fa fa-toggle-right' },
  { value: 'fa fa-toggle-up', label: 'fa fa-toggle-up' },
  { value: 'fa fa-trademark', label: 'fa fa-trademark' },
  { value: 'fa fa-train', label: 'fa fa-train' },
  { value: 'fa fa-transgender', label: 'fa fa-transgender' },
  { value: 'fa fa-transgender-alt', label: 'fa fa-transgender-alt' },
  { value: 'fa fa-trash', label: 'fa fa-trash' },
  { value: 'fa fa-trash-o', label: 'fa fa-trash-o' },
  { value: 'fa fa-tree', label: 'fa fa-tree' },
  { value: 'fa fa-trello', label: 'fa fa-trello' },
  { value: 'fa fa-tripadvisor', label: 'fa fa-tripadvisor' },
  { value: 'fa fa-trophy', label: 'fa fa-trophy' },
  { value: 'fa fa-truck', label: 'fa fa-truck' },
  { value: 'fa fa-try', label: 'fa fa-try' },
  { value: 'fa fa-tty', label: 'fa fa-tty' },
  { value: 'fa fa-tumblr', label: 'fa fa-tumblr' },
  { value: 'fa fa-tumblr-square', label: 'fa fa-tumblr-square' },
  { value: 'fa fa-turkish-lira', label: 'fa fa-turkish-lira' },
  { value: 'fa fa-tv', label: 'fa fa-tv' },
  { value: 'fa fa-twitch', label: 'fa fa-twitch' },
  { value: 'fa fa-twitter', label: 'fa fa-twitter' },
  { value: 'fa fa-twitter-square', label: 'fa fa-twitter-square' },
  { value: 'fa fa-umbrella', label: 'fa fa-umbrella' },
  { value: 'fa fa-underline', label: 'fa fa-underline' },
  { value: 'fa fa-undo', label: 'fa fa-undo' },
  { value: 'fa fa-universal-access', label: 'fa fa-universal-access' },
  { value: 'fa fa-university', label: 'fa fa-university' },
  { value: 'fa fa-unlink', label: 'fa fa-unlink' },
  { value: 'fa fa-unlock', label: 'fa fa-unlock' },
  { value: 'fa fa-unlock-alt', label: 'fa fa-unlock-alt' },
  { value: 'fa fa-unsorted', label: 'fa fa-unsorted' },
  { value: 'fa fa-upload', label: 'fa fa-upload' },
  { value: 'fa fa-usb', label: 'fa fa-usb' },
  { value: 'fa fa-usd', label: 'fa fa-usd' },
  { value: 'fa fa-user', label: 'fa fa-user' },
  { value: 'fa fa-user-circle', label: 'fa fa-user-circle' },
  { value: 'fa fa-user-circle-o', label: 'fa fa-user-circle-o' },
  { value: 'fa fa-user-md', label: 'fa fa-user-md' },
  { value: 'fa fa-user-o', label: 'fa fa-user-o' },
  { value: 'fa fa-user-plus', label: 'fa fa-user-plus' },
  { value: 'fa fa-user-secret', label: 'fa fa-user-secret' },
  { value: 'fa fa-user-times', label: 'fa fa-user-times' },
  { value: 'fa fa-users', label: 'fa fa-users' },
  { value: 'fa fa-vcard', label: 'fa fa-vcard' },
  { value: 'fa fa-vcard-o', label: 'fa fa-vcard-o' },
  { value: 'fa fa-venus', label: 'fa fa-venus' },
  { value: 'fa fa-venus-double', label: 'fa fa-venus-double' },
  { value: 'fa fa-venus-mars', label: 'fa fa-venus-mars' },
  { value: 'fa fa-viacoin', label: 'fa fa-viacoin' },
  { value: 'fa fa-viadeo', label: 'fa fa-viadeo' },
  { value: 'fa fa-viadeo-square', label: 'fa fa-viadeo-square' },
  { value: 'fa fa-video-camera', label: 'fa fa-video-camera' },
  { value: 'fa fa-vimeo', label: 'fa fa-vimeo' },
  { value: 'fa fa-vimeo-square', label: 'fa fa-vimeo-square' },
  { value: 'fa fa-vine', label: 'fa fa-vine' },
  { value: 'fa fa-vk', label: 'fa fa-vk' },
  { value: 'fa fa-volume-control-phone', label: 'fa fa-volume-control-phone' },
  { value: 'fa fa-volume-down', label: 'fa fa-volume-down' },
  { value: 'fa fa-volume-off', label: 'fa fa-volume-off' },
  { value: 'fa fa-volume-up', label: 'fa fa-volume-up' },
  { value: 'fa fa-warning', label: 'fa fa-warning' },
  { value: 'fa fa-wechat', label: 'fa fa-wechat' },
  { value: 'fa fa-weibo', label: 'fa fa-weibo' },
  { value: 'fa fa-weixin', label: 'fa fa-weixin' },
  { value: 'fa fa-whatsapp', label: 'fa fa-whatsapp' },
  { value: 'fa fa-wheelchair', label: 'fa fa-wheelchair' },
  { value: 'fa fa-wheelchair-alt', label: 'fa fa-wheelchair-alt' },
  { value: 'fa fa-wifi', label: 'fa fa-wifi' },
  { value: 'fa fa-wikipedia-w', label: 'fa fa-wikipedia-w' },
  { value: 'fa fa-window-close', label: 'fa fa-window-close' },
  { value: 'fa fa-window-close-o', label: 'fa fa-window-close-o' },
  { value: 'fa fa-window-maximize', label: 'fa fa-window-maximize' },
  { value: 'fa fa-window-minimize', label: 'fa fa-window-minimize' },
  { value: 'fa fa-window-restore', label: 'fa fa-window-restore' },
  { value: 'fa fa-windows', label: 'fa fa-windows' },
  { value: 'fa fa-won', label: 'fa fa-won' },
  { value: 'fa fa-wordpress', label: 'fa fa-wordpress' },
  { value: 'fa fa-wpbeginner', label: 'fa fa-wpbeginner' },
  { value: 'fa fa-wpexplorer', label: 'fa fa-wpexplorer' },
  { value: 'fa fa-wpforms', label: 'fa fa-wpforms' },
  { value: 'fa fa-wrench', label: 'fa fa-wrench' },
  { value: 'fa fa-xing', label: 'fa fa-xing' },
  { value: 'fa fa-xing-square', label: 'fa fa-xing-square' },
  { value: 'fa fa-y-combinator', label: 'fa fa-y-combinator' },
  { value: 'fa fa-y-combinator-square', label: 'fa fa-y-combinator-square' },
  { value: 'fa fa-yahoo', label: 'fa fa-yahoo' },
  { value: 'fa fa-yc', label: 'fa fa-yc' },
  { value: 'fa fa-yc-square', label: 'fa fa-yc-square' },
  { value: 'fa fa-yelp', label: 'fa fa-yelp' },
  { value: 'fa fa-yen', label: 'fa fa-yen' },
  { value: 'fa fa-yoast', label: 'fa fa-yoast' },
  { value: 'fa fa-youtube', label: 'fa fa-youtube' },
  { value: 'fa fa-youtube-play', label: 'fa fa-youtube-play' },
  { value: 'fa fa-youtube-square', label: 'fa fa-youtube-square' }
];

const formatOptionLabel = ({ value, label }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <i className={value} style={{ marginRight: "10px" }}></i>
    <span>{label.replace('fa fa-', '')}</span>
  </div>
);

const FontAwesomeIconDropdown = ({onIconChange, isVisible }) => {
  const [isOpen, setIsOpen] = useState(true);
  const wrapperRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Use an effect to add an event listener to the document
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // Call a function to close the dropdown
        setIsOpen(false);
      }
    };

    // Add when the dropdown is visible and remove when it is not
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  const filteredIcons = iconsList.filter(icon =>
    icon.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div ref={wrapperRef} style={{
      position: 'relative',
    }}>

      {isOpen && isVisible && (
        <ul 
          style={{
            position: 'absolute',
            listStyleType: 'none',
            paddingLeft: '10px',
            margin: 0,
            backgroundColor: '#fff',
            boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
            lineHeight:'normal',
            zIndex: 1,
            overflowY: 'auto',
            maxHeight: '300px',
            width: 'max-content'
          }}
        >
            <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{    
            width: '300px',
            padding: '10px',
            marginBottom: '10px',
            marginTop: '10px',
            boxSizing: 'border-box',
            position: 'sticky',
            top: '0',
            zIndex: 2000,
            color: 'black',
            background: 'white',
            border: '1px solid #ccc' }}
          /> 
        <ul style={{ listStyleType: 'none', padding: '10px', margin: 0 }}>
            {filteredIcons.map((icon, index) => (
              <li
                key={index}
                onClick={() => {
                  onIconChange(icon);
                  setIsOpen(false);
                }}
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', padding: '5px' }}
              >
                <i className={icon.value} style={{ marginRight: '10px' }}></i>
                {icon.label.replace('fa fa-', '')}
              </li>
            ))}
          </ul>
          {iconsList.map((icon, index) => (
            <li 
              key={index} 
              onClick={() => {
                onIconChange(icon);
                setIsOpen(false); // Close the dropdown upon selection
              }}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', padding: '5px' }}
            >
              <i className={icon.value} style={{ marginRight: '10px' }}></i>
              {icon.label.replace('fa fa-', '')}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FontAwesomeIconDropdown;