import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import './SummaryCard.css';
import UseFileExistence from '../util/FileExistenceChecker';
import IsSummaryGenerated from '../util/IsSummaryGenerated';
import GenerateSummaryButton from './GenerateSummaryButton';
import { set } from 'react-ga';

const SummaryCard = ({
  heading, summary, onClose, onEdit, onUpdateSummary, style, fileKey,
  isPaidUser, identityId, isSummaryLoading
}) => {
  const [editableSummary, setEditableSummary] = useState(summary);
  const textareaRef = useRef(null);
  const saveTimeoutRef = useRef(null);
  const [isDraggingDisabled, setIsDraggingDisabled] = useState(false);
  const [autosaveStatus, setAutosaveStatus] = useState('idle');
  //const fileExists = UseFileExistence(fileKey, identityId);
  //const isSummaryGenerated = IsSummaryGenerated(fileKey, identityId);
  const [localLoading, setLocalLoading] = useState(false);

  useEffect(() => {
    setEditableSummary(summary);
  }, [summary]);

  // Adjust textarea height based on its content
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [editableSummary]);

  useEffect(() => {
    adjustTextareaHeight();
    setEditableSummary(summary);
  }, [summary]);

  // Handle live editing changes
  const handleSummaryChange = (event) => {
    const newSummary = event.target.value;
    setEditableSummary(newSummary);
    if (onEdit) {
      onEdit(newSummary);
    }

    // Implement autosave functionality
    clearTimeout(saveTimeoutRef.current);
    saveTimeoutRef.current = setTimeout(() => {
      handleSave();
    }, 1000);
  };

  // Save the changes
  const handleSave = () => {
    setAutosaveStatus('saving');
    if (onUpdateSummary) {
      onUpdateSummary(editableSummary);
    }
    setTimeout(() => {
      setAutosaveStatus('All Changes saved');
    }, 1000);
  };

  const generateAISummaries = () => {
    if (!fileKey) {
      console.error("fileKey is undefined or empty.");
      return;
    }
  
    setLocalLoading(true);
    fetch('https://qvj8vrhpql.execute-api.us-east-1.amazonaws.com/staging', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ fileKey })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error, status = ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
        setLocalLoading(false);
        onClose();
    })
    .catch(error => {
      console.error('Error generating AI summaries:', error);
      setLocalLoading(false); // ensure loading is reset on error
    });
  };
  

  return (
    <Draggable>
      <div className="summary-card-wrapper" style={{ ...style }}>
        <div className={`summary-card ${isSummaryLoading || localLoading ? 'blur' : ''}`}>
          <div className="tags">
            <span className="tag">{heading}</span>
            <button className="options" onClick={onClose} disabled={isSummaryLoading}>
              <img src={`${process.env.PUBLIC_URL}/close-icon.png`} alt="Icon" className="close-icon-summary" />
            </button>
          </div>
          <textarea
            style={{ minWidth: '550px', maxWidth: '80vw', wordWrap: 'break-word' }}
            ref={textareaRef}
            className="editable-summary"
            value={editableSummary}
            onChange={handleSummaryChange}
            onFocus={() => setIsDraggingDisabled(true)}
            onBlur={() => setIsDraggingDisabled(false)}
            placeholder='Type your summary here...'
            disabled={isSummaryLoading}
          />
          <div className="bottom-information-container">
            {/* 
            <div className="left-group">
            {fileExists && !isSummaryGenerated && (

            <GenerateSummaryButton
                        isPaidUser={isPaidUser}
                        fileExists={fileExists}
                        isSummaryGenerated={isSummaryGenerated}
                        onClick={() => {
                          generateAISummaries();
            }}
          />

              )}
                          </div>

*/}
            <div className="autosave-status">
              {autosaveStatus === 'saving' ? 'Saving...' : 'All changes saved'}
            </div>
          </div>
        </div>
        {(isSummaryLoading || localLoading) && (
          <div className="loader-overlay">
            <div className="summary-loader"></div>
          </div>
        )}
      </div>
    </Draggable>
  );
};

export default SummaryCard;