import React, { useState } from 'react';
import SideNavBar from '../ui-components/SideNavBar'; 
import TileGallery from '../ui-components/TileGallery'; 
import './Home.css';
import { downloadData } from '@aws-amplify/storage';
import FormatFileName from '../util/FormatFileName'; 
import UploadModal from '../ui-components/uploadmodal';
import useFileUploadText from '../util/FileUploadText';
import { retrieveProcessedFile } from '../util/RetrieverText';
import getUserData from '../userManagement/getUserData';


const Home = () => {
  //state variables
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [mindMapCode, setMindMapCode] = useState('');
  const [isMindMapReady, setIsMindMapReady] = useState(false);
  const [showUploadModalButton, setShowUploadModalButton] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileKey, setFileKey] = useState('');
  const [showTileGallery, setShowTileGallery] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { uploadTextAsFile, uploadAIMagicTextAsFile, uploadProgress } = useFileUploadText();

  // Function to handle selection of PDF from TileGallery
const handleMindMapUpdate = async (fileName) => {
  setIsLoading(true);
  const formattedFileName = FormatFileName(fileName); // Format the file name
  setUploadedFileName(formattedFileName);
  let summaryFileName;
  if (fileName.endsWith('.pdf')) {
    summaryFileName = `${fileName.replace('.pdf', '')}_summary.txt`;
  } else if (fileName.endsWith('ai_magic.txt') || fileName.endsWith('text_input.txt')) {
    summaryFileName = `${fileName.replace('.txt', '')}_summary.txt`;
  }

  if (summaryFileName) {
      try {
          const downloadResult = await downloadData({
              key: summaryFileName,
              options: {
                  accessLevel: 'private'
              }
          }).result;
          let textContent = await downloadResult.body.text();
          //console.log("Before cleaning: ", textContent);
          textContent = textContent.replace(/```/g, "").replace(/mermaid/g, "").trim();
          setMindMapCode(textContent);
          setIsMindMapReady(true);
          setShowUploadModalButton(true);
          setZoomLevel(3);
          setShowFileUpload(false);
      } catch (error) {
          //error('Error fetching summary file:', error);
      } finally {
          setIsLoading(false);
      }
  } else {
      //console.error('Unsupported file type');
      setIsLoading(false);
  }
};





  // Function to open new mindmap modal
const openModal = () => {
  setIsModalOpen(true);
};
  // Function to close new mindmap modal
const closeModal = () => {
    setIsModalOpen(false);
};


  return (
    <div className="home-container">
      <SideNavBar />
      <div className="main-content">
        <TileGallery 
            onPdfSelect={handleMindMapUpdate} 
            onTxtSelect={handleMindMapUpdate}
            onNewTileClick={openModal} />
        {/* Include other content or components that belong in the main content area */}
      </div>

    </div>
  );
};

export default Home;

