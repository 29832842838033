import React from 'react';
import './WallOfLove.css';
import styled from 'styled-components';
import { useNavigate} from 'react-router-dom';
import { useState } from 'react';
import MindmapSumComponent from '../../util/MindmapSumComponent';

const Button = styled.button`
  background: linear-gradient(to bottom, rgba(11, 121, 175, 0.31), rgba(11, 121, 175, 0));    
  border: 1px solid rgba(11, 121, 175, 0.8); // Border color changed to white
  border-radius: 42px; // Border-radius matched with your styled component
  padding: 20px 50px; // Padding matched with your styled component
  font-family: "Satoshi Variable-Bold", Helvetica;
  font-size: 20px;
  font-weight:700;
  color: black; // Text color is white
  cursor: pointer;
  margin-left: 10px;
  margin-top:50px;
  transition: background-color 0.3s, color 0s; // Smooth transition for hover effect

  @media (max-width: 768px) {
    padding: 20px 40px; // Smaller padding for mobile
    font-size: 20px; // Smaller font size for mobile
  }
  &:hover {
    background: linear-gradient(to bottom, rgba(11, 121, 175, 0.31), rgba(11, 121, 175, 1));   
    color: white; // Dark text color on hover
    border-color: rgba(11, 121, 175, 0.8); // Lighter border color on hover
  }
`;


const WallOfLove = () => {
    const navigate = useNavigate();
    const [sumOfMindmaps, setSumOfMindmaps] = useState(0);
    const handleLogin = () => {
        navigate('/login');
    };

    const getOrdinalSuffix = (number) => {
        const lastDigit = number % 10;
        if (number >= 11 && number <= 13) {
            return 'th';
        } else if (lastDigit === 1) {
            return 'st';
        } else if (lastDigit === 2) {
            return 'nd';
        } else if (lastDigit === 3) {
            return 'rd';
        } else {
            return 'th';
        }
    };

    return (
        <div className='wall-of-love-container' style={{ backgroundImage: "url('/background/BackgroundHero.png')", backgroundSize: 'contain', backgroundPosition: 'center' }}>
              <div style={{ display: 'none' }}>
        <MindmapSumComponent onSumUpdate={setSumOfMindmaps} />
      </div>
            <h2 className='wall-of-love-heading'>Over 250,000+ mind maps generated</h2>
            <p className='wall-of-love-description'>What Mind Map nerds are Saying</p>
            <iframe className='wall-of-love-embed'
                src="https://embed-v2.testimonial.to/w/map-this?animated=on&theme=light&shadowColor=ffffff&speed=1&tag=all"
                width="100%"
                height="500px"
                frameBorder="0"
                scrolling='no'
                allowFullScreen
            ></iframe>
            <h2 className='wall-of-love-cta-heading'>Make the <span className='highlight-mindmap counter'>{sumOfMindmaps+1}<sup>{getOrdinalSuffix(MindmapSumComponent)}</sup></span> mind map <span className='highlight-pdf wall-of-love'>today</span></h2>
            <Button onClick={handleLogin}>Try for free</Button>
            <p className='hero-note' style={{paddingTop:'10px'}}>*no credit card reqiured</p>
        </div>
    );
};

export default WallOfLove;
