import React from 'react';
import './Support.css'; // Assume you have a CSS file for styling

function SupportPage () {
  return (
    <div>
    <div className="support-page-container">
      <iframe
        className="airtable-embed"
        src="https://airtable.com/embed/appRgfGSKMliHxHSS/pagA4yYekAO500uNH/form"
        frameBorder="0"
        onWheel=""
        width="100%"
        height="1000"
        style={{ background: 'transparent', border: '1px solid #ccc' }}
      ></iframe>
    </div>
    </div>
  );
};

export default SupportPage;
