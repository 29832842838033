import React, { useState, useEffect, useRef } from 'react';
import {Amplify} from 'aws-amplify';
import config from '../amplifyconfiguration.json';
import '@aws-amplify/ui-react/styles.css';
import { checkForSummaryFile } from '../util/Retriever';
import '../App.css';
import {uploadData } from 'aws-amplify/storage';
import Mermaid from '../util/mermaid';
import 'font-awesome/css/font-awesome.min.css';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import IframeResizer from 'iframe-resizer-react'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { Authenticator } from '@aws-amplify/ui-react';
import updateUserUsage from '../userManagement/updateUsage';
import getUserData from '../userManagement/getUserData';
import './pdftomindmap.css'
import { grid } from 'ldrs'
import resetUserUsage from '../userManagement/monthlyUsageRefresh';
import mermaid from 'mermaid';
import { downloadData } from '@aws-amplify/storage';
import SideNavBar from '../ui-components/SideNavBar'
import TileGallery from '../ui-components/TileGallery';
import UploadModal from '../ui-components/uploadmodal';
import useFileUploadText from '../util/FileUploadText';
import { retrieveProcessedFile } from '../util/RetrieverText';
import checkAndAddUserIdentity from '../util/sessionLogger';
import EditModal from '../ui-components/EditModal';
import 'aws-amplify/auth/enable-oauth-listener';
import TestimonialPopupModal from '../ui-components/UserTestimonial/testimonialPopup';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import FormatFileName from '../util/FormatFileName';
import useMindMapNodeInteractions from '../Hooks/useMindMapNodeInteractions'; // Adjust the path as necessary
import useFetchUserData from '../Hooks/useFetchUserData';
import SummaryEditor from '../ui-components/SummaryEditor';
import { saveAsPNG } from '../util/SaveFunctions/SaveAsPNG';
import { Modal } from 'react-bootstrap';
import PricingTiersDisplay from '../ui-components/ResponsivePricingComponent/ResponsivePricing';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { MantineProvider } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';
import PopupMessage from '../ui-components/UserFeedback/popupModal';
import UserFeedbackForm from '../ui-components/UserFeedback/userFeedbackForm';
import FeedbackCheck from '../util/FeedbackCheck';
import { generateShareableLink } from '../util/shareMindMap';
import { fetchAuthSession } from '@aws-amplify/auth';
import FileExistenceChecker from '../util/FileExistenceChecker';
import { FaLanguage, FaShare, FaSpinner } from 'react-icons/fa';
import HamsterLoader from '../ui-components/HamsterLoader';
import ShareLinkComponent from '../ui-components/ShareLinkComponent';
import IsSummaryLoading from '../util/IsSummaryLoading';
import MindmapRatings from '../util/MindmapRatings';
import ReMindMap from '../ui-components/ReMindMap';
import getMetaData from '../util/getMetaData';
import { sprig } from '@sprig-technologies/sprig-browser';
grid.register();


export const Sprig = sprig.configure({
environmentId: 'eEI-K58BDxxN',
})



Amplify.configure(config);
library.add(fas);
library.add(far);
resetUserUsage();


function PDFToMindMap() {

  const defaultMindmap = `mindmap
  root((MapThis))
      PDF_Input[PDF Input]
          Upload_PDF[Upload PDF]
          Parse_PDF[Parse PDF]
              Extract_Text[Extract Text]
      Mindmap_Generation[Mindmap Generation]
          Analyze_Data[Analyze Data]
          Identify_Key_Points[Identify Key Points]
              Create_Structure[Create Mindmap Structure]
      Output_Display[Output Display]
          View_Options[View Options]
              Zoom_Pan[Zoom & Pan]
              Export[Export Options]
`;

  const [mindMapCode, setMindMapCode] = useState(defaultMindmap);
  useEffect(() => {
  }, [mindMapCode]);




  const [isLoading, setIsLoading] = useState(false);

  const [isMindMapReady, setIsMindMapReady] = useState(false);

  const [showFileUpload, setShowFileUpload] = useState(false);
  
  const [fileKey, setFileKey] = useState("");

  const [isFormLoading, setFormIsLoading] = useState(false);

  const [feedback, setFeedback] = useState({ type: '', message: '' });

  const [creditsNeeded, setCreditsNeeded] = useState(0);

  const [zoomLevel, setZoomLevel] = useState(4.5);

  const [showTileGallery, setShowTileGallery] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [textInput, setTextInput] = useState('');

  const [showUploadModalButton, setShowUploadModalButton] = useState(false);

  const [newMindmapButtonClicked, setNewMindmapButtonClicked] = useState(false);

  const [uploadedFileName, setUploadedFileName] = useState('');

  const [mapUpdateTrigger, setMapUpdateTrigger] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);

  const [feedbackGiven, setFeedbackGiven] = useState(false);

  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const [showTestimonialWidget, setShowTestimonialWidget] = useState(false);

  const [currentSummary, setCurrentSummary] = useState('');

  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);

  const [mindMapView , setMindMapView] = useState(false);

  const feedbackFormRef = useRef(null);

  const nodeSummaries = []; // Array to hold { nodeText, summary } objects

  const [showMindMapScreen, setShowMindMapScreen] = useState(false);

  const [isPricingPopupOpen, setPricingPopupOpen] = useState(false);

  const [showConfetti, setShowConfetti] = useState(false);

  const [shareableUrl, setShareableUrl] = useState('');

  const [showSharePopup, setShowSharePopup] = useState(false); 

  const [isShareLoading, setIsShareLoading] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState('');

  const [languageDropdownVisible, setLanguageDropdownVisible] = useState(false);

  const [activateNodeClicks, setActivateNodeClicks] = useState(true);

  const [identityId, setIdentityId] = useState('');

  const [userId, setuserId] = useState('');

  const [summaryGenerated, setSummaryGenerated] = useState(true);

  const dropdownRef = useRef(null);

  const [rating, setRating] = useState(null);

  const [isUserOnboarded, setIsUserOnboarded] = useState(true);

  const [metadata, setMetadata] = useState({});

  const [redo, setRedo] = useState("false");

  const [redoLoading, setRedoLoading] = useState();

  const [userEmail, setUserEmail] = useState('');

  const [exportDropdown, setExportDropdown] = useState(false);
  const exportRef = useRef(null);

  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [prevIsSummaryLoading, setPrevIsSummaryLoading] = useState(false);




  const toggleDropdown = () => {
    setExportDropdown(prev => !prev);
  };


  useEffect(() => {
    const fetchData = async () => {
      const data = await getUserData();
      setIsUserOnboarded(data.isUserOnboarded);
      setUserEmail(data.Email);
      if(data.isUserOnboarded === false) {
        navigate('/onboarding');
      }
    };
    fetchData();
  }, []); 

  const handleExportClickOutside = (event) => {
    if (exportRef.current && !exportRef.current.contains(event.target)) {
      setExportDropdown(false);
    }
  };

  const handleExportEscape = (event) => {
    if (event.key === 'Escape') {
      setExportDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleExportClickOutside);
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('mousedown', handleExportClickOutside);
      document.removeEventListener('keydown', handleExportEscape);
    };
  }, []);
  

  useEffect(() => {
    const currentSession = async () => {
      try {
        const data = await fetchAuthSession();
        setIdentityId(data.identityId);
        setuserId(data.userSub);
      } catch (err) {
        //console.log(err);
      }
    };
    currentSession();
  }, []);


  const toggleLanguageDropdown = () => setLanguageDropdownVisible(prevState => !prevState);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setLanguageDropdownVisible(false);
    }
  };

  const handleEscape = (event) => {
    if (event.key === 'Escape') {
      setLanguageDropdownVisible(false);
    }
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  const languages = [
    { code: 'af', name: 'Afrikaans' },
    { code: 'sq', name: 'Albanian' },
    { code: 'am', name: 'Amharic' },
    { code: 'ar', name: 'Arabic' },
    { code: 'hy', name: 'Armenian' },
    { code: 'az', name: 'Azerbaijani' },
    { code: 'bn', name: 'Bengali' },
    { code: 'bs', name: 'Bosnian' },
    { code: 'bg', name: 'Bulgarian' },
    { code: 'ca', name: 'Catalan' },
    { code: 'zh', name: 'Chinese (Simplified)' },
    { code: 'zh-TW', name: 'Chinese (Traditional)' },
    { code: 'hr', name: 'Croatian' },
    { code: 'cs', name: 'Czech' },
    { code: 'da', name: 'Danish' },
    { code: 'fa-AF', name: 'Dari' },
    { code: 'nl', name: 'Dutch' },
    { code:'en', name: 'English' },
    { code: 'et', name: 'Estonian' },
    { code: 'fa', name: 'Farsi (Persian)' },
    { code: 'tl', name: 'Filipino, Tagalog' },
    { code: 'fi', name: 'Finnish' },
    { code: 'fr', name: 'French' },
    { code: 'fr-CA', name: 'French (Canada)' },
    { code: 'ka', name: 'Georgian' },
    { code: 'de', name: 'German' },
    { code: 'el', name: 'Greek' },
    { code: 'gu', name: 'Gujarati' },
    { code: 'ht', name: 'Haitian Creole' },
    { code: 'ha', name: 'Hausa' },
    { code: 'he', name: 'Hebrew' },
    { code: 'hi', name: 'Hindi' },
    { code: 'hu', name: 'Hungarian' },
    { code: 'is', name: 'Icelandic' },
    { code: 'id', name: 'Indonesian' },
    { code: 'ga', name: 'Irish' },
    { code: 'it', name: 'Italian' },
    { code: 'ja', name: 'Japanese' },
    { code: 'kn', name: 'Kannada' },
    { code: 'kk', name: 'Kazakh' },
    { code: 'ko', name: 'Korean' },
    { code: 'lv', name: 'Latvian' },
    { code: 'lt', name: 'Lithuanian' },
    { code: 'mk', name: 'Macedonian' },
    { code: 'ms', name: 'Malay' },
    { code: 'ml', name: 'Malayalam' },
    { code: 'mt', name: 'Maltese' },
    { code: 'mr', name: 'Marathi' },
    { code: 'mn', name: 'Mongolian' },
    { code: 'no', name: 'Norwegian (Bokmål)' },
    { code: 'ps', name: 'Pashto' },
    { code: 'pl', name: 'Polish' },
    { code: 'pt', name: 'Portuguese (Brazil)' },
    { code: 'pt-PT', name: 'Portuguese (Portugal)' },
    { code: 'pa', name: 'Punjabi' },
    { code: 'ro', name: 'Romanian' },
    { code: 'ru', name: 'Russian' },
    { code: 'sr', name: 'Serbian' },
    { code: 'si', name: 'Sinhala' },
    { code: 'sk', name: 'Slovak' },
    { code: 'sl', name: 'Slovenian' },
    { code: 'so', name: 'Somali' },
    { code: 'es', name: 'Spanish' },
    { code: 'es-MX', name: 'Spanish (Mexico)' },
    { code: 'sw', name: 'Swahili' },
    { code: 'sv', name: 'Swedish' },
    { code: 'ta', name: 'Tamil' },
    { code: 'te', name: 'Telugu' },
    { code: 'th', name: 'Thai' },
    { code: 'tr', name: 'Turkish' },
    { code: 'uk', name: 'Ukrainian' },
    { code: 'ur', name: 'Urdu' },
    { code: 'uz', name: 'Uzbek' },
    { code: 'vi', name: 'Vietnamese' },
    { code: 'cy', name: 'Welsh' },
  ];

  const {
    userCurrentUsage,
    userMembershipPlan,
    userMonthlyLimit,
    userID,
    reviewExists,
    userExceededLimit,
    dataForNav,
    isFirstMindMap,
  } = useFetchUserData();

  useEffect(() => {
    const configureAWS = async () => {
      try {
        const session = await fetchAuthSession();
        setIdentityId(session.identityId); // Assuming session object directly contains identityId
      } catch (error) {
        //console.error("Error fetching auth session:", error);
      }
    };
  
    configureAWS();
  }, []);

  useEffect(() => {
    if (isFirstMindMap) {
      setShowConfetti(true);
      const timer = setTimeout(() => {
        setShowConfetti(false); // Hide confetti after timeout
      }, 5000); // Adjust timeout as needed
      return () => clearTimeout(timer);
    }
  }, [isMindMapReady]);

  useEffect(() => {
    if (isFirstMindMap) {
      setIsModalOpen(true);
    }
  }, [isFirstMindMap]);

  const [myStyle, setMyStyle] = useState({});
  const handleGenerateLink = async (event) => {
    setIsShareLoading(true);
    const { clientX, clientY } = event;
    const url = await generateShareableLink(identityId, fileKey);
    setShareableUrl(url);
    setIsShareLoading(false);
    setShowSharePopup(true);
    setMyStyle({
      position: 'absolute',
      left: `${clientX - 220}px`,
      top: `${clientY + 40}px`,
    });
  };

  const handleClose = () => {
    setShowSharePopup(false);
};

      // New function to toggle the pricing popup
      const togglePricingPopup = () => {
        setShowFileUpload(false);
        setPricingPopupOpen(!isPricingPopupOpen);
      };
  


  const {closeAllNodes, activeSummary, setActiveSummary } = useMindMapNodeInteractions(true, mindMapCode);

  const { uploadTextAsFile, uploadAIMagicTextAsFile, uploadProgress } = useFileUploadText({userID});


  const handleUpdateSummary = async (nodeText, newSummary) => {

    // Normalize newSummary by removing new lines
    const sanitizedNewSummary = newSummary.replace(/\n+/g, ' ').trim();

    // Normalize whitespace and escape special characters for regex in nodeText
    const nodeTextEscaped = nodeText.replace(/\s+/g, ' ').trim();
    const regexPattern = new RegExp(`["'\`\\[\\{]?${nodeTextEscaped.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}["'\`\\]\\}]?`, 'i');

    const lines = mindMapCode.split('\n');
    let updatedLines = [];
    let foundNodeIndex = -1;

    // First, identify the line index where the node text is found
    lines.forEach((line, index) => {
        if (regexPattern.test(line.trim())) {
            foundNodeIndex = index; // Capture the index of the node line
        }
    });

    // If a matching node is found, proceed to replace its preceding summary
    if (foundNodeIndex !== -1) {
      let summaryAdded = false;
      lines.forEach((line, index) => {
        if (index === foundNodeIndex - 1 && line.trim().startsWith('%%')) {
          // This is the summary line directly above the found node, replace it
          updatedLines.push(`%% ${sanitizedNewSummary}`);
          summaryAdded = true;
        } else {
          // This line is not the one being replaced, add it as is
          updatedLines.push(line);
        }
      });

      if (!summaryAdded) {
        // Add the new summary line above the found node
        updatedLines.splice(foundNodeIndex, 0, `%% ${sanitizedNewSummary}`);
      }

      const updatedMindMapCode = updatedLines.join('\n');

      // Assuming you have a setState method for mindMapCode
      setMindMapCode(updatedMindMapCode);

      // Upload the updated code
      try {
        const file = new File([updatedMindMapCode], fileKey.replace(/(\.pdf|\.txt)$/, '_summary.txt'), { type: 'text/plain' });
        await uploadData({
          key: fileKey.replace(/(\.pdf|\.txt)$/, '_summary.txt'),
          data: file,
          options: { accessLevel: 'private' }
        });


      } catch (error) {
        //console.error("Error updating and uploading mind map:", error);
      }
    } else {

    }
  };

  

const handleCloseSummary = () => {
  setIsSummaryModalOpen(false); // This effectively closes the SummaryEditor
};

  
useEffect(() => {
  // Show the modal if there is an active summary, otherwise hide it.
  setIsSummaryModalOpen(!!activeSummary);
}, [activeSummary]);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (feedbackFormRef.current && !feedbackFormRef.current.contains(event.target)) {
        setFeedbackOpen(false);
      }
    };
  
    if (feedbackOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [feedbackOpen]); // Add feedbackOpen as a dependency
  

  const openEditModal = () => setShowEditModal(true);
  const closeEditModal = () => {
    setShowEditModal(false);
    setIsMindMapReady(true);
  }

  const handleFeedbackStatus = (status) => {
    setFeedbackGiven(status);
  };


  useEffect(() => {
    fetchMindMapData();
  }, [mapUpdateTrigger]);

  // Hypothetical fetch function
  const fetchMindMapData = async () => {
    //console.log(fileKey);
    const fileKeyFetch = fileKey.replace(/(\.pdf|\.txt)$/, '_summary.txt');
    //console.log(fileKeyFetch);
    try{
        const downloadResult = await downloadData({
          key: `${fileKeyFetch}`,
          options: { accessLevel: 'private' }
      }).result;
      const textContent = await downloadResult.body.text();
      //console.log("this is as soon as data is downloaded for edit modal: "+ textContent);
      setMindMapCode(textContent);
    }
    catch (error) {
      //console.error('Error loading file content:', error);
    }
  };



  const onMindMapSaved = () => {
    setMapUpdateTrigger(current => !current); // Toggle to trigger useEffect
  };


  useEffect(() => {
    checkAndAddUserIdentity();
    
  }, []);



  const openModal = () => {
    setIsModalOpen(true);
    setNewMindmapButtonClicked(true);// Set button to clicked state for visual feedback
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setNewMindmapButtonClicked(false);
  };

  const handleWheel = (e) => {
    if (showEditModal) {
      // If the edit modal is open, do not proceed with zooming.
      e.preventDefault();
      return;
    }


    e.preventDefault();
    const zoomFactor = 0.1; // Adjust this value as needed
    if (e.deltaY < 0) {
      setZoomLevel(zoomLevel => Math.min(zoomLevel + zoomFactor, 4.5)); // Max zoom level 3x
    } else {
      setZoomLevel(zoomLevel => Math.max(zoomLevel - zoomFactor, 0.5)); // Min zoom level 0.5x
    }
  };

  const handleTextUpload = (text, creditsNeeded) => {
    setIsMindMapReady(false);
    setIsLoading(true); 
    uploadTextAsFile(
        text,
        creditsNeeded,
        async (fileKey) => {
            setFileKey(fileKey);
            const formattedFileName = FormatFileName(fileKey); // Format the file name
            setUploadedFileName(formattedFileName);
            setIsModalOpen(false);
            setShowTileGallery(false);
            setIsLoading(true);
            retrieveProcessedFile(
                fileKey,
                async (textContent) => {
                    setMindMapCode(textContent); // Update mind map code
                    setIsMindMapReady(true);
                    setShowUploadModalButton(true); 
                    setIsLoading(false); // Hide loader
                    //console.log("updating user usage");
                    //await updateUserUsage(creditsNeeded);
                    //console.log("it is done!!!");
                    const updatedUserData = await getUserData();
                    if(updatedUserData) {
                      setUserDataUpdateTrigger(updatedUserData.userUsage.CurrentMonthUsage);
                    }
                },
                (error) => {
                    //console.error('Error retrieving processed file:', error);
                    setIsLoading(false); // Hide loader
                }
            );
        },
        (error) => {
            //console.error('Error uploading text:', error);
            setIsLoading(false); // Hide loader
        }
    );
  };

  const handleAIMagicUpload = (aiText, creditsNeeded) => {
    setIsMindMapReady(false);
    setIsLoading(true); 
    uploadAIMagicTextAsFile(
      aiText,
      creditsNeeded,
      async (fileKey) => {
            setFileKey(fileKey);
            const formattedFileName = FormatFileName(fileKey); // Format the file name
            setUploadedFileName(formattedFileName);
            setIsModalOpen(false);
            setShowTileGallery(false);
            setIsLoading(true);
            retrieveProcessedFile(
                fileKey,
                async (textContent) => {
                    
                    setMindMapCode(textContent); // Update mind map code
                    setIsMindMapReady(true);
                    setShowUploadModalButton(true); // Indicate that the mind map is ready
                    setIsLoading(false);
                    /*console.log("updating user usage");
                    await updateUserUsage(1);
                    console.log("it is done!!!");*/
                    const updatedUserData = await getUserData();
                    if(updatedUserData) {
                      setUserDataUpdateTrigger(updatedUserData.userUsage.CurrentMonthUsage);
                    } // Hide loader 
                },
                (error) => {
                  //console.error('Error uploading AI Magic text:', error);
                  setIsLoading(false);  
                }
            );
        },
        (error) => {
            //console.error('Error uploading text:', error);
            setIsLoading(false); // Hide loader
        }
    );
  };


  const navigate = useNavigate();
  const handleMyMapsClick = () => {
    setIsMindMapReady(false); 
    setMindMapView(false);
    setShowTileGallery(true); // Show TileGallery
    navigate('/');
  };

  const handleMindMapUpdate = async (fileName) => {
    setFileKey(fileName);
    setIsLoading(true);
    setMindMapView(true);
    const formattedFileName = FormatFileName(fileName); // Format the file name
    setUploadedFileName(formattedFileName);
    let summaryFileName;
    if (fileName.endsWith('.pdf')) {
        summaryFileName = `${fileName.replace('.pdf', '')}_summary.txt`;
    } else if (fileName.endsWith('ai_magic.txt') || fileName.endsWith('text_input.txt')) {
        summaryFileName = `${fileName.replace('.txt', '')}_summary.txt`;
    }

    if (summaryFileName) {
        try {
            const downloadResult = await downloadData({
                key: summaryFileName,
                options: {
                    accessLevel: 'private'
                }
            }).result;
            let textContent = await downloadResult.body.text();
            //console.log("Before cleaning: ", textContent);
            textContent = textContent.replace(/```/g, "").replace(/mermaid/g, "").trim();
            setMindMapCode(textContent);
            setIsMindMapReady(true);
            setShowUploadModalButton(true);
            setZoomLevel(3);
            setShowFileUpload(false);
        } catch (error) {
            //console.error('Error fetching summary file:', error);
        } finally {
            setIsLoading(false);
        }
    } else {
        //console.error('Unsupported file type');
        setIsLoading(false);
    }
  };
  
  const handleCreditsNeededChange = (newCreditsNeeded) => {
      setCreditsNeeded(newCreditsNeeded);
  };

  const [userDataUpdateTrigger, setUserDataUpdateTrigger] = useState(0);

  const [errorMessage, setErrorMessage] = useState('');

  

  const handleToggleUpload = () => {
    setShowFileUpload(!showFileUpload);
  };

 // Function to be called when loading state transitions from true to false
 const onLoadingComplete = () => {
  // Fetch mind map data and reinitialize interactions
  fetchMindMapData();
  
  // Add a delay before calling closeAllNodes
  setTimeout(() => {
    closeAllNodes();
  }, 500); // Delay in milliseconds (e.g., 500ms)
};

useEffect(() => {
  const pollIsSummaryLoading = async () => {
    const loadingStatus = await IsSummaryLoading(fileKey, identityId);
    setIsSummaryLoading(loadingStatus);
  };

  pollIsSummaryLoading(); // Call it once to set initial state
  const intervalId = setInterval(pollIsSummaryLoading, 5000); // Poll every 5 seconds

  return () => clearInterval(intervalId); // Clean up the interval on component unmount
}, [fileKey, identityId]);

// Detect the transition from true to false
useEffect(() => {
  if (!prevIsSummaryLoading && !isSummaryLoading) {
    onLoadingComplete();
  }
  setPrevIsSummaryLoading(isSummaryLoading);
}, [isSummaryLoading, prevIsSummaryLoading]);
  

  function handleFileUploadSuccess(fileName) {
    
    closeModal();
    setShowTileGallery(false);
    setIsLoading(true);
    setFileKey(fileName);
    const formattedFileName = FormatFileName(fileName); // Format the file name
    setUploadedFileName(formattedFileName);
    setShowFileUpload(false); // Hide the file upload component when a file is uploaded
    

    checkForSummaryFile(fileName,
        async (content) => {
            try {
              const testMindMapCodeWithSyntaxError = `
              mindmappp
              root((mindmap))
                  Origins
                      Long history
                      ::icon(fa fa-book)
                      Popularisation
                          British popular psychology author Tony Buzan
                  Research
                      On effectiveness<br/>and features
                      On Automatic creation
                          Uses
                              Creative techniques
                              Strategic planning
                              Argument mapping
                  Tools
                      Pen and paper
                      Mermaid
              `;
                // Use mermaid API to parse the content
                const isValid = await mermaid.mermaidAPI.parse(content);
                if (isValid) {
                    // If content is valid, update usage and set the mind map code
                    setMindMapCode(content);
                    const updatedUserData = await getUserData();
                    if(updatedUserData) {
                      setUserDataUpdateTrigger(updatedUserData.userUsage.CurrentMonthUsage);
                    }
                    closeModal();
                    setIsMindMapReady(true);
                    setShowUploadModalButton(true);
                    setIsLoading(false);
                } else {
                    // Handle invalid content without updating usage
                    //console.error('Invalid Mermaid content');
                    setErrorMessage("An error occurred. Please try uploading again.");
                    setTimeout(() => {
                      setErrorMessage("");
                    }, 5000); 
                    
                    setShowFileUpload(true); // Show the file upload component again
                    setIsMindMapReady(false);
                    setIsLoading(false);
                }
            } catch (error) {
                //console.error('Error parsing Mermaid content:', error);
                setErrorMessage("An error occurred. Please try uploading again.");
                setTimeout(() => {
                  setErrorMessage("");
                }, 5000); 
                setShowFileUpload(true); // Show the file upload component again
                setIsMindMapReady(false);
            }
            setIsLoading(false);
        },
        (error) => {
            // Handle error from checkForSummaryFile
            //console.error('Error fetching summary:', error);
            setErrorMessage("An error occurred. Please try uploading again.");
            setTimeout(() => {
              setErrorMessage("");
            }, 5000); 
            setShowFileUpload(true); // Show the file upload component again
            setIsMindMapReady(false);
            setIsLoading(false);
        } 
    );
  };

  const translateMindMap = async () => {
    setIsLoading(true);
    setIsMindMapReady(false);
    setShowTileGallery(false);

    if (!selectedLanguage || !fileKey) {
      return;
    }

    // Adjusting for the expected file key format
    let newFileKey = fileKey.replace(/(\.pdf|\.txt)$/, '_summary.txt');
    let summaryFileKey = `private/${identityId}/${newFileKey}`;

  
    try {
      const response = await fetch('https://wox1fv3q4j.execute-api.us-east-1.amazonaws.com/dev', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // Correcting the property names as per the API structure
        body: JSON.stringify({ file_key: summaryFileKey, target_language: selectedLanguage }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to translate mind map');
      }
  
      const data = await response.json();
      setIsLoading(false);
      setIsMindMapReady(true);
      onMindMapSaved();
      // Optionally, trigger any UI update or notification here
    } catch (error) {
      //console.error('Error translating mind map:', error);
      // Consider providing user feedback on the UI
    }
  };


const isPaidUser = userMembershipPlan === 'Paid' || userMembershipPlan === 'Pro Plan' || userMembershipPlan === 'Starter Plan' ||userMembershipPlan === 'Annual Pro Plan' ;
const isFreeOrPayPerUse = userMembershipPlan === 'Freemium' || userMembershipPlan === 'Pay Per Use';
const handleReMindMapClick = () => {
  setIsLoading(true);
  setIsMindMapReady(false);
  setShowTileGallery(false);
  setMindMapCode('');
};


  useEffect(() => {
      // Construct the file path
      const filePath = `private/${identityId}/${fileKey}`;

      // Fetch metadata when the component mounts
      getMetaData(filePath)
          .then(data => {
              if (data) {
                  const parsedBody = JSON.parse(data.body);
                  setMetadata(parsedBody.metadata);
                  setRedo(parsedBody.metadata.redo);
                  setRedoLoading(parsedBody.metadata.redoloading);
                  if(parsedBody.metadata.redoloading === "true") {
                      setMindMapCode('');
                      setIsLoading(true);
                      setIsMindMapReady(false);
                      setShowTileGallery(false);
                  }
              } else {
                  
              }
          })
          .catch(error => {
              
          });

      // This empty dependency array ensures the effect runs only once after the component mounts
  }, [identityId, fileKey]);


  function isPDF(fileKey) {
    if (typeof fileKey !== 'string') {
      console.error('Invalid input: fileKey must be a string.');
      return false;
    }
  
    // Check if the fileKey ends with '.pdf'
    return fileKey.toLowerCase().endsWith('.pdf');
  }

  const ispdf = isPDF(fileKey);

  Sprig.setAttributes({
    membershipPlan: userMembershipPlan
 });

 Sprig.setEmail(userEmail);

 const handleUpgradeNavigation = (event, pricingState = 'monthly') => {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }
  window.open(`/pricing?state=${pricingState}`, '_blank');
}

return (
  
  <Authenticator>
    {({ signOut, user }) => (
      <>
      <div className="page-layout">
        {!isMindMapReady && (
        <SideNavBar 
          onMyMapsClick={handleMyMapsClick} 
          triggerUpdate={userDataUpdateTrigger}
          isFirstMindMap={isFirstMindMap}
        /> 
        )}
        
        {/* 
        <FeedbackCheck onFeedbackStatus={setFeedbackGiven} />
        {!feedbackGiven && userMembershipPlan === 'Freemium' && (
          <PopupMessage onStartClick={() => setFeedbackOpen(true)} />
        )}
        {feedbackOpen && (
          <UserFeedbackForm ref={feedbackFormRef} open={feedbackOpen} setOpen={setFeedbackOpen} />
        )}
        */}
        

        {showTestimonialWidget && (
          <div 
            style={{ position: 'absolute', top: '0%', left: '0%', width: '100%', height: '100%', zIndex: 10000, backgroundColor:'rgba(0,0,0,0.5)'}}
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setShowTestimonialWidget(false);
              }
            }}
          >
            <IframeResizer src="https://embed-v2.testimonial.to/c/map-this?theme=dark" style={{ position: 'absolute', top: '15%', left: '25%',width: '50%', height: '50%', border: 'none' }} />
          </div>
        )}

        {false && (
          <TestimonialPopupModal onStartClick={() => setShowTestimonialWidget(true)} />
        )}



        {(showTileGallery && !showFileUpload && !isMindMapReady) && (
          <TileGallery 
            onPdfSelect={handleMindMapUpdate} 
            onTxtSelect={handleMindMapUpdate}
            onNewTileClick={openModal} 
            isFirstMindMap={isFirstMindMap}
            isPaidUser={isPaidUser}
            isFreeOrPayPerUse={isFreeOrPayPerUse}
          />
        )}

        {isLoading && (
          <div className='centered-content'>
            <div className="loader">
              <HamsterLoader />
              <p style={{paddingTop:'20px'}}>Do not refresh</p>
            </div>
          </div>
        )}

       {isMindMapReady && (
          <div className="main-content" style={{backgroundImage: 'url("/backgroundPattern.png")', backgroundSize: 'contain',  backgroundRepeat: 'repeat'}} onWheel={handleWheel}>
          {showConfetti && ( 
            <Confetti
            numberOfPieces={2000}
            tweenDuration={10000}
            recycle={false}
          />
          
          )}
      {/*
            <FileExistenceChecker
                  fileKey={fileKey}
                  onProcessingUpdated={(loaderVisible) => {
                    if (!loaderVisible) {
                      setActivateNodeClicks(false); // Hide specific processing loader upon metadata update
                    }
                  }}
            />
            */}

            
      
          {isSummaryModalOpen && (
            <div className="node-summary-modal">
                <SummaryEditor
                key={`${activeSummary.nodeText}-${activeSummary.x}-${activeSummary.y}`}
                activeSummary={activeSummary}
                onUpdateSummary={handleUpdateSummary}
                setActiveSummary={setActiveSummary} // Optional if you need to update the active summary from the editor
                handleCloseSummary={handleCloseSummary}
                fileKey={'private/' + identityId + '/' + fileKey}
                isPaidUser={isPaidUser}
                identityId={identityId}  
                isSummaryLoading={isSummaryLoading}
            />
            </div>
          )}
          <div className="mindmap-header">
            <div className="mindmap-header-left">
              <img src="/logo-with-name-black.png" alt="Map This Logo without name" className="app-header-image" onClick={handleMyMapsClick}/>
              </div>
              <p className='file-name-app'>{uploadedFileName}</p>
              <div className="mindmap-header-right">
              <MindmapRatings userId={userId} fileKey={fileKey} identityId={identityId}/>
            

                <button className="translate-button" onClick={toggleLanguageDropdown}>
                  <img className='translate-icon' src='/translate.png' />
                  <span className="tooltip-text">Translate</span>
                </button>

                {
                  languageDropdownVisible && (
                    <div ref={dropdownRef} style={{
                      position: 'absolute',
                      right:'250px',
                      top: '50px',
                      zIndex: 1000,
                      borderRadius: '20px',
                      backgroundColor: 'white',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                      width: '200px',
                      padding: '10px',
                      gap: '10px', // Adds space between elements inside the div
                    }}>
                      <select
                        value={selectedLanguage}
                        onChange={(e) => setSelectedLanguage(e.target.value)}
                        style={{
                          display: 'block',
                          width: '100%', // Ensures the select fills the container
                          padding: '8px', // Adds some padding for better touch interaction
                          borderRadius: '10px', // Rounds the corners for a softer look
                          border: '1px solid #ccc', // Defines a subtle border
                          boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)', // Inner shadow for some depth
                          appearance: 'none', // Removes default styling by the browser
                          backgroundColor: '#fff', // Ensures background color
                        }}
                      >
                        {languages.map((language) => (
                          <option key={language.code} value={language.code}>
                            {language.name}
                          </option>
                        ))}
                      </select>
                      <button
                        className="translate-icon"
                        onClick={translateMindMap}
                        style={{
                          width: '100%', // Ensures the button fills the container
                          padding: '10px',
                          borderRadius: '10px', // Matches the select for consistency
                          backgroundColor: '#007bff', // A pleasant, eye-catching blue
                          color: 'white', // Ensures text is readable against the button background
                          fontWeight: 'bold', // Makes the button text stand out
                          border: 'none',
                          cursor: 'pointer', // Changes the cursor to signify clickable
                          boxShadow: '0 2px 4px rgba(0,0,0,0.2)', // Adds a subtle shadow for depth
                          transition: 'background-color 0.2s', // Smooth transition for hover effect
                        }}
                      >
                        Translate
                      </button>
                    </div>
                  )
                }



              <button className='share-button' onClick={handleGenerateLink} disabled={isShareLoading}>
                {isShareLoading ? (
                  <>
                    <FaSpinner size="30px" className="fa-spin" />
                    <span className="tooltip-text">Generating link...</span>
                  </>
                ) : (
                  <>
                    <img className='share-icon' src="/shareIcon.png" alt="Share"/>
                    <span className="tooltip-text">Share</span>
                  </>
                )}
              </button>
                
              <button           
                        className={`new-mindmap ${newMindmapButtonClicked ? 'clicked' : ''}`}
                        onClick={openModal}
                      >
                        <img className='new-icon' src="/newIcon.png"/>
                        <span className="tooltip-text">New Mindmap</span>
              </button>

            {isPaidUser && (
              <div className="export-dropdown">
              <button className="save-buttons"
              onClick={() => saveAsPNG(isPaidUser)}>
                <img src="/exportIcon.png" alt="Export Icon" className="export-icon" />
                <span className="tooltip-text">Export as PNG</span>
              </button>
              </div>
            )}

            {!isPaidUser && (
              <div ref={exportRef} className="export-dropdown">
                <button className="save-buttons">
                  <img src="/exportIcon.png" alt="Export Icon" className="export-icon" />
                </button>
                <div className="dropdown-content">
                  <button onClick={() => saveAsPNG(isPaidUser)}>Export</button>
                  <button onClick={(e) => handleUpgradeNavigation(e, 'yearly')}>Export without Watermark <img src="/proPlan.png" className='new-icon' alt="Pro Plan"/> </button>
                </div>
              </div>
              )}


                {showUploadModalButton && (
                  <>
                    <button           
                      className={`edit-mindmap ${showEditModal ? 'clicked' : ''}`}
                      onClick={openEditModal}
                    >
                      <img className='edit-icon' src="/editIcon.png"/>
                      <span className="tooltip-text">Edit</span>
                    </button>
                  </>
                )}
                {showEditModal && (
                  <EditModal
                    isOpen={showEditModal}
                    onClose={closeEditModal}
                    fileKey={fileKey}
                    membershipPlan={userMembershipPlan}
                    onMindMapSaved={onMindMapSaved}
                    monthlyLimit={userMonthlyLimit}
                  />
                )}
              </div>
            </div>
            {showSharePopup && (
                <div>
                  <ShareLinkComponent link={shareableUrl} style = {myStyle} onClose={handleClose} />
                </div>
            )}
            <TransformWrapper 
              defaultScale={0.5}
              minScale={0.5}
              maxScale={3}
              centerOnInit={true}
            >
              <TransformComponent>
                <div className="mindmap-centering-wrapper">
                  <Mermaid key={mindMapCode ? mindMapCode.length : 0} chart={mindMapCode} />
                </div>
              </TransformComponent>
            </TransformWrapper>
            {((redo === "false" || redo === undefined) && ispdf) &&(
                <ReMindMap
                fileKey={`private/${identityId}/${fileKey}`}
                metadata={metadata}
                onClick={handleReMindMapClick}
              />
            )}

            <p className='disclaimer'>Map-This can make mistakes. Consider checking important information.</p>
          </div>
        )}

        {isModalOpen && (
          <UploadModal 
            closeModal={closeModal} 
            onMapText={handleTextUpload} 
            onMapAI={handleAIMagicUpload} 
            handleFileUploadSuccess={handleFileUploadSuccess}
            handleCreditsNeededChange={handleCreditsNeededChange} 
            isFirstMindMap={isFirstMindMap}
            userID={userID}
          />
        )}


        {errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )}
      </div>
      <Modal show={isPricingPopupOpen} onHide={togglePricingPopup} size="lg" centered>
        <Modal.Body style={{ background: 'transparent', width:'80vw' }}>
          <MantineProvider>
          <PricingTiersDisplay currentPlanProps={{monthlyLimit: userMonthlyLimit, currentPlan: userMembershipPlan}} />
          </MantineProvider>
        </Modal.Body>
      </Modal>


      </>
    )}
  </Authenticator>
);  
}

export default PDFToMindMap;