import React, { useState, useEffect, forwardRef } from 'react';
//import './PricingComponent.css';
import { handleUpgradeClick } from '../util/HandleUpgrade';

const PricingComponent = forwardRef(({ onClose }, ref) => {
    const [selectedTier, setSelectedTier] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isLoading, setIsLoading] = useState({});

    const tiers = [
        {
            title: 'Free',
            price: '$0',
            features: [
                '10 credits per month',
                'Maximum File Size 5MB',
                'Limited Customer Support'
            ],
            current: true
        },
        {
            title: 'Pro',
            price: '$5.99/month',
            features: [
                '250 credits per month',
                'No limit on file size',
                'Priority Customer Support via email'
            ],
            current: false
        }
    ];

    useEffect(() => {
        const handleResize = () => {
            const isNowMobile = window.innerWidth < 768;
            setIsMobile(isNowMobile);
            if (isNowMobile) {
                // Automatically select the Pro tier on mobile devices
                setSelectedTier('Pro');
            } else {
                // Reset the selection when transitioning back to the full-screen view
                setSelectedTier(null);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleUpgrade = async (tierTitle, event) => {
        event.stopPropagation();
        setIsLoading({ ...isLoading, [tierTitle]: true });
        try {
            await handleUpgradeClick(tierTitle);
            // If the upgrade is successful, you can set the state or trigger any follow-up action here
        } catch (error) {
            //console.error('Error during upgrade process:', error);
        } finally {
            setIsLoading({ ...isLoading, [tierTitle]: false });
        }
    };

    const toggleTier = (tierTitle) => {
        // Only toggle on mobile
        if (isMobile) {
            setSelectedTier(selectedTier === tierTitle ? null : tierTitle);
        }
    };

if (isMobile) {
        return (
            <div className="pricing-container" ref={ref}>
                {tiers.map((tier) => (
                    <div 
                        className={`tier ${tier.title === 'Pro' ? 'pro-plan' : ''} ${selectedTier === tier.title || (!isMobile && tier.current) ? 'open' : ''}`}
                        key={tier.title}
                        onClick={() => toggleTier(tier.title)}
                    >
                        <div className="tier-header">
                            <h2>{tier.title}</h2>
                            <p className="price">{tier.price}</p>
                        </div>
                    
                        {(selectedTier === tier.title || (!isMobile && tier.current)) && (
                            <>
                                <ul>
                                    {tier.features.map((feature, index) => (
                                        <li key={index}>{feature}</li>
                                    ))}
                                </ul>
                                {tier.current && (
                                    <div className="current-plan-indicator">Current Plan</div>
                                )}
                                {!tier.current && (
                                    <button 
                                        className={`tier-button ${tier.title === 'Pro' ? 'pro-button' : ''}`}
                                        onClick={(event) => handleUpgrade(tier.title, event)}
                                        disabled={isLoading[tier.title]}
                                    >
                                        {isLoading[tier.title] ? 'Loading...' : `Choose ${tier.title}`}
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                ))}
            </div>
        );

} else {
    return (
        <div className="pricing-container" ref={ref}>
            {tiers.map((tier) => (
                <div 
                    className={`tier ${tier.title === 'Pro' ? 'pro-plan' : ''}`}
                    key={tier.title}
                >
                    <div className="tier-header">
                        <h2>{tier.title}</h2>
                        <p className="price">{tier.price}</p>
                    </div>
                    <ul>
                        {tier.features.map((feature, index) => (
                            <li key={index}>{feature}</li>
                        ))}
                    </ul>
                    {tier.current && (
                        <div className="current-plan-indicator">Current Plan</div>
                    )}
                    {!tier.current && (
                        <button 
                            className={`tier-button ${tier.title === 'Pro' ? 'pro-button' : ''}`}
                            onClick={(event) => handleUpgrade(tier.title, event)}
                            disabled={isLoading[tier.title]}
                        >
                            {isLoading[tier.title] ? 'Loading...' : `Choose ${tier.title}`}
                        </button>
                    )}
                </div>
            ))}
        </div>
    );
            
};
});

export default PricingComponent;