import { replaceFontAwesomeIconsWithInlineSVGs } from "./changeIconsHelper";
import html2canvas from 'html2canvas';

const saveAsPNG = async (isPaidUser) => {
    let mermaidContainer = document.getElementById("mermaid-chart");
    if (!mermaidContainer) {
        throw new Error("No Mermaid container element found");
    }
    mermaidContainer = await replaceFontAwesomeIconsWithInlineSVGs(mermaidContainer);
    const clonedMermaidContainer = mermaidContainer.cloneNode(true);

    // Apply watermark only if user is not paid
    if (!isPaidUser) {
        // Generate the repeating SVG watermark and apply it as a background
        const base64Mark = btoa(markTemplate("map-this.com")); // Encode the SVG to base64
        clonedMermaidContainer.style.backgroundImage = `url('data:image/svg+xml;base64,${base64Mark}')`;
        clonedMermaidContainer.style.backgroundRepeat = "repeat";
    }

    clonedMermaidContainer.style.width = "100%"; // Ensure the container's full width is used
    clonedMermaidContainer.style.height = "100%"; // Ensure the container's full height is used
    document.body.appendChild(clonedMermaidContainer);
    clonedMermaidContainer.style.display = "block";

    html2canvas(clonedMermaidContainer, {logging: true, letterRendering: 1, useCORS: true})
        .then((canvas) => {
            document.body.removeChild(clonedMermaidContainer);
            const png = canvas.toDataURL("image/png");
            const a = document.createElement("a");
            a.download = "mindmap.png";
            a.href = png;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        })
        .catch((error) => {
            document.body.removeChild(clonedMermaidContainer);
            throw new Error("Error generating PNG file: ", error);
        });
};

const markTemplate = (name) => {
    const width = 100;
    const height = 100;
    const spaceBetweenRepeats = 50;
    
    return `<svg xmlns='http://www.w3.org/2000/svg' width='${width}' height='${height}'>
              <defs>
                <pattern id='watermark' patternUnits='userSpaceOnUse' width='${width + spaceBetweenRepeats}' height='${height + spaceBetweenRepeats}'>
                  <text x='10' y='${height / 2}' fill='rgba(0,0,0,0.1)' font-size='14' font-family='Arial, sans-serif'>
                    ${name}
                  </text>
                </pattern>
              </defs>
              <rect width='100%' height='100%' fill='url(#watermark)' />
            </svg>`;
};

export { saveAsPNG };
