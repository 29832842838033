function SvgIcon3({ className }) {
  return (
    <div className={className}>
      <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" x="0" y="0">
        <g id="Icons_8" xmlns="http://www.w3.org/2000/svg" data-node-id="15:1292">
          <path
            id="Vector_8"
            d="M19.537,7.837l-9.6,9.6c-0.084,0.084 -0.183,0.15 -0.293,0.196c-0.109,0.045 -0.226,0.068 -0.345,0.068c-0.118,0 -0.235,-0.023 -0.345,-0.068c-0.109,-0.046 -0.209,-0.112 -0.292,-0.196l-4.2,-4.2c-0.084,-0.084 -0.15,-0.183 -0.196,-0.293c-0.045,-0.109 -0.068,-0.226 -0.068,-0.345c0,-0.118 0.023,-0.235 0.068,-0.345c0.046,-0.109 0.112,-0.209 0.196,-0.292c0.083,-0.084 0.183,-0.15 0.292,-0.196c0.11,-0.045 0.227,-0.068 0.345,-0.068c0.119,0 0.236,0.023 0.345,0.068c0.11,0.046 0.209,0.112 0.293,0.196l3.563,3.563l8.963,-8.962c0.169,-0.169 0.399,-0.264 0.638,-0.264c0.239,0 0.468,0.095 0.637,0.264c0.169,0.169 0.264,0.399 0.264,0.638c0,0.239 -0.095,0.468 -0.264,0.637z"
            fill="black"
            data-node-id="I15:1292;15:1170"
          />
        </g>
      </svg>
    </div>
  );
}

export default SvgIcon3;
