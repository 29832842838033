import { useState } from 'react';
import { uploadData } from 'aws-amplify/storage';
import { v4 as uuidv4 } from 'uuid';


const useFileUploadText = ({userID}) => {
    const [uploadProgress, setUploadProgress] = useState(0);

    const sanitizeFileName = (text) => {
        return text.replace(/[\/?<>\\:*|"^]/g, '').replace(/\s+/g, '_');
    };

    const extractAndSanitizeFileName = (text) => {
        const words = text.split(/\s+/).slice(0, 3).join(' '); // Extract first three words and join with space
        return sanitizeFileName(words);
    };

    const uploadTextAsFile = async (text, creditsNeeded, onUploadSuccess, onUploadError) => {
        if (!text) {
            alert('Please enter some text.');
            return;
        }

        const metadata = {
            userID: userID,
            creditsNeeded: creditsNeeded,
        };
    
        const firstFewWords = extractAndSanitizeFileName(text);
        const uniqueFileName = `TextInput/${uuidv4()}-${firstFewWords}_text_input.txt`;
        //console.log(`Uploading file with name: ${uniqueFileName}`); 
        const textBlob = new Blob([text], { type: 'text/plain' });

        try {
            const uploadTask = uploadData({
                key: uniqueFileName,
                data: textBlob,
                options: {
                    accessLevel: 'private',
                    metadata: metadata,
                    onProgress: ({ transferredBytes, totalBytes }) => {
                        setUploadProgress(Math.round((transferredBytes / totalBytes) * 100));
                    }
                }
            });

            await uploadTask.result;
            onUploadSuccess(uniqueFileName);
        } catch (error) {
            console.error('Error uploading text file:', error);
            onUploadError(error);
        }
    };

    const uploadAIMagicTextAsFile = async (text, creditsNeeded, onUploadSuccess, onUploadError) => {
        if (!text) {
            alert('Please enter some text.');
            return;
        }

        const metadata = {
            userID: userID,
            creditsNeeded: creditsNeeded,
        };
    
        const firstFewWords = extractAndSanitizeFileName(text);
        const uniqueFileName = `AI_Magic/${uuidv4()}-${firstFewWords}_ai_magic.txt`;
        //console.log(`Uploading AI Magic file with name: ${uniqueFileName}`);
        const textBlob = new Blob([text], { type: 'text/plain' });

        try {
            const uploadTask = uploadData({
                key: uniqueFileName,
                data: textBlob,
                options: {
                    accessLevel: 'private',
                    metadata: metadata,
                    onProgress: ({ transferredBytes, totalBytes }) => {
                        setUploadProgress(Math.round((transferredBytes / totalBytes) * 100));
                    }
                }
            });

            await uploadTask.result;
            onUploadSuccess(uniqueFileName);
        } catch (error) {
            console.error('Error uploading AI Magic text file:', error);
            onUploadError(error);
        }
    };

    return { uploadTextAsFile, uploadAIMagicTextAsFile, uploadProgress };
};

export default useFileUploadText;