import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const handleButtonClick = () => {
    window.open('https://calendly.com/meetnatik/30min', '_blank');
};

const CustomerCallPopup = ({ onClose, isPaidUser }) => {
    const [doNotShowAgain, setDoNotShowAgain] = useState(false);
    const [open, setOpen] = useState(false);


    useEffect(() => {
        setTimeout(() => setOpen(true), 5000);
    }, []);

    const handleChange = (event) => {
        setDoNotShowAgain(event.target.checked);
        localStorage.setItem('showPopupAgain', event.target.checked);
    };

    // Different content based on user type
    const dialogContentText = isPaidUser ? {
        title: (
            <>
                Let's chat about how we can make Map This better for you! And we will send you a <strong>$25 Amazon Gift card</strong> as a thank you!
            </>
        ),
        subtitle: "The call will focus on understanding your needs, exploring ways we can enhance our services, and discussing any immediate benefits you might need."
    } : {
        title: (
            <>
                Let's chat about how we can make Map This better for you! And you will be entered in a draw for a <strong>$100 Amazon Gift card</strong>!
            </>
        ),
        subtitle: "The call will focus on understanding your needs, exploring ways we can enhance our services, and discussing any immediate benefits you might need."
    };
    

    const buttonLabel = "Let's Chat!"

    return (
        <Dialog
            open={open && doNotShowAgain}
            onClose={() => { setOpen(false); onClose(); }}
            aria-labelledby="call-to-action-dialog-title"
            fullWidth
            maxWidth="md"
            scroll="paper"
        >
            <DialogTitle sx={{ position: 'relative', padding: 2, textAlign: 'center' }}>
                <IconButton
                    aria-label="close"
                    onClick={() => { setOpen(false); onClose(); }}
                    sx={{ position: 'absolute', right: 8, top: 8, color: 'gray' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                <div style={{ width: '50%', padding: '20px' }}>
                    <img src="https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExM2Q1anFpdmw4aGk1cWgwNGE3enJlZXcxNnUxYXplOWYzYTBqczFpayZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/BpGWitbFZflfSUYuZ9/giphy.webp" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} alt="Special Offer" />
                </div>
                <div style={{ width: '50%', padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <DialogContentText sx={{ fontSize: '1.2rem', marginBottom: '30px', fontWeight: 'medium', color: 'black' }}>
                        {dialogContentText.title}
                    </DialogContentText>
                    <DialogContentText sx={{ fontSize: '1rem', color: 'gray', marginBottom: '20px' }}>
                        {dialogContentText.subtitle}
                    </DialogContentText>
                    <DialogActions sx={{ flexDirection: 'column', justifyContent: 'center', padding: 3 }}>
                        <Button
                            onClick={handleButtonClick}
                            color="primary"
                            variant="contained"
                            sx={{
                                backgroundColor: '#f2a0a5',
                                color: 'black',
                                '&:hover': {
                                    backgroundColor: '#f0d1d3'
                                },
                                marginBottom: '20px'
                            }}
                        >
                            {buttonLabel}
                        </Button>
                        <FormControlLabel
                            control={<Switch checked={doNotShowAgain} onChange={handleChange} />}
                            label="Don't show this message again"
                            sx={{ color: 'gray' }}
                        />
                    </DialogActions>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default CustomerCallPopup;
