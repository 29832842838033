import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from '@react-hook/window-size';
import NavBar from '../ui-components/LandingPage/Navbar';
import getUserData from '../userManagement/getUserData'; // Adjust the path as necessary
import Footer from '../ui-components/LandingPage/Footer';

const Congratulations = () => {
  const [width, height] = useWindowSize();
  const [user, setUser] = useState(null);
  const [isHovered, setIsHovered] = useState(false); // State to track hover
  
  const product = {
    name: "Pro Membership",
    image: "https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExcW0yZHl3ZjE5MWMzM2V3eTZ1ZHpiYjV0dnQycGwwenRkMWxnejg2aSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/HloNK1z39EkEQcreIo/giphy.webp"
  };

  useEffect(() => {
    const fetchUser = async () => {
      const userData = await getUserData();
      setUser(userData);
    };

    fetchUser();
  }, []);

  const getFirstName = (fullName) => {
    return fullName.split(' ')[0]; // Splits the name string and returns the first part
  };

  const buttonStyle = {
    width: '200px',
    margin: 'auto',
    border: 'none',
    borderRadius: '24px',
    padding: '10px',
    fontWeight: '600',
    textDecoration: 'underline',
    backgroundColor: isHovered ? '#f5a4ab' : '#f0d1d3', // Background color changes on hover
    cursor: 'pointer',
    transition: 'background-color 0.3s' // Smooth transition for the background color
  };

  const greeting = user && user.Name ? `${getFirstName(user.Name)}, you are in 🎉` : "You are in 🎉";

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', paddingBottom: '50px', textAlign: 'center' }}>
        <Confetti width={width} height={height} numberOfPieces={450} recycle={false} />
        <NavBar />
        {user ? (
          <>
            <h1 style={{ paddingTop: '50px', fontWeight: '900' }}>{greeting}</h1>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ width: '400px', fontSize: '18px', color: 'grey', margin: 'auto'}}>Thanks for unlocking more credits on Map This. Create amazing mind maps and keep on learning!</p>
              <img src={product.image} alt={product.name} style={{ maxWidth: '400px', margin: '20px auto', borderRadius:'16px'  }} />
              <button
                style={buttonStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => window.location.href = '/app'}
              >
                Go to my maps →
              </button>
            </div>
          </>
        ) : (
          <p>Loading user information...</p>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Congratulations;
