import { fab } from '@fortawesome/free-brands-svg-icons';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas,far,fab);

async function replaceFontAwesomeIconsWithInlineSVGs(mermaidContainer) {
    const iconElements = Array.from(mermaidContainer.querySelectorAll(".fa"));
    for (const iconElement of iconElements) {
        const iconClass = Array.from(iconElement.classList).find((className) =>
            className.startsWith("fa-")
        );
        const iconName = iconClass.slice(3);
        let faIcon; // Try checking for fab first
        faIcon = icon({ prefix: "fab", iconName });
        if (!faIcon) {
            // If not found in fab, try fas and far
            faIcon = icon({ prefix: "fas", iconName });
            if (!faIcon) {
                faIcon = icon({ prefix: "far", iconName });
            }
        }
        if (!faIcon) {
            console.error(`Icon with name ${iconName} not found.`);
            iconElement.parentNode.removeChild(iconElement);
            continue;
        }
        /*const svgElement = faIcon.node[0];
        const paths = svgElement.querySelectorAll("path");
        paths.forEach(path => {
            path.setAttribute("fill", "#fff");
        });*/
        iconElement.parentNode.replaceChild(faIcon.node[0], iconElement);
    }
    return mermaidContainer;
}

export { replaceFontAwesomeIconsWithInlineSVGs };