import React from 'react';
import styled from 'styled-components';
import uploadIcon from './upload.png'; // Update with the correct path
import imageIcon from './mindmap.png'; // Update with the correct path
import vectorIcon from './save.png'; // Update with the correct path
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FaArrowRight, FaExclamationCircle } from 'react-icons/fa';
import './VisualDemo.css';

const StyledFrame = styled.div`
  overflow:hidden;
  background-color: #f5f5f4;
  padding-top: 100px;
  padding-bottom: 80px;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  justify-content: center;
  width: 100%;
  background-image: url('${process.env.PUBLIC_URL}/background/backgroundLeft20.png'), url('${process.env.PUBLIC_URL}/background/backgroundRight20.png');
  background-position: left center, right center;
  background-size: auto auto, auto auto;
  background-repeat: repeat, repeat;

  @media (max-width: 768px) { // Adjust breakpoint as needed
    margin-top:50px;
    padding-top: 50px;
    padding-bottom: 100px;
    gap: 20px;
    background-image: url('${process.env.PUBLIC_URL}/background/backgroundLeft20.png'), url('${process.env.PUBLIC_URL}/background/backgroundRight20.png');
    background-position: top center, bottom center;
    background-size: auto auto, auto auto;
    background-repeat: repeat, repeat;
  }

  .works-in-simple, .text-wrapper {
    color: #002b31;
    font-size: 52px;
    font-weight: 500;
    letter-spacing: 0.64px;
    line-height: 78px;
    position: relative;
    text-align: center;
    width: 90%; // Use percentage for responsiveness
    margin-top: -1px;

    @media (max-width: 768px) {
      font-size: 32px; // Smaller font size for mobile
      line-height: 48px;
      width: 95%; // Adjust width for smaller screens
    }
  }

  .div {
    align-items: flex-start;
    display: flex;
    flex-direction: row; // Align side by side on larger screens
    gap: 35px;
    position: relative;

    @media (max-width: 768px) {
      flex-direction: column; // Stack vertically on smaller screens
      align-items: center;
    }
  }

  .link {
    background-color: #ffffff;
    border: none;
    border-radius: 12px;
    height: 297px;
    overflow: hidden;
    position: relative;
    width: 405px;

    @media (min-width: 769px) {
      // For larger screens
      height: 297px;
      width: 405px;
    }

    @media (max-width: 768px) {
      // For mobile screens
      width: 90%; // Responsive width
      height: auto; // Adjust height for content
      margin: 0 auto; // Center the element
    }

  }

  .div-2 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 15px;
    left: 16px;
    position: relative;
    top: 23px;
  }

  .span-body-small {
    align-items: flex-start;
    background-color: #9bc7ce;
    border-radius: 4px;
    box-shadow: inset 0px -1px 0px 1px #0000001a, inset 0px 1px 0px #0000001a;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    overflow: hidden;
    padding: 2px 3.31px 1.19px 4px;
    position: relative;
  }

  .step {
    color: #002b31;
    font-family: var(--skiff-com-1440x810-default-inter-light-13-89-upper-font-family);
    font-size: var(--skiff-com-1440x810-default-inter-light-13-89-upper-font-size);
    font-style: var(--skiff-com-1440x810-default-inter-light-13-89-upper-font-style);
    font-weight: var(--skiff-com-1440x810-default-inter-light-13-89-upper-font-weight);
    letter-spacing: var(--skiff-com-1440x810-default-inter-light-13-89-upper-letter-spacing);
    line-height: var(--skiff-com-1440x810-default-inter-light-13-89-upper-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .div-3 {
    background-color: #ffffff;
    border-color: #e6e6e5;
    border-radius: 12px;
    overflow: hidden; // Prevent content from overflowing
    position: relative;
    width: 100%; // Full width of the parent element
    max-width: 373px; // Max width to control size on larger screens
    margin: 0 auto; // Center the container

    @media (max-width: 768px) {
      max-width: 100%; // Allow full width on smaller screens
    }
  }

  .upload {
    width: 100%; // Make the image width responsive to the container
    height: auto; // Maintain aspect ratio
    object-fit: contain; // Ensures the image is fully visible
    max-height: 250px; // Optional: limit the maximum height
    margin: 0 auto; // Center the image within its container

    @media (max-width: 768px) {
      max-height: 150px; // Smaller maximum height for mobile devices
    }
  }

  .text-wrapper-2 {
    color: #002b31;
    font-family: "Satoshi Variable-Bold", Helvetica;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.64px;
    line-height: 33px;
    position: relative;
    width: 277px;
  }

  .p {
    color: #000000;
    font-family: "Satoshi Variable-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.64px;
    line-height: normal;
    position: relative;
    width: 358px;
  }

  .overlap-group {
    height: 95px;
    left: 104px;
    position: relative;
    top: 6px;
    width: 164px;
  }

  .img {
    height: 95px;
    left: 14px;
    position: absolute;
    top: 0;
    width: 150px;
  }

  .group {
    height: 86px;
    left: 0;
    position: absolute;
    top: 9px;
    width: 164px;
  }

  .vector-wrapper {
    background-image: url(./upload-2.svg);
    background-size: 100% 100%;
    height: 95px;
    left: 117px;
    position: relative;
    top: 6px;
    width: 150px;
  }

  .vector {
    height: 73px;
    left: 46px;
    position: absolute;
    top: 15px;
    width: 57px;
  }
`;
const TextContent = styled.div`
  text-align: left;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  padding-top: 16px;
  padding-left: 16px; // Added padding for alignment
  padding-right: 16px; // Added padding for symmetry
`;
const TextWrapper2 = styled.div`
  color: #002b31;
  font-family: "Satoshi Variable-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.64px;
  line-height: 33px;
`;

const P = styled.p`
  color: #000000;
  font-family: "Satoshi Variable-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.64px;
  line-height: normal;
`;

const StyledLink = styled.div`
  align-items: center;
  border: 2px solid #002b31;
  border-radius: 42px;
  display: flex;
  justify-content: center;
  padding: 14px 25px;
  font-family: "Satoshi Variable-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.64px;
  line-height: 28px;
  color: #002b31;
  text-decoration: none; // Remove underline if it's a link
  transition: background-color 0.3s ease, color 0.3s ease;
  @media (max-width: 768px) {
    font-size: 14px; // Even smaller on very small screens
  }
`;


const SlidingList = styled.div`
  display: flex;
  width: 400px;
  flex-direction: row;
  animation: slide 10s linear infinite;
  z-index: 0;
  animation-iteration-count: infinite;

  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-30%);
    }
  }

  &:hover {
    animation-play-state: paused;
  }
`;

const ListItem = styled.div`
margin-right: 20px;
white-space: nowrap;
font-family: "Satoshi Variable-Regular", Helvetica;
font-size: 16px;
color: #000000;
`;

const Button = styled.button`
  background-color: transparent;
  border: 1px solid #ffffff; // Border color changed to white
  border-radius: 42px; // Border-radius matched with your styled component
  padding: 20px 50px; // Padding matched with your styled component
  font-family: "Satoshi Variable-Bold", Helvetica;
  font-size: 20px;
  font-weight:700;
  color: #ffffff; // Text color is white
  cursor: pointer;
  margin-left: 10px;
  margin-top:50px;
  transition: background-color 0.3s, color 0.3s; // Smooth transition for hover effect

  @media (max-width: 768px) {
    padding: 20px 40px; // Smaller padding for mobile
    font-size: 20px; // Smaller font size for mobile
  }
  &:hover {
    background: linear-gradient(90deg, rgba(266, 198, 241, 0.8) 0%, rgba(246, 174, 177) 50%); // Lighter gradient on hover
    color: #002b31; // Dark text color on hover
    border-color: rgba(255, 255, 255, 0.8); // Lighter border color on hover
  }
`;

// Define the React component
const VisualDemo = () => {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate('/login');
};

const [text, setText] = useState('');
const [isDeleting, setIsDeleting] = useState(false);
const [loopNum, setLoopNum] = useState(0);
const typingSpeed = isDeleting ? 100 : 200; 
const phrases = ["PDFs", "Notes", "Docs", "Reports"];

useEffect(() => {
  const currentIndex = loopNum % phrases.length;
  const fullText = phrases[currentIndex];

  const handleTyping = () => {
    setText(current => {
      const baseText = isDeleting ? current.slice(0, current.length - 1) : (current.length < fullText.length ? current + fullText[current.length] : current);
      if (!isDeleting && baseText === fullText) {
        setTimeout(() => setIsDeleting(true), 1000);
      } else if (isDeleting && baseText === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
      }
      return baseText || '';
    });
  };

  const timer = setTimeout(handleTyping, typingSpeed);

  return () => clearTimeout(timer);
}, [text, isDeleting, loopNum, phrases]);

const trustedItems = ["Students ", "Educators ", "Lawyers ", "Nurses ", "Advisors ", "Executives ", "Marketers ","Students ", "Educators ", "Lawyers ", "Nurses ", "Advisors ", "Executives ", "Marketers ","Students ", "Educators ", "Lawyers ", "Nurses ", "Advisors ", "Executives ", "Marketers ","Students ", "Educators ", "Lawyers ", "Nurses ", "Advisors ", "Executives ", "Marketers","Students ", "Educators ", "Lawyers ", "Nurses ", "Advisors ", "Executives ", "Marketers "];


  return (
    <StyledFrame>
      <div className="works-in-simple">
        1, 2, 3 <FaArrowRight/> <span className='highlight-mindmap'>Mind Map!</span>
        <img className="mind-map-image" src={`${process.env.PUBLIC_URL}/logo-no-name.png`} alt="Mind Map" />
      </div>
      <div className="div">
        {/* Step 1 */}
        <div className="link">
          <TextContent>
            <div className="span-body-small">
              <div className="step">STEP 1</div>
            </div>
            <div className="div-3">
              <img className="upload" alt="Upload" src={uploadIcon} />
            </div>
            <TextWrapper2 style={{ whiteSpace: 'nowrap' }}>Add your <span className='highlight-pdf'>{text}</span></TextWrapper2>
            <P>Upload your PDF, or paste your notes, or simply use an AI prompt</P>
          </TextContent>
        </div>
        {/* Step 2 */}
        <div className="link">
          <TextContent>
            <div className="span-body-small">
              <div className="step">STEP 2</div>
            </div>
            <div className="div-3">
             
                <img className="upload" alt="Generate" src={imageIcon} />
                
            
            </div>
            <TextWrapper2>Generate Mind Map</TextWrapper2>
            <P>Our app will turn your data into mind map</P>
          </TextContent>
        </div>
        {/* Step 3 */}
        <div className="link">
          <TextContent>
            <div className="span-body-small">
              <div className="step">STEP 3</div>
            </div>
            <div className="div-3">
              
                <img className="upload" alt="Vector" src={vectorIcon} />
              
            </div>
            <TextWrapper2>Save it or Share it</TextWrapper2>
            <P>Save your mind maps for later or share it with a friend or colleague </P>
          </TextContent>
        </div>
      </div>

      <div className='trusted-by'>
      <h1>Trusted By:</h1>
      <div className="scroller" data-speed="fast" data-animated="true">
        <ul className="tag-list scroller__inner">
          {trustedItems.map((item, index) => (
            <ul className={index % 2 === 0 ? 'highlight-mindmap' : 'highlight-blue'} key={index}>{item}</ul>
          ))}
        </ul>
      </div>
    </div>

    </StyledFrame>
  );
};

export default VisualDemo;