import React, { useState, useEffect } from 'react';
import SummaryCard from './SummaryCard';
import IsSummaryLoading from '../util/IsSummaryLoading';

const SummaryEditor = ({
  activeSummary, onUpdateSummary, handleCloseSummary,
  fileKey, isPaidUser, identityId, isSummaryLoading // Accept the callback prop
}) => {
  const [editedSummary, setEditedSummary] = useState('');

  useEffect(() => {
    if (activeSummary) {
      setEditedSummary(activeSummary.summary);
    } else {
      setEditedSummary('');
    }

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleCloseSummary();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeSummary, handleCloseSummary]);


  const handleEdit = (newSummary) => {
    setEditedSummary(newSummary);
  };

  const handleSave = () => {
    if (activeSummary) {
      onUpdateSummary(activeSummary.nodeText, editedSummary);
    }
  };

  return (
    <div>
      {activeSummary && (
        <SummaryCard
          heading={activeSummary.nodeText}
          summary={activeSummary.summary}
          onClose={handleCloseSummary}
          onEdit={handleEdit}
          onUpdateSummary={handleSave}
          style={{ position: 'absolute', left: `${activeSummary.x}px`, top: `${activeSummary.y}px` }}
          fileKey={fileKey}
          isPaidUser={isPaidUser}
          identityId={identityId}
          isSummaryLoading={isSummaryLoading}
        />
      )}
    </div>
  );
};

export default SummaryEditor;
