import React, { useEffect, useState } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import NavBar from '../ui-components/LandingPage/Navbar';
import getUserData from '../userManagement/getUserData';
import Footer from '../ui-components/LandingPage/Footer';

const MaybeNextTime = () => {
  const [width, height] = useWindowSize();
  const [user, setUser] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  
  useEffect(() => {
    const fetchUser = async () => {
      const userData = await getUserData();
      setUser(userData);
    };

    fetchUser();
  }, []);

  // Modified to handle potentially empty or undefined names
  const getFirstName = (fullName) => {
    return fullName ? fullName.split(' ')[0] : ''; // Checks if fullName is truthy before splitting
  };

  const buttonStyle = {
    width: '250px',
    margin: 'auto',
    border: 'none',
    borderRadius: '24px',
    padding: '10px',
    fontWeight: '600',
    textDecoration: 'underline',
    backgroundColor: isHovered ? '#d3d3d3' : '#e6e6e6', // Neutral colors
    cursor: 'pointer',
    transition: 'background-color 0.3s'
  };

  const greeting = user && user.Name ? `${getFirstName(user.Name)}, maybe next time.` : "Hmm, maybe later.";

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', paddingBottom: '50px', textAlign: 'center' }}>
        <NavBar />
        {user ? (
          <>
            <h1 style={{ paddingTop: '50px', fontWeight: '900' }}>{greeting}</h1>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ width: '400px', fontSize: '18px', color: 'grey', margin: 'auto' }}>
                It seems like you haven't completed the payment process. You can explore other options or try again whenever you're ready!
              </p>
              <img src={'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExZG81YjhscDlqZmNuajMwaWthcXBqb3I3bDFreHE4ZDBkcWc2eGVyZyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/TG7gzTLAKR2DxOMqCj/giphy.webp'} style={{ maxWidth: '400px', margin: '20px auto', borderRadius:'16px' }} />
              <button
                style={buttonStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => window.location.href = '/pricing'} // Redirect to pricing or another relevant page
              >
                Check Pricing Options →
              </button>
            </div>
          </>
        ) : (
          <p>Loading user information...</p>
        )}
      </div>
      <Footer />
    </>
  );
};

export default MaybeNextTime;
